import { format } from 'date-fns-tz';
import React from 'react';
import { IHelpMeDecideEntry } from '../../interfaces';
import { SearchableTable } from '../searchable-table';
import { orderIdCol, patientAgeCol } from '../../shared/data/report-columns';

interface IHelpMeDecideProps {
  helpMeDecide: IHelpMeDecideEntry[];
}

export const HelpMeDecideList = (
  { helpMeDecide }: IHelpMeDecideProps,
) => {
  const columns = React.useMemo(() => [
    orderIdCol,
    {
      Header: 'Order Date',
      accessor: 'createdAt',
      // @ts-ignore
      Cell: ({ value }) => format(new Date(value), 'do MMMM yyyy HH:mm'),
    },
    patientAgeCol,
    {
      Header: 'Migraine Status',
      accessor: 'migraineStatus',
    },
    {
      Header: 'Migraine - Visual Aura',
      accessor: 'migraineVisualAura',
    },
    {
      Header: 'BMI Status',
      accessor: 'bmiStatus',
    },
    {
      Header: 'Smoking Status',
      accessor: 'smokingStatus',
    },
    {
      Header: 'Blood Pressure Systolic',
      accessor: 'bloodPressureSystolic',
    },
    {
      Header: 'Blood Pressure Diastolic',
      accessor: 'bloodPressureDiastolic',
    },
    {
      Header: 'Medication Prescribed',
      accessor: 'medication',
    },
  ], []);

  return (
    <SearchableTable columns={columns} rows={helpMeDecide} />
  );
};
