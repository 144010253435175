import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import type { IMedication } from '../../interfaces';
import { SearchableTable } from '../searchable-table';

interface IMedicationsListProps {
  medications: IMedication[];
  children?: React.ReactChild | React.ReactChild[];
}

const TrueFalseCell = ({ value }: { value: boolean }) => {
  return value ? <Icon name="check" /> : <Icon name="cancel" />;
};

const LinkCell = ({ value }: { value: string }) => {
  return <Link to={`/medications/view/${value}`}>{value}</Link>;
};

export const MedicationsList = (
  { medications, children }: IMedicationsListProps,
) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: LinkCell,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Category Code',
      accessor: 'categoryCode',
    },
    {
      Header: 'Short Code',
      accessor: 'shortCode',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Units',
      accessor: 'units',
    },
    {
      Header: 'Form',
      accessor: 'form',
    },
    {
      Header: 'PackSize',
      accessor: 'packSize',
    },
    {
      Header: 'Dosage Level',
      accessor: 'dosageLevel',
    },
    {
      Header: 'Available',
      accessor: 'available',
      Cell: TrueFalseCell,
    },
  ], []);

  return (
    <SearchableTable columns={columns} rows={medications}>
      {children}
    </SearchableTable>
  );
};
