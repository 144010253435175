import React from 'react';
import { format } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import { IReport } from '../../interfaces';
import { SearchableTable } from '../searchable-table';

interface IReportsListProps {
  reports: IReport<any>[];
}

const LinkCell = ({ value }: { value: string }) => {
  return <Link to={`/reports/view/${value}`}>{value}</Link>;
};

const sortByDateDescending = (a: IReport<any>, b: IReport<any>) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

export const ReportsList = (
  { reports }: IReportsListProps,
) => {
  const copy = [...reports];
  copy.sort(sortByDateDescending);

  const columns = React.useMemo(() => [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: LinkCell,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ value }) => format(new Date(value), 'do MMMM yyyy HH:mm'),
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ value }) => format(new Date(value), 'do MMMM yyyy HH:mm'),
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: ({ value }) => format(new Date(value), 'do MMMM yyyy HH:mm'),
    },
  ], []);

  return (
    <SearchableTable columns={columns} rows={copy} />
  );
};
