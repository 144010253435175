import React from 'react';
import { get } from 'lodash/fp';
import { Column } from 'react-table';
import { Placeholder, Segment } from 'semantic-ui-react';
import { formatDistanceToNowStrict } from 'date-fns';
import { useKeycloak } from '@react-keycloak/web';
import { isEmpty } from 'lodash';
import { useSwr } from '../../hooks/swr';
import { CustomTable } from '../custom-table';
import type { IOrderNote } from '../../interfaces';
import { CreateOrderNoteButton } from './create-order-note-button';
import { ConditionalRenderer } from '../conditional-renderer';

const FriendlyDate = ({ value }: { value: string }) => (value
  ? <div title={value}>{formatDistanceToNowStrict(new Date(value))} ago</div> : null);

export const OrderNotes = ({ orderId }: IOrderNotesProps) => {
  const { keycloak } = useKeycloak();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, preferred_username } = get('idTokenParsed', keycloak) as any;

  const { data: orderNotes, error, mutate } = useSwr<IOrderNote[]>(`/api/orders/${orderId}/notes`);

  const columns: Column<any>[] = React.useMemo(() => [
    {
      Header: 'Author name',
      accessor: 'authorName',
    },
    {
      Header: 'Author e-mail',
      accessor: 'authorEmail',
    },
    {
      Header: 'Content',
      accessor: 'content',
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: FriendlyDate,
    },
  ], []);

  const data = React.useMemo(() => orderNotes, [orderNotes]);

  if (error) {
    return (
      <Segment>
        Error fetching order notes:
        {error.message}
      </Segment>
    );
  }

  if (!orderNotes) {
    return (
      <Segment>
        <Placeholder fluid>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
        </Placeholder>
      </Segment>
    );
  }

  return (
    <>
      <ConditionalRenderer role={'order.ADD_NOTE'}>
        <CreateOrderNoteButton
          onComplete={mutate}
          orderId={orderId}
          prescriber={{ prescriberId: email, prescriberUsername: preferred_username }}
        />
      </ConditionalRenderer>
      {isEmpty(data)
        ? <p className="bold">No previous order notes</p>
        : <CustomTable columns={columns as any} data={data!} />
      }
    </>
  );
};

interface IOrderNotesProps {
  orderId: string;
}
