import React from 'react';
import { Header as SemanticUIHeader, Icon, SemanticICONS } from 'semantic-ui-react';

interface IHeaderProp {
  children: React.ReactChild;
  icon?: SemanticICONS;
}

export const Header = ({ children, icon }: IHeaderProp) => {
  return (
    <SemanticUIHeader size="large">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        {icon && <div><Icon name={icon} /></div>}
        <div style={{ fontSize: '1.2rem', flex: 1 }}>{children}</div>
      </div>
    </SemanticUIHeader>
  );
};
