import React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import { format } from 'date-fns';
import { useSwr } from '../hooks/swr';
import type { IConsultation } from '../interfaces';

export type Props = {
  consultationId: string
};

export const timeStampsToReadable = (answer: string): string => {
  if (!answer) {
    return '';
  }
  try {
    return answer.replace(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g, (a) => {
      return format(new Date(a), 'dd/MM/yyyy');
    });
  } catch (e) {
    return answer;
  }
};

const ConsultationTable = ({ consultationId }: Props) => {
  const { data, error } = useSwr<IConsultation>(`/api/consultations/${consultationId}`);

  if (error) {
    return (<Segment>
      Error fetching consultation:
      {error.message}
    </Segment>);
  }

  if (!data) {
    return (<Segment>Loading</Segment>);
  }

  return (<Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Question</Table.HeaderCell>
        <Table.HeaderCell>Answer</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {data.consultation.map(({ question, answer }, i) =>
        <Table.Row key={i}>
          <Table.Cell>{question}</Table.Cell>
          <Table.Cell style={{ 'wordWrap': 'break-word' }}>{timeStampsToReadable(answer)}</Table.Cell>
        </Table.Row>,
      )}
    </Table.Body>
  </Table>);
};

export const Consultation = ({ consultationId }: Props) => {
  if (!consultationId) {
    return <Segment>A consultation has not been provided for this order.</Segment>;
  }

  return <ConsultationTable consultationId={consultationId} />
}
