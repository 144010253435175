import React from 'react';
import { Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

interface PrivateRouteProps {
  component: React.ReactType,
  [key: string]: any
}

export function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
  const [keycloak] = useKeycloak();
  if (!keycloak.authenticated) {
    keycloak.login();
  }

  return (
    <Route
      {...rest}
      render={(props) => keycloak.authenticated && <Component {...{ ...rest, ...props }} />
      }
    />
  );
}
