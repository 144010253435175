import { IPatient, IPatientDto } from '../interfaces';
import { get, put } from './base';

export const getPatientById = async (token: string, id: string): Promise<IPatient> => {
  const url = `/api/patients/${id}`;
  const data = await get<IPatient>(url, token);

  return data;
};

export const getPatientsByQuery = async (
  token: string,
  query: string,
): Promise<IPatient[]> => {
  const url = `/api/patients?${query}`;
  return get<IPatient[]>(url, token);
};

const emptyStringToNull = (value: string | null | undefined): string | null => {
  if (!value) {
    return null;
  }
  return value;
};

export const updatePatientById = async (token: string, id: string, patient: IPatientDto): Promise<IPatient> => {
  const cleanPatient = {
    firstName: patient.firstName,
    lastName: patient.lastName,
    dob: patient.dob,
    sex: patient.sex,
    telephone: patient.telephone,
    email: patient.email,
    genderIdentity: emptyStringToNull(patient.genderIdentity),
    preferredPronouns: emptyStringToNull(patient.preferredPronouns),
    preferredName: emptyStringToNull(patient.preferredName),
  };
  const url = `/api/patients/${id}`;
  const data = await put<IPatient>(url, token, 'patient', cleanPatient);
  return data;
};
