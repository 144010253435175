import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as orderClient from "../api-clients/orders";
import { getAxiosError } from "../hooks/request-entity";
import {
  IDispatcher,
  IDispenser,
  IOrder,
  IPaginatedOrders,
  IPagination,
  IPharmacist,
  IPrescriber,
  IProductSubstituteParams,
} from "../interfaces";
import type { AppThunk } from "../store";

interface OrdersState {
  order: IOrder | null;
  loadingError: string | null;
  isLoadingOrder: boolean;
  isUpdatingOrder: boolean;
  isUpdatingShippingAddress: boolean;
  isUpdatingShippingInformation: boolean;
  substitutedProductId: string | null;
  substitutionError: string | null;
  triageResolveError: string | null;
  updateError: string | null;
  orders: IOrder[];
  pagination: IPagination | null;
  isLoadingOrders: boolean;
}

const initialState: OrdersState = {
  order: null,
  loadingError: null,
  isLoadingOrder: false,
  isUpdatingOrder: false,
  isUpdatingShippingAddress: false,
  isUpdatingShippingInformation: false,
  substitutedProductId: null,
  substitutionError: null,
  triageResolveError: null,
  updateError: null,
  orders: [],
  pagination: null,
  isLoadingOrders: false,
};

const tryUpdateOrders = (state: OrdersState, updatedOrder: IOrder): boolean => {
  const index = state.orders.findIndex((order) => order.id === updatedOrder.id);
  if (index < 0) {
    return false;
  }

  state.orders[index] = updatedOrder;
  return true;
};

const tryUpdateOrder = (state: OrdersState, updatedOrder: IOrder): boolean => {
  if (state?.order?.id !== updatedOrder.id) {
    return false;
  }

  state.order = updatedOrder;
  return true;
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.loadingError = null;
      state.isLoadingOrder = false;
    },
    getOrderError(state, action: PayloadAction<string>) {
      state.order = null;
      state.loadingError = action.payload;
      state.isLoadingOrder = false;
    },
    getOrderStart(state) {
      state.isLoadingOrder = true;
    },
    getOrdersSuccess(state, action: PayloadAction<IOrder[]>) {
      state.orders = action.payload;
      state.loadingError = null;
      state.isLoadingOrders = false;
      state.pagination = null;
    },
    getPaginatedOrdersSuccess(state, action: PayloadAction<IPaginatedOrders>) {
      state.orders = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loadingError = null;
      state.isLoadingOrders = false;
    },
    getOrdersError(state, action: PayloadAction<string>) {
      state.orders = [];
      state.loadingError = action.payload;
      state.isLoadingOrders = false;
    },
    getOrdersStart(state) {
      state.isLoadingOrders = true;
    },
    rejectOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.updateError = null;
      state.isUpdatingOrder = false;
    },
    rejectOrderError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    dispenseOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.updateError = null;
      state.isUpdatingOrder = false;
    },
    dispenseOrderError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    cancelOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.updateError = null;
      state.isUpdatingOrder = false;
    },
    cancelOrderError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    approveOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.updateError = null;
      state.isUpdatingOrder = false;
    },
    approveOrderError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    voidOrderPrescriptionSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.updateError = null;
      state.isUpdatingOrder = false;
    },
    voidOrderPrescriptionError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    dispatchOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.updateError = null;
      state.isUpdatingOrder = false;
    },
    dispatchOrderError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    updateOrderStart(state) {
      state.isUpdatingOrder = true;
    },
    assignPharmacistSuccess(state, action: PayloadAction<IOrder>) {
      state.updateError = null;
      state.isUpdatingOrder = false;
      tryUpdateOrders(state, action.payload);
      tryUpdateOrder(state, action.payload);
    },
    assignPharmacistError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    assignDispenserSuccess(state, action: PayloadAction<IOrder>) {
      state.updateError = null;
      state.isUpdatingOrder = false;
      tryUpdateOrders(state, action.payload);
      tryUpdateOrder(state, action.payload);
    },
    assignDispenserError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    assignDispatcherSuccess(state, action: PayloadAction<IOrder>) {
      state.updateError = null;
      state.isUpdatingOrder = false;
      tryUpdateOrders(state, action.payload);
      tryUpdateOrder(state, action.payload);
    },
    assignDispatcherError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    assignPrescriberSuccess(state, action: PayloadAction<IOrder>) {
      state.updateError = null;
      state.isUpdatingOrder = false;
      tryUpdateOrders(state, action.payload);
      tryUpdateOrder(state, action.payload);
    },
    assignPrescriberError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    updatingShippingAddress(state, action: PayloadAction<boolean>) {
      state.isUpdatingShippingAddress = action.payload;
    },
    updateShippingAddressSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.isUpdatingOrder = false;
      state.updateError = null;
      state.isUpdatingShippingAddress = false;
    },
    updateShippingAddressError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    updatingShippingInformation(state, action: PayloadAction<boolean>) {
      state.isUpdatingShippingInformation = action.payload;
    },
    updateShippingInformationSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.isUpdatingOrder = false;
      state.updateError = null;
      state.isUpdatingShippingInformation = false;
    },
    updateShippingInformationError(state, action: PayloadAction<string>) {
      state.updateError = action.payload;
      state.isUpdatingOrder = false;
    },
    substituteProductSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.substitutionError = null;
      state.isUpdatingOrder = false;
      state.substitutedProductId = null;
    },
    resolveOrderSuccess(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.substitutionError = null;
      state.isUpdatingOrder = false;
    },
    substituteProductError(state, action: PayloadAction<string>) {
      state.substitutionError = action.payload;
      state.isUpdatingOrder = false;
    },
    startSubstitution(state, action: PayloadAction<string>) {
      state.substitutedProductId = action.payload;
      state.substitutionError = null;
    },
    endSubstitution(state) {
      state.substitutionError = null;
      state.substitutedProductId = null;
    },
    substitutionErrorHandled(state) {
      state.substitutionError = null;
    },
    triageResolveError(state, action: PayloadAction<string>) {
      state.triageResolveError = action.payload;
      state.isUpdatingOrder = false;
    },
    triageResolveErrorHandled(state) {
      state.triageResolveError = null;
    },
    orderLoadingErrorHandled(state) {
      state.loadingError = null;
    },
    orderUpdateErrorHandled(state) {
      state.updateError = null;
    },
  },
});

export const {
  getOrderError,
  getOrderSuccess,
  getOrderStart,
  getOrdersError,
  getOrdersSuccess,
  getPaginatedOrdersSuccess,
  getOrdersStart,
  rejectOrderSuccess,
  rejectOrderError,
  cancelOrderSuccess,
  cancelOrderError,
  substituteProductSuccess,
  substituteProductError,
  startSubstitution,
  endSubstitution,
  approveOrderSuccess,
  approveOrderError,
  voidOrderPrescriptionSuccess,
  voidOrderPrescriptionError,
  dispenseOrderSuccess,
  dispenseOrderError,
  dispatchOrderSuccess,
  dispatchOrderError,
  assignPrescriberSuccess,
  assignPrescriberError,
  assignPharmacistSuccess,
  assignPharmacistError,
  assignDispenserSuccess,
  assignDispenserError,
  assignDispatcherSuccess,
  assignDispatcherError,
  updateOrderStart,
  substitutionErrorHandled,
  orderLoadingErrorHandled,
  orderUpdateErrorHandled,
  updateShippingAddressError,
  updateShippingAddressSuccess,
  updatingShippingAddress,
  updateShippingInformationError,
  updateShippingInformationSuccess,
  updatingShippingInformation,
  resolveOrderSuccess,
  triageResolveError,
  triageResolveErrorHandled,
} = ordersSlice.actions;

export const ordersReducer = ordersSlice.reducer;

export const fetchOrder =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOrderStart());
      const order = await orderClient.getOrderById(token, id);
      dispatch(getOrderSuccess(order));
    } catch (err) {
      dispatch(getOrderError(err.toString()));
    }
  };

export const fetchOrders =
  (token: string, query: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOrdersStart());
      const orders = await orderClient.getOrdersByQuery(token, query);
      dispatch(getOrdersSuccess(orders));
    } catch (err) {
      dispatch(getOrdersError(err.toString()));
    }
  };

export const fetchPaginatedOrders =
  (token: string, query: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOrdersStart());
      const pagOrders = await orderClient.getOrdersByQueryWithPagination(
        token,
        query
      );
      dispatch(getPaginatedOrdersSuccess(pagOrders));
    } catch (err) {
      dispatch(getOrdersError(err.toString()));
    }
  };

export const rejectOrder =
  (token: string, id: string, reason: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      await orderClient.rejectOrder(token, id, reason);
      const order = await orderClient.getOrderById(token, id);
      dispatch(rejectOrderSuccess(order));
    } catch (err) {
      dispatch(rejectOrderError(err.toString()));
    }
  };

export const cancelOrder =
  (token: string, id: string, reason: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.cancelOrder(token, id, reason);
      dispatch(cancelOrderSuccess(order));
    } catch (err) {
      dispatch(cancelOrderError(err.toString()));
    }
  };

export const approveOrder =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.approveOrder(token, id);
      dispatch(approveOrderSuccess(order));
    } catch (err) {
      dispatch(approveOrderError(err.toString()));
    }
  };

export const voidOrderPrescription =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.voidOrderPrescription(token, id);
      dispatch(voidOrderPrescriptionSuccess(order));
    } catch (err) {
      dispatch(voidOrderPrescriptionError(err.toString()));
    }
  };

export const dispatchOrder =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.dispatchOrder(token, id);
      dispatch(dispatchOrderSuccess(order));
    } catch (err) {
      dispatch(dispatchOrderError(err.toString()));
    }
  };

export const dispenseOrder =
  (token: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.dispenseOrder(token, id);
      dispatch(dispenseOrderSuccess(order));
    } catch (err) {
      dispatch(dispenseOrderError(err.toString()));
    }
  };

export const assignPharmacist =
  (token: string, id: string, pharmacist: IPharmacist): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.assignPharmacist(token, id, pharmacist);
      dispatch(assignPharmacistSuccess(order));
    } catch (err) {
      dispatch(assignPharmacistError(err.toString()));
    }
  };

export const assignDispenser =
  (token: string, id: string, dispenser: IDispenser): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.assignDispenser(token, id, dispenser);
      dispatch(assignDispenserSuccess(order));
    } catch (err) {
      dispatch(assignDispenserError(err.toString()));
    }
  };

export const assignDispatcher =
  (token: string, id: string, dispatcher: IDispatcher): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.assignDispatcher(token, id, dispatcher);
      dispatch(assignDispatcherSuccess(order));
    } catch (err) {
      dispatch(assignDispatcherError(err.toString()));
    }
  };

export const assignPrescriber =
  (token: string, id: string, prescriber: IPrescriber): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.assignPrescriber(token, id, prescriber);
      dispatch(assignPrescriberSuccess(order));
    } catch (err) {
      dispatch(assignPrescriberError(err.toString()));
    }
  };

const formatSubstitutionError = (error: any): string | null => {
  const detail = error?.errors?.[0].detail;
  if (!detail) {
    return null;
  }

  return detail.indexOf("quantity") >= 0
    ? "Packs field needs to be a positive number"
    : detail;
};

export const resolveOrderTriage =
  (
    token: string,
    id: string,
    physicalPrescriptionId: string,
    physicalPrescriptionDate: string,
    physicalPrescriptionImage: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.resolveOrderTriage(
        token,
        id,
        physicalPrescriptionId,
        physicalPrescriptionDate,
        physicalPrescriptionImage
      );
      dispatch(resolveOrderSuccess(order));
    } catch (err) {
      //TODO: handle resolve error
      const error = getAxiosError(err);
      const message = JSON.stringify(error);
      dispatch(triageResolveError(message));
    }
  };

// eslint-disable-next-line max-len
export const substituteProduct =
  (token: string, id: string, substitute: IProductSubstituteParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.substituteProduct(token, id, substitute);
      dispatch(substituteProductSuccess(order));
    } catch (err) {
      const error = getAxiosError(err);
      const message = formatSubstitutionError(error) || JSON.stringify(error);
      dispatch(substituteProductError(message));
    }
  };

export const updateShippingAddress =
  (
    token: string,
    id: string,
    shippingAddress: Partial<IOrder["shippingAddress"]>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.updateShippingAddress(
        token,
        id,
        shippingAddress
      );
      dispatch(updateShippingAddressSuccess(order));
    } catch (err) {
      const error = getAxiosError(err);
      const message = formatSubstitutionError(error) || JSON.stringify(error);
      dispatch(updateShippingAddressError(message));
    }
  };

export const updateShippingInformation =
  (
    token: string,
    id: string,
    shippingInformation: Partial<IOrder["shippingInformation"]>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateOrderStart());
      const order = await orderClient.updateShippingInformation(
        token,
        id,
        shippingInformation
      );
      dispatch(updateShippingInformationSuccess(order));
    } catch (err) {
      const error = getAxiosError(err);
      const message = formatSubstitutionError(error) || JSON.stringify(error);
      dispatch(updateShippingInformationError(message));
    }
  };
