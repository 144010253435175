import React, { useState, useCallback } from 'react';
import {
  Button, Portal, Segment, Header, ButtonProps, Grid, Container,
} from 'semantic-ui-react';

interface IConfirmationButton extends ButtonProps {
  content: string;
  disabled: boolean;
  title: string;
  message: string;
  ControllerComponent?: React.ReactNode;
  onSubmit: Function;
  onError?: Function;
  onCancel?: Function;
}

export const ConfirmationButton = ({
  title, message, ControllerComponent, onSubmit, onError, onCancel, ...props
}: IConfirmationButton) => {
  const [isOpen, setOpen] = useState(false);
  const openHandler = useCallback(() => {
    if (isOpen && onCancel) {
      onCancel();
    }
    setOpen(!isOpen);
  },
  [isOpen],
  );
  const buttonProps = { ...props } as ButtonProps;
  return (
    <>
      <Button {...buttonProps} onClick={openHandler} />
      <Portal open={isOpen} onClose={openHandler}>
        <Segment style={{
          maxWidth: '30rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Grid padded>
            <Grid.Row><Header>{title}</Header></Grid.Row>
            <Grid.Row>{message && <p>{message}</p>}</Grid.Row>
            <Grid.Row stretched>{ControllerComponent}</Grid.Row>
            <Grid.Row>
              <Container textAlign="right">
                <Button
                  compact
                  color={buttonProps.color}
                  onClick={() => {
                    const isError = onError && onError();
                    if (!isError) {
                      onSubmit();
                      openHandler();
                    }
                  }}
                  data-testid="confirmation-button"
                >
                  {buttonProps.content}
                </Button>
                <Button compact onClick={openHandler}>Back</Button>
              </Container>
            </Grid.Row>
          </Grid>
        </Segment>
      </Portal>
    </>
  );
};
