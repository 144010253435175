import React from 'react';
import { Button, Message } from 'semantic-ui-react';

export const DismissibleErrorMessage = (
  props: { message: string | null, handleError: Function
  }) => {
  if (!props.message) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      zIndex: 1000,
    }}>
      <div className='ui segment'>
        <Message negative content={props.message} />
        <div style={ { display: 'flex', justifyContent: 'space-around' }} >
          <Button content="Dismiss error" onClick={() => props.handleError()} />
        </div>
      </div>
    </div>
  );
};
