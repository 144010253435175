/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { KeycloakProvider } from '@react-keycloak/web';
import { Dimmer, Loader } from 'semantic-ui-react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { OrdersPage } from './pages/orders/orders-page';
import { PrivateRoute } from './protected-route';
import { keycloak } from './keycloak';
import { AppMenu } from './app-menu';
import { OrderPage } from './pages/orders/order-page';
import { SearchPage } from './pages/search/search-page';
import { PatientPage } from './pages/patient/patient-page';
import { ProductsPage } from './pages/products/products-page';
import { MedicationsPage } from './pages/medications/medications-page';
import { MedicationPage } from './pages/medications/medication-page';
import { ProductPage } from './pages/products/product-page';
import { ErrorBoundary } from './shared/utils/component-utils';
import { ReportsPage } from './pages/reports/reports-page';
import { ReportPage } from './pages/reports/report-page';

const PageLoader = () => (
  <Dimmer active>
    <Loader size="massive">Loading</Loader>
  </Dimmer>
);

class AppRouter extends Component {
  render() {
    return (
      <KeycloakProvider keycloak={keycloak} LoadingComponent={<PageLoader />}>
        <ErrorBoundary>
          <Router>
            <Route path="/*" component={AppMenu} />
            <PrivateRoute exact path="/orders" component={OrdersPage} />
            <PrivateRoute exact path="/orders/view/:id" component={OrderPage} />
            <PrivateRoute
              exact
              path="/patients/edit/:id"
              component={PatientPage}
              pageContext={'edit'}
            />
            <PrivateRoute
              exact
              path="/patients/view/:id"
              component={PatientPage}
              pageContext={'view'}
            />
            <PrivateRoute exact path="/products" component={ProductsPage} />
            <PrivateRoute exact path="/search" component={SearchPage} />
            <PrivateRoute
              exact
              path="/products/view/:id"
              component={ProductPage}
              pageContext={'view'}
            />
            <PrivateRoute
              exact
              path="/products/edit/:id"
              component={ProductPage}
              pageContext={'edit'}
            />
            <PrivateRoute
              exact
              path="/products/create"
              component={ProductPage}
              pageContext={'create'}
            />
            <PrivateRoute
              exact
              path="/medications/view/:id"
              component={MedicationPage}
              pageContext={'view'}
            />
            <PrivateRoute
              exact
              path="/medications/edit/:id"
              component={MedicationPage}
              pageContext={'edit'}
            />
            <PrivateRoute
              exact
              path="/medications/create"
              component={MedicationPage}
              pageContext={'create'}
            />
            <PrivateRoute
              exact
              path="/medications"
              component={MedicationsPage}
            />
            <PrivateRoute exact path="/reports" component={ReportsPage} />
            <PrivateRoute
              exact
              path="/reports/view/:id"
              component={ReportPage}
            />
            <Route exact path="/">
              <Redirect to="/orders" />
            </Route>
          </Router>
        </ErrorBoundary>
      </KeycloakProvider>
    );
  }
}

export default withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_KEY || '',
})(AppRouter);
