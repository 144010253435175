import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPatientById, updatePatientById } from '../api-clients/patients';
import type { IPatient, IPatientDto } from '../interfaces';
import type { AppThunk } from '../store';

interface PatientState {
  patient: IPatient | null;
  error: string | null;
  isPatientLoading: boolean;
}

const initialState: PatientState = {
  patient: null,
  error: null,
  isPatientLoading: false,
};

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    getPatientSuccess(state, action: PayloadAction<IPatient>) {
      state.patient = action.payload;
      state.error = null;
      state.isPatientLoading = false;
    },
    getPatientError(state, action: PayloadAction<string>) {
      state.patient = null;
      state.error = action.payload;
      state.isPatientLoading = false;
    },
    getPatientStart(state) {
      state.isPatientLoading = true;
    },
  },
});

export const {
  getPatientError,
  getPatientSuccess,
  getPatientStart,
} = patientsSlice.actions;

export const patientsReducer = patientsSlice.reducer;

export const fetchPatient = (token: string, id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getPatientStart());
    const patient = await getPatientById(token, id);
    dispatch(getPatientSuccess(patient));
  } catch (err: any) {
    dispatch(getPatientError(err.toString()));
  }
};

export const updatePatient = (token: string, id: string, patient: IPatientDto): AppThunk => async (dispatch) => {
  try {
    dispatch(getPatientStart());
    const updatedPatient = await updatePatientById(token, id, patient);
    dispatch(getPatientSuccess(updatedPatient));
  } catch (err: any) {
    dispatch(getPatientError(err.toString()));
  }
};
