import React from 'react';
import { format } from 'date-fns-tz';
import { IOverFortyReportEntry } from '../../interfaces';
import { SearchableTable } from '../searchable-table';
import {
  dispensedMedicationCol,
  orderIdCol,
  patientAgeCol,
  patientIdCol,
  patientNameCol,
  pharmacistCol,
  prescriberCol,
  requestedMedicationCol,
  upstreamIdCol,
} from '../../shared/data/report-columns';

interface IOverFortyListProps {
  overForties: IOverFortyReportEntry[];
}

export const OverFortyList = (
  { overForties }: IOverFortyListProps,
) => {
  const columns = React.useMemo(() => [
    orderIdCol,
    patientIdCol,
    patientNameCol,
    patientAgeCol,
    upstreamIdCol,
    requestedMedicationCol,
    dispensedMedicationCol,
    {
      Header: 'Order Creation Date',
      accessor: 'orderCreationDate',
      Cell: ({ value }: any) => {
        const date = new Date(value.split('.')[0]);
        return format(date, 'do MMMM yyyy HH:mm');
      },
    },
    pharmacistCol,
    prescriberCol,
  ], []);

  return (
    <SearchableTable columns={columns} rows={overForties} />
  );
};
