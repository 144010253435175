import {
  Breadcrumb,
  Menu,
  Container,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ProductsList } from '../../components/product/products-list';
import type { IProduct } from '../../interfaces';
import { useSwr } from '../../hooks/swr';

export const ProductsPage = () => {
  const { data: products = [], error } = useSwr<IProduct[]>('/api/products');

  const { createProductsEnabled } = useFlags();

  if (error) {
    return <Segment>Error fetching products: {error.message}</Segment>;
  }

  return (
    <Container>
      <Breadcrumb
        divider="/"
        sections={[
          { key: 'home', content: <Icon name="home" /> },
          { key: 'products', content: <a href="/products">Products</a> },
        ]}
      />

      <Header size="medium">
        <Icon name="pills" />
        Products List
      </Header>
      <ProductsList products={products}>
        <Menu.Menu compact="true" position="left" size="mini" icon="labeled">
          {createProductsEnabled && (
            <Menu.Item name="create" onClick={() => { }}>
              <Icon name="plus" />
              <Link to="/products/create"> Create Product </Link>
            </Menu.Item>
          )}
        </Menu.Menu>
      </ProductsList>
    </Container>
  );
};
