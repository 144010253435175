import React from 'react';
import { IRejectionsReportEntry } from '../../interfaces';
import { SearchableTable } from '../searchable-table';
import {
  companyIdCol,
  createdAtCol,
  orderIdCol,
  patientAgeCol,
  patientDobCol,
  patientIdCol,
  patientNameCol,
  prescriptionTypeCol,
  reasonCol,
  resolutionDateCol,
  statusCol,
  requestedMedicationCol,
} from '../../shared/data/report-columns';

interface IRejectionsListProps {
  rejections: IRejectionsReportEntry[];
}

export const RejectionsList = (
  { rejections }: IRejectionsListProps,
) => {

  const columns = React.useMemo(() => [
    orderIdCol,
    patientIdCol,
    patientNameCol,
    patientAgeCol,
    patientDobCol,
    createdAtCol,
    companyIdCol,
    requestedMedicationCol,
    resolutionDateCol,
    statusCol,
    reasonCol,
    prescriptionTypeCol,
  ], []);

  return (
    <SearchableTable columns={columns} rows={rejections} />
  );
};
