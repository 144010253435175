import { get, put, post } from './base';
import type { IProduct } from '../interfaces';

const BASE_URL = '/api/products';

export const getProducts = async (token: string): Promise<IProduct[]> => {
  const data = await get<IProduct[]>(BASE_URL, token);
  return data;
};

export const getSingleProduct = async (token: string, id: string): Promise<IProduct> => {
  const url = `${BASE_URL}/${id}`;
  const data = await get<IProduct>(url, token);
  return data;
};

export const storeProduct = (token: string) => async (product: IProduct): Promise<IProduct> => {
  const data = product.id
    ? await put<IProduct>(`${BASE_URL}/${product.id}`, token, 'product', product)
    : await post<IProduct>(BASE_URL, token, 'product', product);
  return data;
};
