import React, { ReactNode } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Tab } from 'semantic-ui-react';
import { Conditions, shouldRender } from './conditional-renderer';

type TabType = Conditions & {
  label: string,
  component: ReactNode,
  role?: string,
};

export const Tabs = ({ tabs }: { tabs: TabType[] }) => {
  const { keycloak } = useKeycloak();

  const panes = tabs
    .filter((tab) => shouldRender(keycloak, tab))
    .map((tab, i) => ({
      menuItem: tab.label,
      render: () => <Tab.Pane key={i}>{ tab.component }</Tab.Pane>,
    }));

  return <Tab panes={panes} />;
};
