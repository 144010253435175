import React, { Fragment, useCallback, useState } from 'react';
import {
  Accordion, Button, Dimmer, Form, Icon, Loader, Modal, TextArea,
} from 'semantic-ui-react';
import { useKeycloak } from '@react-keycloak/web';
import { get } from 'lodash/fp';
import Iframe from 'react-iframe';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormattedProducts, IOrder, MedicationType,
} from '../../interfaces';

import { resetPrescriptionSave, savePrescription } from '../../reducers/prescriptionsSlice';
import { RootState } from '../../root-reducer';
import { fetchOrder } from '../../reducers/ordersSlice';

const AccordionItem = (
  product: Partial<FormattedProducts>,
  ind: number,
  activeIndex: number,
  setActiveIndex: Function,
  callbackOnChange: Function,
) => {
  const [instructions, setInstructions] = useState(product.instructions?.replace(/\n/g, ''));

  return (
    <div key={`acc-${ind}`}>
      <Accordion.Title
        active={activeIndex === ind}
        index={ind}
        onClick={() => setActiveIndex(ind)}
      >
        <Icon name='dropdown' />
        {product.name}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === ind}>
        <Form>
          <TextArea
            placeholder='Provide instructions for product'
            value={instructions}
            onChange={(event, { value }) => {
              setInstructions(`${value}`);
              callbackOnChange(ind, value);
            }}
          />
        </Form>
      </Accordion.Content>
    </div>
  );
};

interface InstructionsParams {
  order: IOrder;
  products: Array<Partial<FormattedProducts>>;
  keycloakToken: string | undefined;
  setShowModal: Function;
}

const ShowEditableInstructions = ({
  order, products, keycloakToken, setShowModal,
}: InstructionsParams) => {
  const pomProductsOnly = products.filter((item) => {
    return item.productType === 'medication' && item.type === MedicationType.pom;
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [editableProducts, setEditableProducts] = useState(pomProductsOnly);

  const dispatch = useDispatch();

  const {
    isSavingPrescription,
  } = useSelector((state: RootState) => state.prescriptionsReducer);

  const saveP = useCallback(() => {
    const prescriptionProductUpdate = editableProducts.map(p => ({ id: p.id, instructions: p.instructions }))
    dispatch(resetPrescriptionSave());
    dispatch(savePrescription(keycloakToken || '', order.id, prescriptionProductUpdate as any));
  }, []);

  return (
    <div>
      <Dimmer active={isSavingPrescription}>
        <Loader />
      </Dimmer>
      <Accordion fluid styled>
        {editableProducts.map((product, index) => {
          return AccordionItem(product,
            index,
            activeIndex,
            setActiveIndex,
            (changedIndex: number, newInstructions: string) => {
              editableProducts[changedIndex].instructions = newInstructions;
              setEditableProducts(editableProducts);
            });
        })}
      </Accordion>
      <Button
        floated='left'
        style={{ marginTop: '15px', marginBottom: '15px' }}
        content='Cancel'
        color='red'
        onClick={() => setShowModal(false)}
        icon="cancel"
      />
      <Button
        onClick={() => {
          saveP();
        }}
        content='Create Prescription'
        color='green'
        floated='right'
        style={{ marginTop: '15px', marginBottom: '15px' }}
        icon="save outline"
      />
    </div>
  );
};

export const ApprovePomButton = ({ order, products, disabled = false }: IOrderComponentProps) => {
  const [showModal, setShowModal] = useState(false);
  const { keycloak } = useKeycloak();
  const keycloakToken = get('token', keycloak) || '';

  const {
    prescriptionUrl,
  } = useSelector((state: RootState) => state.prescriptionsReducer);

  const dispatch = useDispatch();
  const style = prescriptionUrl?.url ? { minWidth: '1400px' } : { width: '700px' };

  return (
    <Fragment>
      <Button
        loading={showModal}
        disabled={showModal || disabled}
        content='Sign & Approve'
        icon='check circle'
        color='green'
        onClick={() => setShowModal(true)}
      />
      <Modal
        open={showModal}
        style={style}
        closeIcon
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
          dispatch(resetPrescriptionSave());
          dispatch(fetchOrder(keycloakToken, order.id));
        }}
      >
        {!prescriptionUrl?.url && <>
          <Modal.Header>Validate/Edit Instructions</Modal.Header>
          <Modal.Content>
            <ShowEditableInstructions
              keycloakToken={keycloakToken}
              order={order}
              products={products}
              setShowModal={setShowModal}
            />
          </Modal.Content>
        </>
        }
        {prescriptionUrl?.url && <>
          <Modal.Header>Sign Prescription</Modal.Header>
          <Modal.Content data-testid="prescription-signature">
            <p>{prescriptionUrl?.url}</p>
            <Iframe
              url={prescriptionUrl?.url}
              width="100%"
              height="1500px"
            />
          </Modal.Content>
        </>
        }
      </Modal>
    </Fragment>
  );
};

interface IOrderComponentProps {
  order: IOrder,
  products: Array<Partial<FormattedProducts>>,
  disabled?: boolean,
}
