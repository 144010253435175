import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { get } from 'lodash/fp';
import { useKeycloak } from '@react-keycloak/web';
import { handleError } from '../error-handler';
import type { ICreatePatientNoteButtonProps } from '../interfaces';
import { ConfirmationInputButton } from './confirmation-input-button';

export const CreatePatientNoteButton = (
  { onComplete, patientId }: ICreatePatientNoteButtonProps,
) => {
  const { keycloak } = useKeycloak();
  const [noteContent, setNoteContent] = useState('');

  const createPatientNote = useCallback(async () => {
    if (noteContent.trim()) {
      try {
        await axios.post(
          `/api/patients/${patientId}/notes`,
          {
            data: {
              type: 'patient',
              attributes: {
                content: noteContent,
              },
            },
          },
          {
            headers: {
              Authorization: `Bearer ${get('token', keycloak)}`,
              ContentType: 'application/vnd.api+json',
            },
          },
        );
        if (onComplete) {
          onComplete();
        }
      } catch (error) {
        await handleError(error);
      }
    }
  }, [patientId, noteContent]);

  return (
    <ConfirmationInputButton
      color="purple"
      basic={true}
      icon="sticky note"
      message="Enter the medical note content"
      disabled={false}
      content="Add medical note"
      title="Add medical note"
      placeholder="Note content"
      onSubmit={createPatientNote}
      onChange={(content: string) => setNoteContent(content)}
    />
  );
};
