import {
  Column, Row, useAsyncDebounce, useGlobalFilter, usePagination, useTable,
} from 'react-table';
import React, { useEffect, useState } from 'react';
import {
  Button, Container, Dropdown, Input, Menu, Table,
} from 'semantic-ui-react';
import { toSafeInteger } from 'lodash/fp';

const pageDropdownValues = [
  {
    key: 10,
    value: 10,
    text: 10,
  },
  {
    key: 20,
    value: 20,
    text: 20,
  },
  {
    key: 30,
    value: 30,
    text: 30,
  },
  {
    key: 40,
    value: 40,
    text: 40,
  },
  {
    key: 50,
    value: 50,
    text: 50,
  },
];

export const SearchableTable = ({ columns, rows, children }: ITableProps) => {
  const [searchFilter, setSearchFilter] = useState('');

  const onSearchValueHandler = (handler: React.Dispatch<React.SetStateAction<string>>) => {
    return (e: any) => handler(e.target.value);
  };

  const memoProducts = React.useMemo(() => rows, [rows]);

  const tableInstance = useTable(
    {
      columns,
      data: memoProducts,
    },
    useGlobalFilter,
    usePagination,
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    setGlobalFilter,
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // Fixes "Warning: Can't perform a React state update on an unmounted component"
  // TODO: move this to a custom hook (useMounted)
  // Current impediment: passing a function to a hook and calling it inside the hook
  // breaks the hooks rule
  const isMounted = React.useRef(false);
  const debounceHandler = useAsyncDebounce((value) => {
    if (isMounted.current) {
      setGlobalFilter(value || undefined);
    }
    isMounted.current = true;
  }, 200);

  useEffect(() => {
    debounceHandler(searchFilter);
  }, [searchFilter]);

  return (
    <Container>
      <Menu>
        {children}
        <Menu.Menu position="right">
          <Menu.Item>
            <Input
              value={searchFilter}
              icon='search'
              placeholder='Search...'
              onChange={onSearchValueHandler(setSearchFilter)}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <div style={{overflowX: 'scroll'}}>
        <Table {...getTableProps()}>
          <Table.Header>
            {headerGroups.map((headerGroup, i) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <Table.HeaderCell {...column.getHeaderProps()}
                    key={j}>{column.render('Header')}</Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row: Row, l: number) => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()} key={l}>
                  {row.cells.map((cell, k) => {
                    return <Table.Cell {...cell.getCellProps()}
                      key={k}>{cell.render('Cell')}</Table.Cell>;
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      <div className="flex items-center justify-end">
        <div>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Button>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Button>
        </div>
        <Dropdown
          className="mx1"
          options={pageDropdownValues}
          value={pageSize}
          text={`Show ${pageSize}`}
          onChange={(_, selected) => setPageSize(toSafeInteger(selected.value))}
        />
        <span>
          {`Page ${pageIndex + 1} of ${pageCount === 0 ? 1 : pageCount}`}
        </span>
      </div>
    </Container>
  );
};

export interface ITableProps {
  columns: Column<object>[];
  rows: Array<object>;
  children?:
  | React.ReactChild
  | React.ReactChild[];
}
