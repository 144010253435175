import React from 'react';
import { IMigraineReportEntry } from '../../interfaces';
import { SearchableTable } from '../searchable-table';
import {
  dispensedDateCol,
  dispensedMedicationCol,
  orderIdCol,
  patientAgeCol,
  patientAnswerCol,
  patientEmailCol,
  patientIdCol,
  patientNameCol,
  reasonCol,
  requestedMedicationCol,
  resolutionDateCol,
  statusCol,
} from '../../shared/data/report-columns';

interface IMigraineListProps {
  migraines: IMigraineReportEntry[];
}

export const MigraineList = (
  { migraines }: IMigraineListProps,
) => {
  const columns = React.useMemo(() => [
    orderIdCol,
    patientIdCol,
    patientNameCol,
    patientAgeCol,
    patientEmailCol,
    patientAnswerCol,
    requestedMedicationCol,
    dispensedMedicationCol,
    dispensedDateCol,
    resolutionDateCol,
    statusCol,
    reasonCol,
  ], []);

  return (
    <SearchableTable columns={columns} rows={migraines} />
  );
};
