/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import download from 'downloadjs';
import { deserialize } from 'json-api-deserialize';

export const quietDeserialize = (input: object) => {
  if (!input) {
    return input;
  }
  const processed = deserialize(input);
  delete processed.deserialized;
  return processed;
};

const DEFAULT_TIMEOUT = 1000 * 30;

export const get = async <T>(url: string, token: string) => {
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: DEFAULT_TIMEOUT,
  });

  if (response.errors) {
    // TODO: improve this handler:
    console.error('Error fetching', url, response.errors);
    throw new Error(response.errors[0].title);
  }
  if (response.pagination) {
    return quietDeserialize(response) as T;
  }
  return quietDeserialize(response.data) as T;
};

export const remove = async <T>(url: string, token: string) => {
  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.errors) {
    // TODO: improve this handler:
    console.error('Error fetching', url, response.errors);
    throw new Error(response.errors[0].title);
  }

  if (response.pagination) {
    return quietDeserialize(response.data) as T;
  }

  return quietDeserialize(response.data) as T;
};

// eslint-disable-next-line max-len
export const post = async <T>(
  url: string,
  token: string,
  type?: string,
  attributes?: object,
  headers?: object,
) => {
  const postBody = attributes && type
    ? {
      data: {
        type,
        attributes: {
          ...attributes,
        },
      },
    }
    : null;

  const { data: response } = await axios.post(url, postBody, {
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'application/vnd.api+json',
      ...headers,
    },
  });
  return quietDeserialize(response.data) as T;
};

export const doDelete = async <T>(url: string, token: string) => {
  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return quietDeserialize(response.data) as T;
};

// eslint-disable-next-line max-len
export const put = async <T>(
  url: string,
  token: string,
  type: string,
  attributes: object,
  headers?: object,
) => {
  const { data: response } = await axios.put(
    url,
    {
      data: {
        type,
        attributes: {
          ...attributes,
        },
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
    },
  );

  return quietDeserialize(response.data) as T;
};

const downloadFile = async (
  url: string,
  token: string,
  fileName: string,
  extension: string,
  mimeType: string,
) => {
  // eslint-disable-next-line no-undef
  const res = await fetch(
    new Request(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }),
  );

  const blob = await res.blob();
  await download(blob, `${fileName}.${extension}`, mimeType);
};

export const downloadXslsFile = (
  downloadUrl: string,
  token: string,
  fileName: string,
) => {
  downloadFile(
    downloadUrl,
    token,
    fileName,
    'xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );
};

export const downloadPDFFile = async (
  downloadUrl: string,
  token: string,
  fileName: string,
) => {
  downloadFile(downloadUrl, token, fileName, 'pdf', 'application/pdf');
};
