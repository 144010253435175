import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { has } from 'lodash';
import type { IMedication } from '../../interfaces';

export const WithMedicationRequestError = ({ error }: { error: { message: string } }) =>
  (has(error, 'message') ? () => <Segment>Error fetching medication: {error?.message}</Segment> : null);

export const WithNoMedication = ({ medication }: { medication: IMedication }) =>
  (!medication ? () => <Segment>Loading...</Segment> : null);

interface IHeaderContent {
  medication: IMedication;
}

export const HeaderContent = ({
  medication,
}: IHeaderContent) => {
  if (!medication) return null;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ flex: 1 }}>
        <>{medication.name}</>
      </div>
      <div style={{ display: 'flex' }}>
        <div>Available: {medication.available ? <Icon name="check" /> : <Icon name="cancel" />}</div>
        <div>Type: {medication.type}</div>
      </div>
    </div>
  );
};
