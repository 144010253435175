import React, { useState, useCallback } from 'react';
import { get } from 'lodash/fp';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import type { IPrescriber } from '../../interfaces';
import { handleError } from '../../error-handler';
import { ConfirmationInputButton } from '../confirmation-input-button';

export const CreateOrderNoteButton = ({ onComplete, prescriber, orderId }: ICreateOrderNoteButtonProps) => {
  const { keycloak } = useKeycloak();
  const [noteContent, setNoteContent] = useState('');

  const {
    prescriberId = null,
    prescriberUsername = null,
  } = prescriber;

  const createOrderNote = useCallback(async () => {
    if (noteContent.trim()) {
      try {
        await axios.post(
          `/api/orders/${orderId}/notes`,
          {
            data: {
              type: 'order-note',
              attributes: {
                authorEmail: prescriberId,
                authorName: prescriberUsername,
                content: noteContent,
              },
            },
          },
          {
            headers: {
              Authorization: `Bearer ${get('token', keycloak)}`,
              ContentType: 'application/vnd.api+json',
            },
          },
        );
        if (onComplete) {
          onComplete();
        }
      } catch (error) {
        await handleError(error);
      }
    }
  }, [orderId, noteContent]);

  return (
    <ConfirmationInputButton
      color="grey"
      message="Enter the order note content"
      icon="add"
      disabled={false}
      content="Add order note"
      title="Add order note"
      placeholder="Note content"
      onSubmit={createOrderNote}
      onChange={(content: string) => setNoteContent(content)}
    />
  );
};

interface ICreateOrderNoteButtonProps {
  onComplete?: VoidFunction;
  prescriber: IPrescriber;
  orderId: string;
}
