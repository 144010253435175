import React, { useCallback, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  Breadcrumb, Container, Icon, Form, Segment, Label,
} from 'semantic-ui-react';

import { Dispatch } from '@reduxjs/toolkit';

import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import type { SchemaOf } from 'yup';

import {
  useFlags,
} from 'launchdarkly-react-client-sdk';

import type {
  IEditPageProps, IMedication, IMedicationDto, MetaData,
} from '../../interfaces';
import { Header } from '../../components/header';
import { RootState } from '../../root-reducer';
import {
  getSingleMedication,
  medicationsErrorHandled,
  saveMedication,
  successfulStoreHandled,
} from '../../reducers/medicationsSlice';
import { ConditionalRenderer } from '../../components/conditional-renderer';
import { HeaderContent } from './header-content';
import { B2bForm } from '../../components/form';
import { WrappedCheckbox } from '../../components/wrapped-checkbox';
import { emptyFormData } from '../../transforms/empty-form-data';
import { GenericList } from '../../components/generic-view';
import { DismissibleErrorMessage } from '../../components/dismissableErrorMessage';

const medicationFormMetaData = [{ fieldName: 'name', icon: 'info circle' },
  {
    fieldName: 'instructions',
    type: 'textarea',
    component: Form.TextArea,
    icon: 'pencil alternate',
    shouldRenderOnStatic: true,
  },
  { fieldName: 'units', icon: 'box', shouldRenderOnStatic: true },
  { fieldName: 'packSize', icon: 'info circle', shouldRenderOnStatic: true },
  { fieldName: 'form', icon: 'info circle', shouldRenderOnStatic: true },
  { fieldName: 'categoryCode', icon: 'info circle', shouldRenderOnStatic: true },
  { fieldName: 'shortCode', icon: 'info circle', shouldRenderOnStatic: true },
  { fieldName: 'available', component: WrappedCheckbox, icon: 'info circle' },
  { fieldName: 'type', icon: 'info circle' },
  { fieldName: 'dosageLevel', icon: 'box' }] as MetaData[];

const medicationDtoSchema: SchemaOf<IMedicationDto> = yup.object().shape({
  name: yup.string().min(1, 'Please enter the full product name').required(),
  available: yup.boolean().required('Is this product currently available for sale?'),
  units: yup.number().required('Please specify the total doses in stock'),
  form: yup.string().trim().ensure().required('Eg: Pill, Patch, Nasal Spray'),
  type: yup.string().required(),
  dosageLevel: yup.string().trim().ensure().required('You must specify the dosage amount per unit'),
  instructions: yup.string().trim().ensure(),
  packSize: yup.number()
    .required()
    .positive('Cannot be negative')
    .integer('Must be a whole number'),
  categoryCode: yup.string().required(),
  shortCode: yup.string().required(),
});

const getPageContent = (token: string, id: string,
  pageContext: string, medication: IMedication, isLoading: boolean, dispatch: Dispatch<any>) => {
  switch (pageContext) {
  case 'edit': {
    return <ConditionalRenderer role='medication.EDIT' condition={ medication && !isLoading }>
      <B2bForm data={medication}
        formFieldData={medicationFormMetaData}
        validationSchema={medicationDtoSchema}
        updateHandler={(med: IMedicationDto) => dispatch(
          saveMedication(token, med, id),
        )} />
    </ConditionalRenderer>;
  }
  case 'create': {
    return <ConditionalRenderer role='medication.EDIT' condition={ !isLoading }>
      <B2bForm data={emptyFormData(medicationFormMetaData)}
        formFieldData={medicationFormMetaData}
        validationSchema={medicationDtoSchema}
        updateHandler={(med: IMedicationDto) => dispatch(
          saveMedication(token, med, id),
        )} />
    </ConditionalRenderer>;
  }
  case 'view': {
    return <ConditionalRenderer condition={ medication && !isLoading } >
      <GenericList metaData={medicationFormMetaData} dto={medication} />
    </ConditionalRenderer>;
  }
  default:
    return null;
  }
};

export const MedicationPage = ({ pageContext = 'view' }: IEditPageProps) => {
  // @ts-ignore
  const { id } = useParams();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token as string;
  const dispatch = useDispatch();
  const {
    isLoading, medications, storedSuccessfully, createdId, error,
  } = useSelector((s: RootState) => s.medicationsReducer);
  const medication = medications[id];

  const getMedication = useCallback(
    async () => { await dispatch(getSingleMedication(token, id)); },
    [token, id, dispatch],
  );

  const { createMedicationEnabled } = useFlags();

  useEffect(() => {
    if (pageContext === 'view' || pageContext === 'edit') {
      getMedication();
    }
  }, [getMedication, pageContext]);

  useEffect(() => {
    if (storedSuccessfully) {
      history.push(`/medications/view/${createdId}`);
      dispatch(successfulStoreHandled());
    }
  }, [storedSuccessfully, createdId, history, dispatch]);

  if (!medication && (pageContext === 'view' || pageContext === 'edit')) {
    return <Label>Loading</Label>;
  }

  return <>
    <DismissibleErrorMessage
      handleError={() => dispatch(medicationsErrorHandled())}
      message={error} />
    <Container>
      <Breadcrumb divider='/' sections={[
        { key: 'home', content: (<Icon name='home' />) },
        { key: 'medications', content: (<a href='/medications'>Medications</a>) },
        { key: 'info', content: id, active: true },
      ]} />
      <Header icon='syringe'>
        <HeaderContent
          medication={medication}
        />
      </Header>
      <Segment>
        { getPageContent(token, id, pageContext, medication, isLoading, dispatch) }
      </Segment>
      <ConditionalRenderer role='medication.POST' condition={pageContext === 'create'}>
        <Icon name='cancel' />
        <Link to={'/medications'}> Cancel </Link>
      </ConditionalRenderer>
      {createMedicationEnabled && (<ConditionalRenderer role='medication.POST' condition={ pageContext === 'view' }>
        <Link to={`/medications/edit/${id}`}> Edit Medication </Link>
      </ConditionalRenderer>)}
      <ConditionalRenderer role='medication.POST' condition={pageContext === 'edit'}>
        <Icon name='cancel' />
        <Link to={`/medications/view/${id}`}> Cancel </Link>
      </ConditionalRenderer>
    </Container>
  </>;
};
