import { format } from 'date-fns-tz';
import { LinkCellBuilder } from '../../components/report/link-cell';

export const orderIdCol = {
  Header: 'Order Id',
  accessor: 'orderId',
  Cell: LinkCellBuilder('orders/view'),
};

export const patientIdCol = {
  Header: 'Patient Id',
  accessor: 'patientId',
  Cell: LinkCellBuilder('patients/view'),
};

export const patientNameCol = {
  Header: 'Patient Name',
  accessor: 'patientName',
};

export const patientAgeCol = {
  Header: 'Patient Age',
  accessor: 'patientAge',
};

export const patientEmailCol = {
  Header: 'Patient Email',
  accessor: 'patientEmail',
};

export const patientAnswerCol = {
  Header: 'Patient Answer',
  accessor: 'patientAnswer',
};

export const upstreamIdCol = {
  Header: 'Upstream Id',
  accessor: 'upstreamId',
};

export const patientDobCol = {
  Header: 'Patient DOB',
  accessor: 'patientDob',
  Cell: ({ value }: any) => format(new Date(value), 'do MMMM yyyy HH:mm'),
};

export const createdAtCol = {
  Header: 'Created At',
  accessor: 'orderCreationDate',
  Cell: ({ value }: any) => format(new Date(value), 'do MMMM yyyy HH:mm'),
};

export const prescriberCol = {
  Header: 'Prescriber',
  accessor: 'prescriber',
};

export const pharmacistCol = {
  Header: 'Pharmacist',
  accessor: 'pharmacist',
};

export const requestedMedicationCol = {
  Header: 'Requested Medication',
  accessor: 'requestedMedication',
};

export const dispensedMedicationCol = {
  Header: 'Dispensed Medication',
  accessor: 'dispensedMedication',
};

export const dispensedMedicationTypeCol = {
  Header: 'Dispensed Medication Type',
  accessor: 'dispensedMedicationType',
};

export const dispensedDateCol = {
  Header: 'Dispensed Date',
  accessor: 'dispensedDate',
  Cell: ({ value }: any) => (value ? format(new Date(value), 'do MMMM yyyy HH:mm') : ''),
};

export const companyIdCol = {
  Header: 'Company ID',
  accessor: 'companyId',
};

export const resolutionDateCol = {
  Header: 'Resolution Date',
  accessor: 'resolutionDate',
  Cell: ({ value }: any) => format(new Date(value), 'do MMMM yyyy HH:mm'),
};

export const statusCol = {
  Header: 'Status',
  accessor: 'status',
};

export const reasonCol = {
  Header: 'Reason',
  accessor: 'reason',
};

export const prescriptionTypeCol = {
  Header: 'Prescription Type',
  accessor: 'prescriptionType',
};

export const repeatCol = {
  Header: 'Repeat',
  accessor: 'repeat',
};
