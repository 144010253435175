import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPrescription, PrescriptionSigningResponse } from '../api-clients/prescriptions';
import { PrescriptionUpdate } from '../interfaces';
import type { AppThunk } from '../store';

interface PrescriptionsState {
  savingPrescription: string | null;
  isSavingPrescription: boolean;
  prescriptionUrl: PrescriptionSigningResponse | null;
  savingError: string | null;
}

const initialState: PrescriptionsState = {
  savingPrescription: null,
  isSavingPrescription: false,
  prescriptionUrl: null,
  savingError: null,
};

const prescriptionsSlice = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {
    savePrescriptionStart(state, action: PayloadAction<string>) {
      state.isSavingPrescription = true;
      state.savingPrescription = action.payload;
    },
    savePrescriptionSuccess(state, action: PayloadAction<PrescriptionSigningResponse>) {
      state.prescriptionUrl = action.payload;
      state.savingError = null;
      state.isSavingPrescription = false;
    },
    savePrescriptionError(state, action: PayloadAction<string>) {
      state.prescriptionUrl = null;
      state.savingError = action.payload;
      state.isSavingPrescription = false;
    },
    prescriptionSaveReset(state) {
      state.savingPrescription = initialState.savingPrescription;
      state.isSavingPrescription = initialState.isSavingPrescription;
      state.prescriptionUrl = initialState.prescriptionUrl;
      state.savingError = initialState.savingError;
    },
  },
});

export const {
  savePrescriptionError,
  savePrescriptionStart,
  savePrescriptionSuccess,
  prescriptionSaveReset,
} = prescriptionsSlice.actions;
export const prescriptionsReducer = prescriptionsSlice.reducer;

export const savePrescription = (token: string, orderId: string, editableProducts: Array<PrescriptionUpdate>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(savePrescriptionStart(orderId));
      const prescriptionResult = await createPrescription(token, orderId, editableProducts);
      dispatch(savePrescriptionSuccess(prescriptionResult));
    } catch (err) {
      dispatch(savePrescriptionError(err.toString()));
    }
  };

export const resetPrescriptionSave = (): AppThunk =>
  (dispatch) => {
    dispatch(prescriptionSaveReset());
  };
