import Keycloak from "keycloak-js";

const isDevPharmacy =
  window.location.hostname.includes(".pr-") ||
  window.location.hostname.includes(".staging.") ||
  window.location.hostname === "localhost";

export const keycloak = Keycloak({
  realm: isDevPharmacy ? "dev-pharmacy" : "pharmacy",
  url: "https://auth.phloconnect.com/auth/",
  clientId: "pharmacy-dashboard",
});
