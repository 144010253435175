import React from 'react';
import { differenceInYears, format } from 'date-fns';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  Breadcrumb, Container, Grid, Header, Icon, List, Menu, Segment, Button,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Dispatch } from '@reduxjs/toolkit';
import { useSwr } from '../../hooks/swr';
import { RelatedOrders } from '../../components/order/related-orders';
import type { IPatient, IPatientDto, MetaData } from '../../interfaces';
import { PatientNotes } from '../../components/patient-notes';
import type { RootState } from '../../root-reducer';
import { CreatePatientNoteButton } from '../../components/create-patient-note-button';
import { PatientEditForm } from '../../components/form';
import { GenericList } from '../../components/generic-view';
import { ConditionalRenderer } from '../../components/conditional-renderer';
import { updatePatient } from '../../reducers/patientsSlice';
import { getMedicationsAsArray } from '../../reducers/medicationsSlice';

const formatDob = ({ dob }: IPatientDto) => {
  if (!dob) {
    return '';
  }
  return `${format(new Date(dob), 'PPP')} (${differenceInYears(new Date(), new Date(dob))} years old)`;
};

const patientFormMetaData = [
  { fieldName: 'firstName', icon: 'id badge outline', shouldRenderOnStatic: true },
  { fieldName: 'lastName', icon: 'id badge outline', shouldRenderOnStatic: true },
  { fieldName: 'preferredName', icon: 'id badge outline', shouldRenderOnStatic: true },
  { fieldName: 'preferredPronouns', icon: 'id badge outline', shouldRenderOnStatic: true },
  { fieldName: 'sex', icon: 'venus mars', shouldRenderOnStatic: true },
  { fieldName: 'genderIdentity', icon: 'venus mars', shouldRenderOnStatic: true },
  {
    fieldName: 'dob', icon: 'birthday cake', shouldRenderOnStatic: true, formatField: formatDob,
  },
  { fieldName: 'email', icon: 'mail', shouldRenderOnStatic: true },
  { fieldName: 'telephone', icon: 'phone', shouldRenderOnStatic: true },
] as MetaData[];

interface PageContentProps {
  token: string,
  context: string,
  id: string,
  patient: IPatient | undefined,
  dispatch: Dispatch<any>,
  loading: boolean
}
const PageContent = ({
  token, context, id, patient, dispatch, loading,
}: PageContentProps) => {
  const history = useHistory();

  switch (context) {
  case 'edit': {
    return (
      <ConditionalRenderer role={'patient.PUT'}>
        <PatientEditForm
          initialValues={patient}
          updateHandler={(pat: IPatientDto) => {
            dispatch(updatePatient(token, id, pat));
            history.push(`/patients/view/${id}`);
          }}
          loading={loading}
        />
      </ConditionalRenderer>);
  }
  case 'view': {
    return <GenericList metaData={patientFormMetaData} dto={patient} />;
  }
  default: {
    return null;
  }
  }
};

export const PatientPage = ({ pageContext }: { pageContext: string }) => {
  const { id } = useParams<{ id: string }>();
  const { data: patient, error } = useSwr<IPatient>(`/api/patients/${id}`);

  const { keycloak } = useKeycloak();
  const token = keycloak?.token as string;

  const { products, isLoading: productsLoading } = useSelector((state: RootState) => state.productsReducer);
  const { isPatientLoading: patientsLoading } = useSelector((state: RootState) => state.patientsReducer);
  const { isLoading: medicationsLoading } = useSelector((state: RootState) => state.medicationsReducer);

  const medications = useSelector(getMedicationsAsArray);

  const dispatch = useDispatch();

  if (error) {
    return <div>
      Error fetching patient:
      {error.message}
    </div>;
  }

  if (!patient || medicationsLoading || productsLoading) {
    return <div>Loading</div>;
  }

  return (
    <Container>
      <Breadcrumb divider='/' sections={[
        { key: 'home', content: (<Icon name="home" />) },
        { key: 'patients', content: 'Patients' },
        { key: 'info', content: id, active: true },
      ]} />

      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6} >
              <Header size="huge">
                <List.Icon name='user' />
                <List.Content>
                  {`${patient.firstName} ${patient.lastName}`}
                </List.Content>
              </Header>

              <PageContent
                token={token}
                context={pageContext}
                id={id}
                patient={patient}
                dispatch={dispatch}
                loading={patientsLoading}
              />

            </Grid.Column>
            <Grid.Column width={10}>
              <PatientNotes patientId={id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Menu align="right">

        {pageContext !== 'edit' && (
          <ConditionalRenderer role={'patient.PUT'}>
            <Menu.Item name='edit'>
              <Link to={`/patients/edit/${id}`}>
                <Button color="blue" basic >
                  <Icon name="edit" />
                  Edit Patient Details
                </Button>
              </Link>
            </Menu.Item>
          </ConditionalRenderer>
        )}

        {pageContext === 'edit'
          && (
            <Menu.Item name='cancel' >
              <Link to={`/patients/view/${id}`}>
                <Button color="red" basic icon="pencil">
                  <Icon name="edit" />
                  Cancel Edit
                </Button>
              </Link>
            </Menu.Item>
          )
        }

        <ConditionalRenderer role={'patient.ADD_NOTE'}>
          <Menu.Item
            name='addNote'
            onClick={() => { }}
          >
            <CreatePatientNoteButton
              patientId={id}
              prescriber={{ prescriberId: '', prescriberUsername: '' }}
            />
          </Menu.Item>
        </ConditionalRenderer>
      </Menu>
      <Segment>
        <Header> Order History </Header>
        <RelatedOrders patientId={id} medications={medications} products={products} />
      </Segment>
    </Container>
  );
};
