import { combineReducers } from '@reduxjs/toolkit';
import { productsReducer } from './reducers/productsSlice';
import { medicationsReducer } from './reducers/medicationsSlice';
import { ordersReducer } from './reducers/ordersSlice';
import { patientsReducer } from './reducers/patientsSlice';
import { prescriptionsReducer } from './reducers/prescriptionsSlice';
import { reportsReducer } from './reducers/reportsSlice';
import { searchReducer } from './reducers/searchSlice';

export const rootReducer = combineReducers({
  productsReducer,
  medicationsReducer,
  ordersReducer,
  patientsReducer,
  prescriptionsReducer,
  reportsReducer,
  searchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
