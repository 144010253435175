import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { approveOrder } from '../../reducers/ordersSlice';
import type { RootState } from '../../root-reducer';

export const ApproveButton = ({ orderId, token, disabled = false }: IOrderComponentProps) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.ordersReducer.isUpdatingOrder);

  return (
    <Button
      loading={loading}
      disabled={loading || disabled}
      content='Approve'
      icon='check circle'
      color='green'
      onClick={() => {
        dispatch(approveOrder(token, orderId));
      }}
    />
  );
};

interface IOrderComponentProps {
  orderId: string,
  token: string,
  disabled?: boolean,
}
