import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { dispenseOrder } from '../../reducers/ordersSlice';
import type { RootState } from '../../root-reducer';

interface IDispenseButtonProps {
  orderId: string,
  token: string,
  disabled?: boolean,
}

export const DispenseButton = ({ orderId, token, disabled = false }: IDispenseButtonProps) => {
  const { isUpdatingOrder } = useSelector(
    (state: RootState) => state.ordersReducer,
  );

  const dispatch = useDispatch();

  return (
    <Button
      loading={isUpdatingOrder}
      disabled={isUpdatingOrder || disabled}
      color='green'
      icon='check circle'
      content='Mark as dispensed'
      onClick={() => dispatch(dispenseOrder(token, orderId))} />
  );
};
