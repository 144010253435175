/* eslint-disable react/require-default-props */
import _ from "lodash/fp";
import React from "react";
import DatePicker from 'react-datepicker';
import { Dropdown, Input, List, Menu } from "semantic-ui-react";

import 'react-datepicker/dist/react-datepicker.css';

interface FilterValues {
  statuses: string[]
  startDate: Date | null,
  endDate: Date | null,
  searchText: string
}

interface FilterMenuProps {
  disableSearch: boolean
  filterValue: FilterValues
  onFilterChange: (newFilterValues: FilterValues) => void
  placeholderText?: {
    startDate?: string
    endDate?: string
    search?: string
    statuses?: string
  }
  statusOptions: { status: string, disabled: boolean }[]
}

const formatStatus = _.compose(_.startCase, _.lowerCase);

export const FilterMenu = ({
  disableSearch,
  filterValue,
  onFilterChange,
  placeholderText,
  statusOptions,
}: FilterMenuProps) => {
  const statusFilterOptions = statusOptions.map(({status, disabled}) => {
    return {
      key: status,
      text: formatStatus(status),
      value: status,
      disabled: disabled,
    };
  });

  return (
    <Menu>
      <Menu.Item> Statuses: </Menu.Item>
      <Dropdown
        placeholder={ placeholderText?.statuses ?? "Quickfilter for Statuses"}
        fluid
        multiple
        selection
        onChange={(event, data: any) => onFilterChange({
          ...filterValue,
          statuses: data.value,
        })}
        defaultValue={filterValue.statuses}
        options={statusFilterOptions}
      />
      <Menu.Menu position="right">
        <Menu.Item>
          <List>
            <List.Item>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                className="ui menu right small"
                selected={filterValue.startDate}
                onChange={(startDate: any) => onFilterChange({
                  ...filterValue,
                  startDate,
                })}
                isClearable
                placeholderText={ placeholderText?.startDate ?? 'Start Date' }
                selectsStart
                startDate={filterValue.startDate}
                endDate={filterValue.endDate}
              />
            </List.Item>
            <List.Item>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                className="ui menu right small"
                selected={filterValue.endDate}
                onChange={(endDate: any) => onFilterChange({
                  ...filterValue,
                  endDate,
                })}
                isClearable
                placeholderText={ placeholderText?.endDate ?? 'End Date' }
                selectsEnd
                startDate={filterValue.startDate}
                endDate={filterValue.endDate}
                minDate={filterValue.startDate}
              />
            </List.Item>
          </List>
        </Menu.Item>
        <Menu.Item>
          <Input
            value={filterValue.searchText}
            icon="search"
            placeholder={ placeholderText?.search ?? "Filter by..." }
            onChange={(event, data) => onFilterChange({
              ...filterValue,
              searchText: data.value,
            })}
            disabled={disableSearch}
          />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}
