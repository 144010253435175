import React from 'react';
import { startCase } from 'lodash';
import { List, Icon } from 'semantic-ui-react';
import { MetaData } from '../interfaces';

export interface GenericListProps {
  dto: any;
  metaData: MetaData[];
}

export const GenericList = ({ dto, metaData }: GenericListProps) => (
  <>
    {metaData
      .filter(({ shouldRenderOnStatic }) => shouldRenderOnStatic)
      .map(({
        icon, fieldName, labelText, specialRenderer, formatField,
      }) => {
        if (specialRenderer) {
          return <React.Fragment key={fieldName}>{specialRenderer(dto)}</React.Fragment>;
        }

        const value = formatField ? formatField(dto) : dto[fieldName];
        return (
          value && (<List.Item key={fieldName}>
            <Icon name={icon} />
            <strong>
              {labelText || startCase(fieldName)}
            </strong>: {value}
          </List.Item>));
      },
      )}
  </>);
