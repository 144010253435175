import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { AppThunk } from '../store';
import { getPatientsByQuery } from '../api-clients/patients';
import { getOrdersByQuery } from '../api-clients/orders';
import { ISearchResponse } from '../interfaces';

interface SearchState {
  results: ISearchResponse[] | null;
  error: string | null;
  searchTerm: string;
  isLoading: boolean;
  noResults: boolean;
}
const initialState: SearchState = {
  results: null,
  error: null,
  searchTerm: '',
  isLoading: false,
  noResults: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchSuccess(state, action: PayloadAction<ISearchResponse[]>) {
      state.results = action.payload.length ? action.payload : null;
      state.error = null;
      state.isLoading = false;
      state.noResults = !action.payload.length;
    },
    searchError(state, action: PayloadAction<string>) {
      state.results = null;
      state.isLoading = false;
      state.noResults = false;
      state.error = action.payload;
    },
    searchStart(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.results = null;
      state.isLoading = true;
      state.noResults = false;
      state.error = null;
    },
  },
});

export const {
  searchSuccess,
  searchError,
  searchStart,
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

export const fetchSearch = (token: string, search: string): AppThunk => async (dispatch) => {
  try {
    dispatch(searchStart(search));
    const query = qs.stringify({ search });
    const [patients, orders] = await Promise.all([
      getPatientsByQuery(token, query),
      getOrdersByQuery(token, query),
    ]);
    dispatch(searchSuccess([...patients, ...orders]));
  } catch (err) {
    dispatch(searchError(err.toString()));
  }
};
