import React from 'react';
import { get } from 'lodash';
import { Grid, Header, List } from 'semantic-ui-react';
import { Cell } from 'react-table';
import { format } from 'date-fns';
import {
  IAuditHistory, IMedication, IOrder, IProduct,
} from '../../interfaces';
import { CustomTable } from '../custom-table';
import { extractRowObject } from '../../shared/utils/table-utils';
import {
  findMedicationForProduct,
  getChangeEventsForIndex,
  getProductSubstitutionEvents,
} from '../../pages/orders/order-utils';

export interface SubstituteHistoryViewProps {
  order: IOrder;
  products: Record<string, IProduct>;
  medications: Array<IMedication>;
}

interface ChangedItem {
  id: string;
  quantity?: number;
  medicationName?: string;
  medicationType?: string;
}

interface ProductSubstitution {
  date: string;
  originalItem: ChangedItem;
  currentItem: ChangedItem;
}

interface Substitution {
  itemIndex?: number;
  itemProductId?: string;
  events: Array<ProductSubstitution>;
}

const extractChangeItem = (
  cell: Cell<ProductSubstitution>,
): ProductSubstitution => extractRowObject<ProductSubstitution>(cell);

const addMedicationDetails = (
  productId: string,
  products: Record<string, IProduct>,
  medications: Array<IMedication>,
  changedItem: ChangedItem,
) => {
  const originalProduct = products[productId];
  if (!originalProduct) {
    return;
  }
  const originalMedication = findMedicationForProduct(originalProduct, medications);
  if (!originalMedication) {
    return;
  }
  changedItem.medicationName = originalMedication.name;
  changedItem.medicationType = originalMedication.type;
};

const substitutionFromChangeEvent = (
  itemIndex: number,
  changeEvent: IAuditHistory,
  products: Record<string, IProduct>,
  medications: Array<IMedication>,
): ProductSubstitution => {
  const changedId = get(changeEvent.changed?.updated, [`items${itemIndex}Id`]);
  const changedQuantity = get(changeEvent.changed?.updated, [`items${itemIndex}Quantity`]);

  const originalItem: ChangedItem = {
    id: changedId.oldValue,
  };

  const currentItem: ChangedItem = {
    id: changedId.newValue,
  };

  if (changedQuantity) {
    originalItem.quantity = changedQuantity.oldValue;
    currentItem.quantity = changedQuantity.newValue;
  }

  addMedicationDetails(changedId.oldValue, products, medications, originalItem);
  addMedicationDetails(changedId.newValue, products, medications, currentItem);

  return { date: changeEvent.at, originalItem, currentItem };
};

const buildSubstitutedItems = (order: IOrder, products: Record<string, IProduct>, medications: Array<IMedication>): Array<Substitution> => {
  const substitutionEvents = getProductSubstitutionEvents(order);
  if (!substitutionEvents.length) {
    return [];
  }

  const changes: Array<Substitution> = [];

  order?.items?.forEach((item, itemIndex) => {
    const changesForItem = getChangeEventsForIndex(itemIndex, substitutionEvents);
    if (!changesForItem.length) {
      return;
    }
    const events = changesForItem.map((changeEvent) => {
      return {
        ...substitutionFromChangeEvent(itemIndex, changeEvent, products, medications),
        itemIndex,
        ...{ itemProductId: item.id },
      };
    });
    changes.push({
      itemIndex, events, itemProductId: item.id,
    });
  });
  return changes;
};

const ProductCell = (changedItem: ChangedItem, date?: string) => {
  return (
    <List>
      {date && <List.Item>
        <i>Date: </i>{format(new Date(date), 'PPpp')}
      </List.Item>}
      <List.Item data-testid="changed-item-id">
        <i>Product: </i>{changedItem.id}
      </List.Item>
      {changedItem.quantity && <List.Item data-testid="changed-item-quantity">
        <i>Quantity: </i>{changedItem.quantity}
      </List.Item>
      }
      {changedItem.medicationName && <List.Item data-testid="medication-name">
        <i>Medication: </i>{changedItem.medicationName}
      </List.Item>
      }
      {changedItem.medicationType && <List.Item data-testid="medication-type">
        <i>Type: </i>{changedItem.medicationType}
      </List.Item>
      }
    </List>
  );
};

const changesTable = (changeEvents: Array<ProductSubstitution>) => {
  const columns = [
    {
      Header: 'Before',
      accessor: 'itemIndex',
      Cell: (cell: Cell<ProductSubstitution>) => {
        const productSubstitution = extractChangeItem(cell);
        return ProductCell(productSubstitution.originalItem, productSubstitution.date);
      },
    },
    {
      Header: 'After',
      accessor: 'itemProductId',
      Cell: (cell: Cell<ProductSubstitution>) => {
        const productSubstitution = extractChangeItem(cell);
        return ProductCell(productSubstitution.currentItem);
      },
    },
  ];
  return (
    <CustomTable columns={columns} data={changeEvents} compact='very' />
  );
};

const buildChangesView = (substitutedItems: Array<Substitution>) => {
  return substitutedItems.map((substitutedItem, index) => {
    return (
      <Grid.Row key={`${substitutedItem.itemProductId}-${index}`}>
        <Grid.Column textAlign='center' verticalAlign='middle' width={4}>
          {substitutedItem.itemProductId}
        </Grid.Column>
        <Grid.Column width={12}>
          {changesTable(substitutedItem.events)}
        </Grid.Column>
      </Grid.Row>);
  });
};

export const SubstituteHistoryView = ({ order, products, medications }: SubstituteHistoryViewProps) => {
  if (!order || !order.auditHistory) {
    return null;
  }

  const substitutedItems = buildSubstitutedItems(order, products, medications);
  if (!substitutedItems.length) {
    return null;
  }

  return <>
    <Header size="medium">Products Substitution History</Header>
    <Grid>
      {buildChangesView(substitutedItems)}
    </Grid>
  </>;
};
