import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { assignPharmacist } from '../../reducers/ordersSlice';
import type { IPharmacist } from '../../interfaces';

export interface AssignPharmacistButtonProps {
  text: string,
  pharmacist: IPharmacist,
  orderId: string,
  token: string,
  disabled?: boolean,
}

export const AssignPharmacistButton = ({ token, orderId, pharmacist, text, disabled = false }: AssignPharmacistButtonProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      data-testid={`assign-pharmacist-${orderId}`}
      onClick={() => {
        dispatch(assignPharmacist(token, orderId, pharmacist));
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
