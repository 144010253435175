import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps, Header } from 'semantic-ui-react';
import React, { SyntheticEvent, useCallback } from 'react';
import { pickBy } from 'lodash/fp';
import type { IMedication, IProduct } from '../../interfaces';
import type { RootState } from '../../root-reducer';

const noMedication = 'No Medication';

const productToOptionSet = (product: IProduct, medications: Record<string, IMedication>) => {
  const productMedicationId = product.medication?.id;
  const medication = productMedicationId ? medications[productMedicationId] : null;
  const subheader = medication ? `Type: ${medication?.type} | Pack Size:${medication?.packSize}` : product.id;

  return {
    key: product.id,
    value: product.id,
    text: medication?.name,
    content: (<Header content={medication?.name} subheader={subheader} />),
  };
};

const filterIgnoredProducts = (
  product: IProduct,
  ignoredProductId?: string,
) => (!ignoredProductId || ignoredProductId !== product.id);

const getProducts = (products: IProduct[], medications: Record<string, IMedication>, oldProductId?: string) => {
  const oldProduct = products.find(({ id }) => id === oldProductId);
  if (!oldProduct) {
    return { products: [], mapped: [], oldMedicationName: null };
  }
  const oldMedicationId = oldProduct.medication?.id;
  const medication = oldMedicationId ? medications[oldMedicationId] : null;

  return {
    products,
    mapped: products
      .filter((p) => filterIgnoredProducts(p, oldProductId))
      .filter((p) => p.medication)
      .map((item) => productToOptionSet(item, medications)),
    oldMedicationName: medication?.name,
  };
};

export interface ProductPickerProps {
  oldProductId?: string;
  companyId: string;
  onProductSelect: (oldMedicationName: string,
    newName: string,
    product: IProduct | undefined) => void | Promise<void>;
}

export const ProductPicker = ({ companyId, onProductSelect, oldProductId }: ProductPickerProps) => {
  const { medications } = useSelector((state: RootState) => state.medicationsReducer);
  const { products: productsDictionary } = useSelector((state: RootState) => state.productsReducer);
  const validCompanyProducts = pickBy((product) => product.companyId?.includes(companyId), productsDictionary);
  const productOptions = getProducts(Object.values(validCompanyProducts), medications, oldProductId);
  const onProductChange = useCallback((event: SyntheticEvent<{}, Event>, data: DropdownProps) => {
    const value = data.value;
    onProductSelect(
      productOptions.oldMedicationName || noMedication,
      productOptions.mapped.find((item) => item.key === value)?.text || noMedication,
      productOptions.products.find(({ id }) => id === value),
    );
  }, [oldProductId]);

  return (
    <Dropdown
      style={{ minWidth: '350px' }}
      placeholder='Select Product'
      search
      selection
      options={productOptions.mapped}
      onChange={onProductChange}
    />
  );
};
