import React from 'react';
import { format } from 'date-fns-tz';
import { IGpContactReportEntry } from '../../interfaces';
import { SearchableTable } from '../searchable-table';
import {
  dispensedDateCol,
  dispensedMedicationCol,
  orderIdCol,
  patientEmailCol,
  patientIdCol,
  patientNameCol,
  reasonCol,
  requestedMedicationCol,
  statusCol,
} from '../../shared/data/report-columns';

interface IGpContactProps {
  gpContact: IGpContactReportEntry[];
}

export const GpContactList = (
  { gpContact }: IGpContactProps,
) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Order Date',
      accessor: 'createdAt',
      Cell: ({ value }: any) => format(new Date(value), 'do MMMM yyyy HH:mm'),
    },
    orderIdCol,
    patientIdCol,
    {
      Header: 'GP Contact Consent',
      accessor: 'informGpConsent',
    },
    patientNameCol,
    patientEmailCol,
    requestedMedicationCol,
    dispensedMedicationCol,
    dispensedDateCol,
    statusCol,
    reasonCol,
  ], []);

  return (
    <SearchableTable columns={columns} rows={gpContact} />
  );
};
