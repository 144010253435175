import { Field, Formik } from 'formik';
import { identity, pickBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Grid, Header, Icon, Segment, Table,
} from 'semantic-ui-react';
import * as yup from 'yup';
import { IShippingInformation } from '../../interfaces';
import { updatingShippingInformation } from '../../reducers/ordersSlice';
import { RootState } from '../../root-reducer';

const CARRIERS = {
  DPD: 'DPD',
  DX: 'DX',
  ROYAL_MAIL: 'Royal mail',
  DHL: 'DHL',
};

const shippingInformationSchema = yup.object().shape({
  carrier: yup.string().oneOf(Object.keys(CARRIERS)).required('Carrier name is required'),
  trackingId: yup.string().required('Tracking Id is required'),
  trackingUrl: yup.string().url().required('Tracking URL is requires'),
});

export interface IShippingInformationProps {
  shippingInformation: IShippingInformation;
  isEditable: boolean
  onSubmit: (values: Partial<IShippingInformation>) => void
}

interface ShippingInformationFormProps extends Omit<IShippingInformationProps, 'isEditable'> {
  onCancel?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
}

const ShippingInformationEditForm = ({ shippingInformation, onSubmit, onCancel }: ShippingInformationFormProps) => {
  return (
    <Formik
      initialValues={shippingInformation}
      validationSchema={shippingInformationSchema}
      onSubmit={(values) => onSubmit(pickBy(identity, values))}>
      {({ errors, touched, handleSubmit }) => (
        <Form widths="equal" onSubmit={handleSubmit}>
          <Form.Field required error={errors.carrier && touched.carrier}>
            <label htmlFor="carrier">Carrier</label>
            <Field name="carrier" id='carrier' as="select">
              <option value=""></option>
              {Object.entries(CARRIERS).map(([key, value]) =>
                <option key={key} value={key}>{value}</option>
              )}
            </Field>
          </Form.Field>
          <Form.Field required error={errors.trackingId && touched.trackingId}>
            <label htmlFor="trackingId">Tracking Id</label>
            <Field name="trackingId" id="trackingId" />
          </Form.Field>
          <Form.Field required error={errors.trackingUrl && touched.trackingUrl}>
            <label htmlFor="trackingUrl">Tracking URL</label>
            <Field name="trackingUrl" id="trackingUrl" />
          </Form.Field>
          <div >
            <Button type="submit" className="mr2 mt1">Update</Button>
            <Button type="button" basic onClick={onCancel}>Cancel</Button>
          </div>
        </Form>
      )
      }
    </Formik >
  );
};

export const ShippingInformation = ({
  shippingInformation = { trackingId: '', trackingUrl: '', carrier: '' }, isEditable, onSubmit,
}: IShippingInformationProps) => {
  const memoInformation = useMemo(() => shippingInformation, [shippingInformation]);
  const dispatch = useDispatch();

  const {
    isUpdatingShippingInformation,
  } = useSelector((state: RootState) => state.ordersReducer);

  return (
    <>
      <Header size="medium">
        Shipping Information
      </Header>
      <Segment>
        <Grid columns={2}>
          <Grid.Column>
            {
              isUpdatingShippingInformation
                ? <ShippingInformationEditForm
                  shippingInformation={shippingInformation}
                  onSubmit={onSubmit}
                  onCancel={() => dispatch(updatingShippingInformation(false))} />
                : <>
                  {memoInformation.carrier && <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell><span className="bold">Carrier</span></Table.Cell>
                        <Table.Cell>{CARRIERS[memoInformation.carrier as keyof typeof CARRIERS]}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><span className="bold">Tracking ID</span></Table.Cell>
                        <Table.Cell>{memoInformation.trackingId}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><span className="bold">Tracking URL</span></Table.Cell>
                        <Table.Cell><a target="blank" href={memoInformation.trackingUrl} >
                          {memoInformation.trackingUrl}</a></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>}
                </>
            }
          </Grid.Column>
          <Grid.Column>
            {(isEditable && !isUpdatingShippingInformation) && (
              <div>
                <Button
                  basic
                  floated='right'
                  className='mr3'
                  onClick={() => dispatch(updatingShippingInformation(true))}
                >
                  <Icon name="edit outline" />
                  Edit shipping information
                </Button>
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};
