import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SearchableTable } from '../searchable-table';
import type { IProduct } from '../../interfaces';

interface IProductsListProps {
  products: IProduct[];
  children?:
  | React.ReactChild
  | React.ReactChild[];
}

const TrueFalseCell = ({ value }: { value: boolean }) => {
  return value ? <Icon name="check" /> : <Icon name="cancel" />;
};

export const ProductsList = (
  { products, children }: IProductsListProps,
) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ value }: { value: string }) => {
        return <Link to={`/products/view/${value}`}>{value}</Link>;
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Currency',
      accessor: 'currency',
    },
    {
      Header: 'Variant',
      accessor: 'variant',
    },
    {
      Header: 'Date Code',
      accessor: 'dateCode',
    },
    {
      Header: 'Medication Id',
      accessor: 'medication.id',
      Cell: ({ value }: { value: string | null }) => {
        return value ? <Link to={`/medications/view/${value}`}>{value}</Link> : null;
      },
    },
    {
      Header: 'Units',
      accessor: 'units',
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: TrueFalseCell,
    },
    {
      Header: 'Available',
      accessor: 'available',
      Cell: TrueFalseCell,
    },
  ], []);

  return (
    <SearchableTable columns={columns} rows={products}> {children} </SearchableTable>
  );
};
