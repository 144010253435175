export const rejectionReasonOptions = [
  "Cancelled by patient",
  "Rejection due to emergency pill efficacy window",
  "ID check failure",
  "Cannot contact patient",
  "Raised BMI",
  "Migraine status not suitable for medication",
  "Duplicate order",
  "Patient not suitable due to medication history",
  "Images: Medication is not suitable for patient.",
  "Images: Patient's face is not visible",
  "Images: Patient's selfies do not match",
  "Images: Photos are not of a high enough quality",
  "Images: Photos have insufficient lighting",
  "Images: Photos do not meet the expected criteria",
  "Images: Patient's clothing does not allow judgement.",
  "Other",
].map((reason) => ({ key: reason, text: reason, value: reason }));
