import { Header } from 'semantic-ui-react';
import React from 'react';
import { Column } from 'react-table';
import { format } from 'date-fns';
import { CustomTable } from './custom-table';
import type { IReviewer } from '../interfaces';

export const ReviewerDetails = (props: IReviewerDetailsProps) => {
  const columns: Column<any>[] = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'reviewerDetails.name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Registration Id',
      accessor: 'reviewerDetails.registrationId',
    },
    {
      Header: 'Registration Body',
      accessor: 'reviewerDetails.registrationBody',
    },
    {
      Header: 'Reviewed at',
      accessor: ({ reviewerDetails }: IReviewerDetailsProps) => {
        return (reviewerDetails.reviewedAt ? format(new Date(reviewerDetails.reviewedAt), 'PPpp') : '');
      },
    },
  ], []);

  const memoReviewer = React.useMemo(() => props, [props]);

  return (
    <>
      <Header size={'medium'}>Reviewer Details</Header>
      <CustomTable columns={columns} data={[memoReviewer]} />
    </>
  );
};

interface IReviewerDetailsProps {
  email: string,
  reviewerDetails: IReviewer
}
