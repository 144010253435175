import { useCallback, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import { useSwr } from './swr';
import { useError } from './error';
import { mutateHandler } from '../shared/utils/request-utils';

export type EntityRequestStatus = 'idle' | 'success' | 'pending' | 'error';

export const entityRequestStatus = {
  IDLE: 'idle',
  SUCCESS: 'success',
  PENDING: 'pending',
  ERROR: 'error',
};

interface IUseUpdateEntity<T> {
  url: string;
  entity: string;
  data: T | undefined;
  mutate: Function;
  request?: Function;
  relationships?: Record<string, any>;
  ignoreError?: boolean;
}

export const getAxiosError = (error: any): any => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response.data || error.response;
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request;
  }
  // Something happened in setting up the request that triggered an Error
  return error.message || error;
};

export const useGetEntity = <T>(url: string) => {
  const { data, error, mutate } = useSwr<T>(url);
  return {
    data, error, mutate,
  };
};

export const useUpdateEntity = <T>({
  url, entity, data, mutate, request = axios.put, relationships, ignoreError = false,
}: IUseUpdateEntity<T>) => {
  const [status, setStatus] = useState(entityRequestStatus.IDLE);
  const [value, setValue] = useState(null);
  const throwError = useError();
  const { keycloak } = useKeycloak();
  const mutator = mutateHandler(mutate, data);

  const execute = useCallback(async (props?: Record<string, any>, callback?: Function) => {
    setStatus(entityRequestStatus.PENDING);
    setValue(null);
    try {
      const response: any = await request(url, {
        data: {
          type: entity,
          attributes: { ...props },
          relationships,
        },
      }, {
        headers: {
          Authorization: `Bearer ${keycloak?.token}`,
        },
      });
      setValue(response);
      setStatus(entityRequestStatus.SUCCESS);
      mutator(props);
      if (callback) {
        callback(entityRequestStatus.SUCCESS, response);
      }
    } catch (err) {
      const error = getAxiosError(err);
      setStatus(entityRequestStatus.ERROR);
      if (!ignoreError) {
        throwError(error);
      }
      if (callback) {
        callback(entityRequestStatus.ERROR, error);
      }
    }
  }, [url, relationships]);

  return {
    execute, status, value,
  };
};

export interface IDeleteEntity {
  url: string;
}

export const useDeleteEntity = ({
  url,
}: IDeleteEntity) => {
  const [status, setStatus] = useState(entityRequestStatus.IDLE);
  const throwError = useError();
  const { keycloak } = useKeycloak();

  const execute = useCallback(async (callback?: Function) => {
    setStatus(entityRequestStatus.PENDING);
    try {
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${keycloak?.token}`,
        },
      });
      setStatus(entityRequestStatus.SUCCESS);
      if (callback) {
        callback();
      }
    } catch (err) {
      setStatus(entityRequestStatus.ERROR);
      throwError(err);
    }
  }, [url]);

  return {
    execute, status,
  };
};
