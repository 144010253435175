import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { useKeycloak } from '@react-keycloak/web';
import { get, getOr } from 'lodash/fp';
import { NavLink } from 'react-router-dom';
import { ConditionalRenderer } from './components/conditional-renderer';

export const AppMenu = () => {
  const { keycloak } = useKeycloak();

  if (!get('authenticated', keycloak)) {
    return null;
  }

  const name = getOr('User', 'idTokenParsed.name', keycloak);
  const email = get('idTokenParsed.email', keycloak);
  return (
    <Menu>
      <Menu.Item>
        <img className="mr2"
          src="https://truepill.com/static/1ef329d01e023cd09a05b8c301473ad2/d774d/truepill-logo.png" /> Truepill
        Pharmacy System
      </Menu.Item>
      <ConditionalRenderer role={'order.GET'}>
        <Menu.Item as={NavLink} name='orders' to='/orders'>
          <Icon name='boxes' />
          Orders
        </Menu.Item>
      </ConditionalRenderer>
      <ConditionalRenderer role={'report.SEARCH'}>
        <Menu.Item as={NavLink} name='search' to='/search'>
          <Icon name='search' />
          Search
        </Menu.Item>
      </ConditionalRenderer>
      <ConditionalRenderer role={'medication.GET'}>
        <Menu.Item as={NavLink} name='products' to='/products'>
          <Icon name='pills' />
          Products
        </Menu.Item>
      </ConditionalRenderer>
      <ConditionalRenderer role={'medication.GET'}>
        <Menu.Item as={NavLink} name='medications' to='/medications'>
          <Icon name='syringe' />
          Medications
        </Menu.Item>
      </ConditionalRenderer>
      <ConditionalRenderer role={'report.GET'}>
        <Menu.Item as={NavLink} name='reports' to='/reports'>
          <Icon name='newspaper' />
          Reports
        </Menu.Item>
      </ConditionalRenderer>
      <Menu.Menu position="right">
        <Menu.Item>
          Signed in as {name} ({email})
        </Menu.Item>
        <Menu.Item onClick={() => keycloak && keycloak.logout()}>
          <Icon name="sign-out" /> Logout
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};
