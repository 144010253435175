import React from 'react';
import { reverse } from 'lodash/fp';
import { Placeholder, Segment, List } from 'semantic-ui-react';
import { formatDistanceToNowStrict } from 'date-fns';
import { isEmpty } from 'lodash';
import type { IPatientNote } from '../interfaces';
import { useSwr } from '../hooks/swr';

const FriendlyDate = ({ value }: { value: string }) => (value
  ? <div title={value}>{formatDistanceToNowStrict(new Date(value))} ago</div> : null);

export const PatientNotes = ({ patientId }: IPatientNotesProps) => {
  const { data: patientNotes, error } = useSwr<IPatientNote[]>(`/api/patients/${patientId}/notes`);

  const data = React.useMemo(() => patientNotes, [patientNotes]);

  if (error) {
    return (<Segment>
      Error fetching patient notes:
      {error.message}
    </Segment>);
  }

  if (!patientNotes) {
    return (
      <Segment>
        <Placeholder fluid>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
        </Placeholder>
      </Segment>
    );
  }
  const items = reverse(patientNotes)
    .map((note) => (
      <List.Item key={note.id}>
        <List.Icon name='circle' size='large' verticalAlign='middle' />
        <List.Content>
          <List.Header><a href={`mailto:${note.authorEmail}`}>{note.authorName}</a></List.Header>
          <List.Description>
            {note.content}
            <br />
            <small> <FriendlyDate value={note.createdAt} /> </small>
          </List.Description>
        </List.Content>
      </List.Item>
    ));

  return (
    <>
      {isEmpty(data)
        ? <p className="bold">No medical notes recorded.</p>
        : <List divided relaxed>
          {items}
        </List>
      }
    </>
  );
};

interface IPatientNotesProps {
  patientId: string;
}
