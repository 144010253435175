import { useKeycloak } from '@react-keycloak/web';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import {
  Container, Segment, Header, Divider, Input, Button, Table,
} from 'semantic-ui-react';
import { RootState } from '../../root-reducer';
import { fetchSearch } from '../../reducers/searchSlice';
import { ISearchResponse } from '../../interfaces';

const IdLink = ({ id }: { id: string }) => {
  const url = id.includes('ord_') ? '/orders/view/' : '/patients/view/';

  return <Link to={`${url}${id}`}>{id}</Link>;
};

const SearchTable = ({ searchData }: { searchData: ISearchResponse[] }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({ value }: { value: string }) => <IdLink id={value} />,
      },
      {
        Header: 'Upstream ID',
        accessor: 'upstreamId',
      },
      {
        Header: 'First name',
        accessor: 'firstName',
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    [],
  );

  const memoData = React.useMemo(() => searchData, [searchData]);
  const tableInstance = useTable<any>(
    {
      columns,
      data: memoData,
    },
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Table {...getTableProps()}>
      <Table.Header>
        {// Loop over the header rows
          headerGroups.map((headerGroup, i) => (
            // Apply the header row props
            <Table.Row {...headerGroup.getHeaderGroupProps()} key={i}>
              {
                headerGroup.headers.map((column, index) => (
                  <Table.HeaderCell {...column.getHeaderProps()} key={index}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))
              }
            </Table.Row>
          ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()} key={i}>
                {
                  row.cells.map((cell, index) => {
                    return (
                      <Table.Cell {...cell.getCellProps()} key={index}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};

export const SearchPage = () => {
  const { keycloak } = useKeycloak();
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const {
    results, searchTerm, isLoading, noResults, error,
  } = useSelector((state: RootState) => state.searchReducer);

  const onSearch = () => {
    dispatch(fetchSearch(keycloak?.token, search));
  };

  return (
    <Container>
      <Segment>
        <Header as='h1'>Search</Header>
        <Divider />
        <Input
          type='text'
          placeholder='Search...'
          action
          onChange={(_event, data) => {
            setSearch(data.value);
          }}
        >
          <input />
          <Button
            type='submit'
            loading={isLoading}
            disabled={!(search.length > 2)}
            onClick={onSearch}>
            Search
          </Button>
        </Input>
      </Segment>
      {
        noResults && (
          <Segment>
            <Header as='h4'>No results found for: {searchTerm}</Header>
          </Segment>
        )
      }
      {
        results && (
          <Segment>
            <SearchTable searchData={results} />
          </Segment>
        )
      }
      {
        error && (
          <Segment>
            Error fetching search results:  {error}
          </Segment>
        )
      }
    </Container>
  );
};
