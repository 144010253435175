import 'regenerator-runtime/runtime';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter from './app-router';
import { store } from './store';

// eslint-disable-next-line no-undef
ReactDOM.render(<Provider store={store} ><AppRouter /></Provider>, document.getElementById('root'));
