interface IIdentifiable {
  id: string
}

export function toDictionary<T extends IIdentifiable>(prev: Record<string, T>, item: T): Record<string, T> {
  return {
    ...prev,
    [item.id]: item,
  };
}
