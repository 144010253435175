import React from 'react';
import { dropRight } from 'lodash';
import {
  Button, Header, Portal, Segment,
} from 'semantic-ui-react';

export type ReactDispatchAction = React.Dispatch<React.SetStateAction<string>>;
export type ReactChangeInputEvent = React.ChangeEvent<HTMLInputElement>;
export type ReactChangeTextAreaEvent = React.ChangeEvent<HTMLTextAreaElement>;

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  errorMessage: string;
  open: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { errorMessage: '', open: false };

  static getDerivedStateFromError(error: any) {
    return { errorMessage: error.message, open: true };
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <>
          {this.props.children}
          <Portal
            onClose={() => this.setState({ open: false })}
            open={this.state.open}
          >
            <Segment
              style={{
                maxWidth: '30rem',
                minWidth: '15rem',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'fixed',
                zIndex: 1000,
              }}
            >
              <Header>Error</Header>
              <p>{this.state.errorMessage}</p>
              <Button
                content='Close'
                negative
                onClick={() => this.setState({ open: false })}
              />
            </Segment>
          </Portal>
        </>
      );
    }

    return this.props.children;
  }
}

export const branch = (...components: Function[]) => {
  return (props?: Record<string, any>) => {
    const LastComponent = components[components.length - 1];
    const conditionals = dropRight(components, 1);
    for (const ConditionalComponent of conditionals) {
      const Render = ConditionalComponent(props);
      if (typeof Render === 'function') {
        return () => <Render />;
      }
    }
    return () => <LastComponent {...props} />;
  };
};

export const onInputChangeHandler = (handler: ReactDispatchAction) =>
  (e: ReactChangeInputEvent) => handler(e.target.value);

export const onTextAreaChangeHandler = (handler: ReactDispatchAction) =>
  (e: ReactChangeTextAreaEvent) => handler(e.target.value);
