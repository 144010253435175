import React from 'react';

import { Form } from 'semantic-ui-react';

export interface WrappedCheckboxProps {
  name: string;
  // This type was imposed by the Form.Checkbox
  value: string | number | undefined;
}

export const WrappedCheckbox = (props: WrappedCheckboxProps) =>
  (<Form.Checkbox {...props} id={props.name} checked={!!props.value} name={props.name} />);
