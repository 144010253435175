import React from 'react';
import { ISalesReportEntry } from '../../interfaces';
import { SearchableTable } from '../searchable-table';
import {
  companyIdCol,
  createdAtCol,
  dispensedDateCol,
  dispensedMedicationCol,
  dispensedMedicationTypeCol,
  orderIdCol,
  patientAgeCol,
  patientDobCol,
  patientIdCol,
  patientNameCol,
  pharmacistCol,
  prescriberCol,
  prescriptionTypeCol,
  reasonCol,
  repeatCol,
  requestedMedicationCol,
  resolutionDateCol,
  statusCol,
} from '../../shared/data/report-columns';

interface ISalesListProps {
  sales: ISalesReportEntry[];
}

export const SalesList = (
  { sales }: ISalesListProps,
) => {
  const columns = React.useMemo(() => [
    orderIdCol,
    patientIdCol,
    patientNameCol,
    patientAgeCol,
    patientDobCol,
    createdAtCol,
    prescriberCol,
    pharmacistCol,
    companyIdCol,
    requestedMedicationCol,
    dispensedMedicationCol,
    dispensedMedicationTypeCol,
    dispensedDateCol,
    resolutionDateCol,
    statusCol,
    reasonCol,
    prescriptionTypeCol,
    repeatCol,
  ], []);

  return (
    <SearchableTable columns={columns} rows={sales} />
  );
};
