import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { identity, pickBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import {
  Header, Button, Grid, Form, Icon, Segment,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { IShippingAddress } from '../../interfaces';
import { RootState } from '../../root-reducer';
import { updatingShippingAddress } from '../../reducers/ordersSlice';

const shippingSchema = yup.object().shape({
  name: yup.string().nullable(),
  line1: yup.string().required('Address line 1 is required'),
  line2: yup.string().nullable(),
  city: yup.string().nullable(),
  region: yup.string().nullable(),
  postcode: yup.string().required('Postcode is required'),
});

export const ShippingAddress = ({
  shippingAddress, isEditable, onSubmit,
}: IShippingAddressProps) => {
  const memoAddress = useMemo(() => shippingAddress, [shippingAddress]);
  const dispatch = useDispatch();

  const {
    isUpdatingShippingAddress,
  } = useSelector((state: RootState) => state.ordersReducer);

  return (
    <>
      <Header size="medium">
        Shipping Address
      </Header>
      <Segment>
        <Grid columns={2}>
          <Grid.Column>
            {
              isUpdatingShippingAddress
                ? <ShippingAddressEditForm
                  shippingAddress={shippingAddress}
                  onSubmit={onSubmit}
                  onCancel={() => dispatch(updatingShippingAddress(false))} />
                : <address>
                  <span className="block">{memoAddress.name}</span>
                  <span className="block">{memoAddress.line1}</span>
                  <span className="block">{memoAddress.line2}</span>
                  <span className="block">{memoAddress.city}</span>
                  <span className="block">{memoAddress.region}</span>
                  <span className="block">{memoAddress.postcode}</span>
                </address>
            }
          </Grid.Column>
          <Grid.Column>
            {(isEditable && !isUpdatingShippingAddress) && (
              <div>
                <Button
                  basic
                  floated='right'
                  className='mr3'
                  onClick={() => dispatch(updatingShippingAddress(true))}
                >
                  <Icon name="edit outline" />
                  Edit
                </Button>
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};

export interface IShippingAddressProps {
  shippingAddress: IShippingAddress;
  isEditable: boolean
  onSubmit: (values: Partial<IShippingAddress>) => void
}

interface ShippingAddressFormProps extends Omit<IShippingAddressProps, 'isEditable'> {
  onCancel?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
}

const ShippingAddressEditForm = ({ shippingAddress, onSubmit, onCancel }: ShippingAddressFormProps) => {
  return (
    <Formik
      initialValues={shippingAddress}
      validationSchema={shippingSchema}
      onSubmit={(values) => onSubmit(pickBy(identity, values))}>
      {({ errors, touched, handleSubmit }) => (
        <Form widths="equal" onSubmit={handleSubmit}>
          <Form.Field>
            <label htmlFor="name">Name</label>
            <Field name="name" id='name' />
          </Form.Field>
          <Form.Field
            required
            error={errors.line1 && touched.line1 ? errors.line1 : undefined}
            name="line1"
            id="line1"
            label={{ children: 'Address Line 1', htmlFor: 'line1' }}
            control={Field} />
          <Form.Field>
            <label htmlFor="line2">Address Line 2</label>
            <Field name="line2" id="line2" />
          </Form.Field>
          <Form.Group >
            <Form.Field>
              <label htmlFor='city'>City</label>
              <Field name="city" id='city' />
            </Form.Field>
            <Form.Field>
              <label htmlFor="region">Region</label>
              <Field name="region" id="region" />
            </Form.Field>
            <Form.Field
              required
              error={errors.postcode && touched.postcode ? errors.postcode : undefined}
              name="postcode"
              id="postcode"
              label={{ children: 'Postcode', htmlFor: 'postcode' }}
              control={Field} />
          </Form.Group>
          <div >
            <Button type="submit" className="mr2 mt1">Update</Button>
            <Button type="button" basic onClick={onCancel}>Cancel</Button>
          </div>
        </Form>
      )
      }
    </Formik >
  );
};
