import { random } from 'lodash';
import React, { CSSProperties } from 'react';
import { Column, useTable } from 'react-table';
import { Table } from 'semantic-ui-react';

export const CustomTable = ({ columns, data, compact = false }: ICustomTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const additionalHeaderStyle: CSSProperties = {};
  if (compact === 'very') {
    additionalHeaderStyle.paddingTop = '5px';
    additionalHeaderStyle.paddingBottom = '5px';
  }

  return (
    <>
      <Table {...getTableProps()} compact={compact}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()} key={headerGroup.id || `row-${random()}`} >
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps()}
                  key={column.id}
                  style={additionalHeaderStyle}> {column.render('Header')} </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell, key) => {
                  return <Table.Cell {...cell.getCellProps()} key={key}>{cell.render('Cell')}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

interface ICustomTableProps {
  columns: Column<object>[];
  data: Array<object>;
  compact?: boolean | 'very';
}
