import { PrescriptionUpdate } from '../interfaces';
import { post } from './base';

export interface PrescriptionSigningResponse {
  url: string;
}

export const createPrescription = async (
  token: string, 
  orderId: string, 
  editableProducts: Array<PrescriptionUpdate>
): Promise<PrescriptionSigningResponse> => {
  const url = '/api/prescriptions/create';
  return post(url, token, 'prescription', { orderId, products: editableProducts });
};
