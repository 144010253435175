import React, { useEffect } from 'react';
import { Message } from 'semantic-ui-react';

interface DismissibleMessageProps {
  timeout?: number;
  negative?: boolean;
  message: string;
  handleError: Function;
}

export const DismissibleMessage = ({
  timeout = 20000,
  negative = false,
  message,
  handleError,
}: DismissibleMessageProps) => {
  useEffect(() => {
    setTimeout(() => {
      handleError();
    }, timeout);
  }, [handleError, timeout]);

  return (
    <Message negative={negative} hidden={!message}>
      <Message.Content>{message}</Message.Content>
    </Message>
  );
};
