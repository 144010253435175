import React, { ChangeEvent, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import {
  Button, Form, Input, InputOnChangeData, List, Modal, Segment, TextArea, TextAreaProps,
} from 'semantic-ui-react';
import { FormattedProducts, IProduct, IProductSubstituteParams } from '../../interfaces';
import { ProductPicker } from '../product/product-picker';
import { DismissibleMessage } from '../dismissible-message';
import type { RootState } from '../../root-reducer';
import {
  endSubstitution,
  startSubstitution,
  substituteProduct,
  substitutionErrorHandled,
} from '../../reducers/ordersSlice';

export interface SubstituteProductProps {
  orderId: string;
  companyId: string;
  product: FormattedProducts;
  orderStatus: string;
  disabled?: boolean;
}

const CurrentProduct = ({
  companyId, product,
}: SubstituteProductProps) => {
  return (
    <Segment>
      <List>
        <List.Item>
          <List.Icon name="pills" />
          <List.Content><b>Product Id: </b>{`${product.id}`}</List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="box" />
          <List.Content><b>Quantity: </b>{`${product.packSize ?? 'N/A'}`}</List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="industry" />
          <List.Content><b>Company: </b>{`${companyId}`}</List.Content>
        </List.Item>
        {product.instructions && <List.Item>
          <List.Icon name="pencil alternate" />
          <List.Content><b>Instructions: </b>{`${product.instructions}`}</List.Content>
        </List.Item>}
      </List>
    </Segment>
  );
};

export const SubstituteProductButton = (
  {
    orderId, companyId, product, orderStatus, disabled = false,
  }: SubstituteProductProps,
) => {
  const [productItem, setProductItem] = React.useState<IProductSubstituteParams>({
    id: '',
    item: '',
    quantity: 0,
    reason: '',
  });
  const resetState = useCallback(() => {
    setProductItem({
      id: '',
      item: '',
      quantity: 0,
      reason: '',
    });
  }, []);

  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    resetState();
    dispatch(startSubstitution(product.id));
  }, [resetState, dispatch, product.id]);

  const closeModal = useCallback(() => {
    resetState();
    dispatch(endSubstitution());
  }, [resetState, dispatch]);

  const handleError = useCallback(() => {
    setProductItem({ ...productItem });
    dispatch(substitutionErrorHandled());
  }, [dispatch, productItem]);

  const { keycloak } = useKeycloak();

  const [medicineNameTransition, setMedicineNameTransition] = React.useState({
    old: '',
    new: '',
  });

  const onPacksChange = useCallback((event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const value = data.value?.toString() || '0';
    const newValue = Number.parseInt(value, 10);
    setProductItem({ ...productItem, ...{ quantity: newValue } });
  }, [productItem]);

  const onReasonChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
    setProductItem({ ...productItem, ...{ reason: `${data.value}` } });
  }, [productItem])

  const onSubstituteSubmit = useCallback(() => {
    const { old: oldName, new: newName } = medicineNameTransition;
    const reason: string = `${productItem.reason} Substituted ${oldName} for ${newName}`;
    setProductItem({ ...productItem, reason });
    const payload: IProductSubstituteParams = { ...productItem, reason, id: product.id };
    dispatch(substituteProduct(keycloak?.token || '', orderId, payload));
  }, [productItem])

  const handleProductSelect = useCallback(
    (oldName: string, newName: string, prod: IProduct | undefined) => {
      if (prod) {
        setProductItem({ ...productItem, ...{ item: prod.id } });
        setMedicineNameTransition({ old: oldName, new: newName });
      }
    }
    , [productItem]);

  const { substitutionError, substitutedProductId } = useSelector(
    (state: RootState) => state.ordersReducer,
  );

  if (orderStatus !== 'PRESCRIBING' && orderStatus !== 'REVIEWING') {
    return null;
  }

  return (
    <Fragment>
      <Modal
        onClose={closeModal}
        onOpen={openModal}
        open={substitutedProductId === product.id}
        trigger={
          <Button
            icon='pencil'
            color='red'
            size='tiny'
            className="ml8"
            basic
            content={'Replace'}
            disabled={disabled}
          />
        }
      >
        <Modal.Header>Substitute product</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <CurrentProduct orderId={orderId} companyId={companyId} product={product}
              orderStatus={orderStatus} />

            <Segment>
              <Form>
                <Form.Group>
                  <Form.Field required>
                    <label>Product</label>
                    <ProductPicker oldProductId={product.id}
                      companyId={companyId} onProductSelect={handleProductSelect} />
                  </Form.Field>

                  <Form.Field required>
                    <label>Packs</label>
                    <Input type='number' onChange={onPacksChange} />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field required>
                    <label>Reason</label>
                    <TextArea style={{ width: '550px' }} onChange={onReasonChange} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
            {substitutionError && <DismissibleMessage negative message={substitutionError}
              handleError={handleError} timeout={5000} />}

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='red'
            size='mini'
            content='Cancel'
            icon='cancel'
            onClick={closeModal} />
          <Button
            size='mini'
            content="Substitute"
            labelPosition='right'
            icon='checkmark'
            disabled={!productItem.item || productItem.quantity <= 0 || !productItem.reason}
            positive
            onClick={onSubstituteSubmit}
          />
        </Modal.Actions>
      </Modal>

    </Fragment>
  );
};
