import { useKeycloak } from '@react-keycloak/web';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Container,
  Icon,
  Label,
  List,
  Segment,
} from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { isEmpty } from 'lodash';
import { get } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { ConditionalRenderer } from '../../components/conditional-renderer';
import { GenericList } from '../../components/generic-view';
import { Header } from '../../components/header';
import type { IEditPageProps, IProduct, MetaData } from '../../interfaces';
import { getSingleMedication } from '../../reducers/medicationsSlice';
import { fetchSingleProduct } from '../../reducers/productsSlice';
import { RootState } from '../../root-reducer';

const medicationLink = (product: IProduct | undefined) => {
  if (!product?.medication?.id) {
    return <> No Medication </>;
  }
  return (
    <>
      {' '}
      Medication:
      <Link to={`/medications/view/${product.medication.id}`}>
        {product?.medication.name} ({product.medication?.id})
      </Link>
    </>
  );
};

const companiesList = (product: IProduct) => {
  if (!product || !product.companyId) {
    return (
      <List.List>
        <List.Item>
          <Label tag size='tiny' color='teal'>
            Not associated yet
          </Label>
        </List.Item>
      </List.List>
    );
  }
  const companies = product.companyId.map((value, index) => {
    return <List.Item key={index}>{value}</List.Item>;
  });
  return <List.List as='ul'>{companies}</List.List>;
};

const getCompanyListItem = (product: IProduct) => (
  <List.Item>
    <List.Content>
      <List.Icon name='bookmark outline' /> Available for:{' '}
      {companiesList(product)}
    </List.Content>
  </List.Item>
);

const getPriceListItem = (product: IProduct) => (
  <List.Item>
    <List.Content>
      <List.Icon name='tag' />
      {`${product.currency} ${product.price}`}
    </List.Content>
  </List.Item>
);

const productsFormMetaData: MetaData[] = [
  { fieldName: 'currency', icon: 'tag' },
  {
    fieldName: 'price',
    icon: 'tag',
    shouldRenderOnStatic: true,
    specialRenderer: getPriceListItem,
  },
  { fieldName: 'units', icon: 'box', shouldRenderOnStatic: true },
  {
    fieldName: 'dateCode',
    icon: 'calendar outline',
    shouldRenderOnStatic: true,
  },
  {
    fieldName: 'variant',
    icon: 'bookmark outline',
    shouldRenderOnStatic: true,
  },
  {
    fieldName: 'companyId',
    icon: 'bookmark outline',
    shouldRenderOnStatic: true,
    specialRenderer: getCompanyListItem,
  },
  {
    fieldName: 'medication',
    icon: 'bookmark outline',
    shouldRenderOnStatic: true,
    specialRenderer: medicationLink,
  },
];

export const ProductPage = ({ pageContext = 'view' }: IEditPageProps) => {
  // @ts-ignore
  const { id } = useParams();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token as string;
  const dispatch = useDispatch();
  const isEditMode = pageContext !== 'view';
  const productState = useSelector((state: RootState) => state.productsReducer);
  const product = productState.products[id];
  const medicationId = get(['medication', 'id'], product);
  const medicationState = useSelector(
    (state: RootState) => state.medicationsReducer,
  );

  const medicationLoading = medicationState.isLoading;
  const productLoading = productState.isLoading;
  const isLoading = productLoading || medicationLoading;

  const { createProductEnabled } = useFlags();

  const getProduct = useCallback(async () => {
    await dispatch(fetchSingleProduct(token, id));
  }, [token, id, dispatch]);

  const getMedication = useCallback(async () => {
    if (medicationId) {
      await dispatch(getSingleMedication(token, medicationId));
    }
  }, [token, medicationId, dispatch]);

  const medication = medicationId && medicationState.medications[medicationId];

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    getMedication();
  }, [getMedication]);

  if (isEmpty(product) || isEmpty(medication)) return <div> Empty </div>;

  return (
    <Container>
      <Breadcrumb
        divider='/'
        sections={[
          { key: 'home', content: <Icon name='home' /> },
          { key: 'products', content: <a href='/products'>Products</a> },
          { key: 'info', content: id, active: true },
        ]}
      />
      <Header icon='pills'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>{product?.id}</div>
          <div>
            <span>
              Active:{' '}
              {product?.active ? <Icon name='check' /> : <Icon name='cancel' />}
            </span>
            <span>
              Available:{' '}
              {product?.available ? (
                <Icon name='check' />
              ) : (
                <Icon name='cancel' />
              )}
            </span>
          </div>
        </div>
      </Header>
      <Segment>
        <ConditionalRenderer condition={product && !isLoading}>
          <GenericList metaData={productsFormMetaData} dto={product} />
        </ConditionalRenderer>
      </Segment>
      <ConditionalRenderer
        role='medication.EDIT'
      >
        <ConditionalRenderer condition={isEditMode}>
          <Button
            icon='save outline'
            content='Save'
            color='green'
            size='mini'
            disabled={isLoading}
            onClick={() => {
              // TODO
            }}
          />
        </ConditionalRenderer>
        {createProductEnabled && (
          <Button
            icon='pencil'
            content={'Edit Product'}
            color={'blue'}
            size='mini'
            onClick={() => {}}
          />
        )}
      </ConditionalRenderer>
    </Container>
  );
};
