import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Icon } from 'semantic-ui-react';
import { CustomTable } from './custom-table';
import { FormattedProducts, IOrder } from '../interfaces';
import { downloadPDFFile } from '../api-clients/base';
import { ConditionalRenderer } from './conditional-renderer';
import { voidOrderPrescription } from '../reducers/ordersSlice';
import { RootState } from '../root-reducer';
import { ModalConfirmationButton } from './modal-confirmation-button';

export const Medication = ({ products, keycloak, order }: IMedicationProps) => {
  const { isUpdatingOrder } = useSelector(
    (state: RootState) => state.ordersReducer,
  );
  const dispatch = useDispatch();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Medication Name',
        accessor: 'name',
      },
      {
        Header: 'Quantity',
        accessor: 'total',
      },
      {
        Header: 'Product Type',
        accessor: 'type',
      },
      {
        Header: 'Instructions',
        accessor: 'instructions',
      },
    ],
    [],
  );

  if (!products.length) {
    return null;
  }

  return (
    <>
      <Header size="medium">Requested Medication</Header>
      <CustomTable columns={columns} data={products} />
      <ConditionalRenderer condition={!!order?.prescriptionId}>
        <Button
          data-testid="download-prescription"
          size="small"
          compact
          icon
          onClick={(e) => {
            downloadPDFFile(
              `/api/prescriptions/${order.prescriptionId}/pdf`,
              keycloak?.token,
              order.prescriptionId!,
            );
            e.preventDefault();
          }}
        >
          <Icon name="download" /> Prescription{' '}
        </Button>
      </ConditionalRenderer>
      <ConditionalRenderer
        condition={!!order?.prescriptionId && !order.resolution}
        role="order.VOID_PRESCRIPTION"
      >
        <ModalConfirmationButton
          loading={isUpdatingOrder}
          dataTestId="void-prescription"
          buttonText="Void Prescription"
          buttonIcon="delete"
          buttonColor="red"
          buttonSize="small"
          message="Are you sure you want to void the prescription?"
          onSubmit={() => {
            dispatch(voidOrderPrescription(keycloak?.token, order.id));
          }}
        />
      </ConditionalRenderer>
    </>
  );
};

export interface IMedicationProps {
  products: Partial<FormattedProducts>[];
  order: Pick<IOrder, 'id' | 'resolution' | 'prescriptionId' | 'auditHistory'>;
  keycloak: any;
}
