import React, { useState, useEffect } from 'react';
import { ButtonProps, Input } from 'semantic-ui-react';
import { ConfirmationButton } from './confirmation-button';

interface IConfirmationInputButton extends ButtonProps {
  content: string;
  disabled: boolean;
  title: string;
  message: string;
  placeholder: string;
  onSubmit: Function;
  onCancel?: Function;
  onChange?: Function;
}

export const ConfirmationInputButton = ({
  placeholder, onSubmit, onChange, ...props
}: IConfirmationInputButton) => {
  const [inputText, setInputText] = useState('');
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(inputText);
    }
  }, [inputText]);
  return (
    <ConfirmationButton
      { ...props }
      color={props.color || 'red'}
      icon={props.icon || false}
      ControllerComponent={
        <Input
          style={{ width: '100%' }}
          error={!inputText && isError}
          placeholder={placeholder}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          data-testid="confirmation-input"
        />
      }
      onError={() => {
        const isInputTextEmpty = !inputText;
        if (isInputTextEmpty) {
          setIsError(true);
        }
        return isInputTextEmpty;
      }}
      onSubmit={onSubmit}
      onCancel={() => { setIsError(false); setInputText(''); }}
    />
  );
};
