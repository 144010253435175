import { useKeycloak } from '@react-keycloak/web';
import { endOfDay, startOfDay } from 'date-fns/fp';
import Keycloak from 'keycloak-js';
import {
  compose,
  equals,
  flatten,
  getOr,
  lowerCase,
  map,
  pick,
  pipe,
  uniq,
  without,
} from 'lodash/fp';
import qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  Breadcrumb,
  Container,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';

import { ConditionalRenderer } from '../../components/conditional-renderer';
import { OrderList } from '../../components/order/order-list';
import { useDebounce } from '../../hooks/debounce';
import { EPageStep } from '../../interfaces';
import { fetchOrders, fetchPaginatedOrders } from '../../reducers/ordersSlice';
import { RootState } from '../../root-reducer';

import { FilterMenu } from './filter-menu';

enum groupNames {
  customer_support_agents = 'customer service',
  pharmacists = 'pharmacists',
  dispensers = 'dispensers',
  dispatchers = 'dispatchers',
  prescribers = 'prescribers',
  default = 'default',
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

const RESOLVED_STATUSES = ['CANCELLED', 'REJECTED', 'DISPATCHED'];
const INFLIGHT_STATUSES = [
  'AWAITING_APPROVAL',
  'ON_HOLD',
  'READY_FOR_DISPATCH',
  'DISPATCHING',
  'READY_FOR_DISPENSING',
  'DISPENSING',
  'READY_FOR_PRESCRIBER',
  'PRESCRIBING',
  'READY_FOR_REVIEW',
  'REVIEWING',
  'IN_TRIAGE',
];

const STATUSES_ALLOWED: EnumDictionary<groupNames, string[]> = {
  [groupNames.default]: [
    'READY_FOR_DISPATCH',
    'DISPATCHING',
    ...RESOLVED_STATUSES,
  ],
  [groupNames.dispensers]: [
    'READY_FOR_DISPENSING',
    'DISPENSING',
    'READY_FOR_DISPATCH',
    'DISPATCHING',
    ...RESOLVED_STATUSES,
  ],
  [groupNames.dispatchers]: [
    'READY_FOR_DISPATCH',
    'DISPATCHING',
    ...RESOLVED_STATUSES,
  ],
  [groupNames.prescribers]: [
    'ON_HOLD',
    'READY_FOR_PRESCRIBER',
    'PRESCRIBING',
    ...RESOLVED_STATUSES,
  ],
  [groupNames.pharmacists]: [
    'ON_HOLD',
    'READY_FOR_REVIEW',
    'REVIEWING',
    'IN_TRIAGE',
    ...RESOLVED_STATUSES,
  ],
  [groupNames.customer_support_agents]: [
    'AWAITING_APPROVAL',
    'ON_HOLD',
    'READY_FOR_REVIEW',
    'REVIEWING',
    'READY_FOR_DISPENSING',
    'DISPENSING',
    'READY_FOR_DISPATCH',
    'DISPATCHING',
    'READY_FOR_PRESCRIBER',
    'PRESCRIBING',
    'IN_TRIAGE',
    ...RESOLVED_STATUSES,
  ],
};

const getGroupStatuses = (
  keycloak?: Keycloak.KeycloakInstance,
): Array<string> => {
  const groupNamesLowercase = map(
    lowerCase,
    getOr(['default'], 'tokenParsed.groups', keycloak),
  );

  return compose(
    uniq,
    flatten,
    Object.values,
  )(pick(groupNamesLowercase, STATUSES_ALLOWED));
};

/**
 * Helper functions to parse and format query param dates
 */
const setStartDayString = pipe(startOfDay, (date) => date.toISOString());
const setEndDayString = pipe(endOfDay, (date) => date.toISOString());

/**
 * Query builder
 */
const buildSearchQueryString = (
  statuses: Array<String>,
  resolvedDateFrom: Date | null,
  resolvedDateTo: Date | null,
  page: number | null,
  limit: number | null,
): string =>
  qs.stringify({
    statuses,
    ...(resolvedDateFrom && {
      resolvedAtStartDate: setStartDayString(resolvedDateFrom),
    }),
    ...(resolvedDateTo && {
      resolvedAtEndDate: setEndDayString(resolvedDateTo),
    }),
    ...(page && { page }),
    ...(limit && { limit }),
  });

interface FilterState {
  searchFilter: string,
  resolvedDateFrom: Date | null,
  resolvedDateTo: Date | null,
  activeStatuses: string[],
  activePagination: {
    page: number,
    limit: number
  }
}

export const OrdersPage = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || '';

  const [showTable, isTableVisible] = useState(true);

  const allowedStatuses = getGroupStatuses(keycloak).sort();

  const { pagination, loadingError } = useSelector(
    (state: RootState) => state.ordersReducer,
  );

  const [searchParams, setSearchParams] = useState<FilterState>({
    searchFilter: '',
    resolvedDateFrom: null,
    resolvedDateTo: null,
    activeStatuses: without(RESOLVED_STATUSES, allowedStatuses),
    activePagination: { page: 1, limit: 10 },
  });

  const latestStatuses = useDebounce(searchParams.activeStatuses, 2000);

  const hasSelectedInflight = searchParams.activeStatuses.some(
    (status) => INFLIGHT_STATUSES.includes(status),
  );

  const hasSelectedResolved = searchParams.activeStatuses.some(
    (status) => RESOLVED_STATUSES.includes(status),
  );

  const queryString = buildSearchQueryString(
    latestStatuses,
    searchParams.resolvedDateFrom,
    searchParams.resolvedDateTo,
    searchParams.activePagination.page,
    searchParams.activePagination.limit,
  );

  const dispatch = useDispatch();

  const handleLoadData = useCallback(async () => {
    const hasStatusesSelected = hasSelectedResolved || hasSelectedInflight;

    if (hasStatusesSelected) {
      const fetcher = hasSelectedResolved && !hasSelectedInflight
        ? fetchPaginatedOrders
        : fetchOrders;

      dispatch(fetcher(token, queryString));
    }

    isTableVisible(hasStatusesSelected);
  }, [dispatch, queryString, token]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  const handlePageChange = useCallback(
    async (val: any) => {
      const currentPage = searchParams.activePagination.page;

      switch (val) {
      case EPageStep.FIRST:
        setSearchParams({
          ...searchParams,
          activePagination: { ...searchParams.activePagination, page: 1 },
        });
        break;
      case EPageStep.PREV:
        setSearchParams({
          ...searchParams,
          activePagination: {
            ...searchParams.activePagination,
            page: currentPage - 1,
          },
        });
        break;
      case EPageStep.NEXT:
        setSearchParams({
          ...searchParams,
          activePagination: {
            ...searchParams.activePagination,
            page: currentPage + 1,
          },
        });
        break;
      case EPageStep.LAST:
        setSearchParams({
          ...searchParams,
          activePagination: {
            ...searchParams.activePagination,
            page: pagination?.totalPages || 1,
          },
        });
        break;
      default:
        break;
      }
    },
    [searchParams, pagination],
  );

  const setPageLimit = (value: number) => {
    setSearchParams({
      ...searchParams,
      activePagination: { ...searchParams.activePagination, limit: value },
    });
  };

  if (loadingError) {
    return <Segment>Error fetching orders: {loadingError}</Segment>;
  }

  const tableSearchHandler = (inputFilterValue: string) => (debouncer: (str: string) => void) => () => debouncer(inputFilterValue);

  const statusOptions = allowedStatuses.map((status) => {
    const isResolved = RESOLVED_STATUSES.includes(status);

    return {
      status: status,
      disabled: !isResolved ? hasSelectedResolved : hasSelectedInflight,
    };
  });

  const filtersActive = allowedStatuses.filter((status: string) => {
    return latestStatuses.some(equals(status));
  });

  return (
    <Container>
      <Breadcrumb
        divider="/"
        sections={[
          { key: 'home', content: <Icon name="home" /> },
          { key: 'orders', content: <a href="/orders">Orders</a> },
        ]}
      />
      <Header size="medium">Order List</Header>
      <FilterMenu
        disableSearch={hasSelectedResolved}
        filterValue={{
          statuses: filtersActive,
          searchText: searchParams.searchFilter,
          startDate: searchParams.resolvedDateFrom,
          endDate: searchParams.resolvedDateTo,
        }}
        onFilterChange={(filterValues) => setSearchParams({
          ...searchParams,
          resolvedDateFrom: filterValues.startDate,
          resolvedDateTo: filterValues.endDate,
          searchFilter: filterValues.searchText,
          activeStatuses: filterValues.statuses,
        })}
        placeholderText={{
          startDate: hasSelectedResolved ? 'Resolved Start Date' : undefined,
          endDate: hasSelectedResolved ? 'Resolved End Date' : undefined,
        }}
        statusOptions={statusOptions}
      />
      <ConditionalRenderer condition={showTable}>
        <OrderList
          pagination={pagination}
          onPageChange={handlePageChange}
          onSearchFilter={tableSearchHandler(searchParams.searchFilter)}
          searchValue={searchParams.searchFilter}
          setPageLimit={setPageLimit}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={!showTable}>
        <Container>You have to select order status first.</Container>
      </ConditionalRenderer>
    </Container>
  );
};
