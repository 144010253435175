import { has } from 'lodash';
import { Cell } from 'react-table';
import {
  IAuditHistory, IMedication, IOrder, IProduct,
} from '../../interfaces';
import { extractRowObject } from '../../shared/utils/table-utils';

export const TWO_DAYS = 1000 * 60 * 60 * 48;

export const extractOrderItem = (cell: Cell<IOrder>): IOrder =>
  extractRowObject<IOrder>(cell);

export const findMedicationForProduct = (
  product: IProduct | undefined,
  medications: IMedication[] | undefined,
) => {
  const searchedId = product?.medication?.id || null;
  return typeof searchedId === 'string'
    ? medications?.find((med) => med.id === searchedId)
    : undefined;
};

export const getProductSubstitutionEvents = (order: IOrder) => {
  return (
    order?.auditHistory?.filter((value) => value.eventType === 'substitute')
    || []
  );
};

export const getChangeEventsForIndex = (
  index: number,
  substitutionEvents: Array<IAuditHistory>,
): Array<IAuditHistory> => {
  const updatedKey = `items${index}Id`;
  return substitutionEvents.filter(
    (value) => value.changed && has(value.changed.updated, [updatedKey]),
  );
};

export const isOverdueOrder = (order: Partial<IOrder>): boolean => {
  if (order?.resolution) {
    return false;
  }

  const now = new Date();
  return now.getTime() - new Date(order.createdAt!).getTime() > TWO_DAYS;
};

export const sortOverdueOrdersOnTop = (
  o1: Partial<IOrder>,
  o2: Partial<IOrder>,
) => {
  const o1IsOverdue = isOverdueOrder(o1);
  const o2IsOverdue = isOverdueOrder(o2);
  if ((o2IsOverdue && o1IsOverdue) || (!o1IsOverdue && !o2IsOverdue)) {
    return 0;
  }

  return o1IsOverdue ? -1 : 1;
};
