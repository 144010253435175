import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationInputButton } from '../confirmation-input-button';
import type { RootState } from '../../root-reducer';
import { cancelOrder } from '../../reducers/ordersSlice';

interface ICancelButtonProps {
  orderId: string,
  token: string,
  disabled?: boolean,
}

export const CancelButton = ({ orderId, token, disabled = false }: ICancelButtonProps) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');
  const { updateError: error } = useSelector((state: RootState) => state.ordersReducer);

  return (
    <ConfirmationInputButton
      color="red"
      icon="cancel"
      content="Cancel"
      disabled={!!error || !!reason || !!disabled}
      title="Reason for Cancellation"
      message="Are you sure you want to cancel this order?"
      placeholder="Please provide a reason..."
      onSubmit={() => dispatch(cancelOrder(token, orderId, reason))}
      onChange={(updatedReason: string) => setReason(updatedReason)}
    />
  );
};
