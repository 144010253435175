import type { IMedication, IMedicationDto } from '../interfaces';
import { get, post, put } from './base';

const baseUrl = '/api/medications';

export const getMedications = async (token: string): Promise<IMedication[]> => {
  const data = await get<IMedication[]>(baseUrl, token);
  return data;
};

export const getMedication = async (token: string, id: string): Promise<IMedication> => {
  const data = await get<IMedication>(`${baseUrl}/${id}`, token);
  return data;
};

export const storeMedication = async (token: string, medication: IMedicationDto, medicationId?: string): Promise<IMedication> => {
  const data = medicationId
    ? await put<IMedication>(`${baseUrl}/${medicationId}`, token, 'medication', { ...medication })
    : await post<IMedication>(baseUrl, token, 'medication', { ...medication });
  return data;
};
