import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IMedication, IMedicationDto } from '../interfaces';
import type { AppThunk } from '../store';
import { getMedications, storeMedication, getMedication } from '../api-clients/medications';
import { toDictionary } from '../shared/utils/transforms';
import { RootState } from '../root-reducer';

interface MedicationsState {
  medications: Record<string, IMedication>;
  error: string | null;
  isLoading: boolean;
  storedSuccessfully: boolean | null;
  createdId: string | null;
}
const initialState: MedicationsState = {
  medications: {},
  error: null,
  isLoading: false,
  storedSuccessfully: null,
  createdId: null,
};

const medicationsSlice = createSlice({
  name: 'medications',
  initialState,
  reducers: {
    getMedicationsSuccess(state, action: PayloadAction<IMedication[]>) {
      state.medications = action.payload.reduce(toDictionary, {});
      state.error = null;
      state.isLoading = false;
    },
    getMedicationsError(state, action: PayloadAction<string>) {
      state.medications = {};
      state.error = action.payload;
      state.isLoading = false;
    },
    getMedicationsStart(state) {
      state.isLoading = true;
    },
    getMedicationSuccess(state, { payload }: PayloadAction<IMedication>) {
      state.isLoading = false;
      state.medications[payload.id] = payload;
    },
    getMedicationStart(state) {
      state.isLoading = true;
    },
    getMedicationError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    successfulStoreHandled(state) {
      state.storedSuccessfully = null;
      state.createdId = null;
    },
    storeMedicationStart(state) {
      state.isLoading = true;
    },
    storeMedicationSuccess(state, action: PayloadAction<IMedication>) {
      const { payload } = action;
      state.isLoading = true;
      state.storedSuccessfully = true;
      state.medications[payload.id] = payload;
      state.createdId = payload.id;
    },
    storeMedicationError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.storedSuccessfully = false;
      state.createdId = null;
    },
    medicationsErrorHandled(state) {
      state.error = null;
    },
  },
});

export const {
  getMedicationsError,
  getMedicationsSuccess,
  getMedicationsStart,
  getMedicationError,
  getMedicationStart,
  getMedicationSuccess,
  successfulStoreHandled,
  storeMedicationStart,
  storeMedicationSuccess,
  storeMedicationError,
  medicationsErrorHandled,
} = medicationsSlice.actions;

export const medicationsReducer = medicationsSlice.reducer;
export const getMedicationsAsArray = (state: RootState) => Object.values(state.medicationsReducer.medications);

export const getSingleMedication = (token: string, id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getMedicationStart());
    const medication = await getMedication(token, id);
    dispatch(getMedicationSuccess(medication));
  } catch (err) {
    dispatch(getMedicationError(err.toString()));
  }
};

export const fetchMedications = (token: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getMedicationsStart());
    const medications = await getMedications(token);
    dispatch(getMedicationsSuccess(medications));
  } catch (err) {
    dispatch(getMedicationsError(err.toString()));
  }
};

export const saveMedication = (token: string, medication: IMedicationDto, medicationId?: string): AppThunk => async (dispatch) => {
  try {
    dispatch(successfulStoreHandled());
    dispatch(storeMedicationStart());
    const result = await storeMedication(token, medication, medicationId);
    dispatch(storeMedicationSuccess(result));
  } catch (err) {
    dispatch(storeMedicationError(err.toString()));
  }
};
