import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns-tz';
import {
  Breadcrumb, Button, Container, Icon, Label,
} from 'semantic-ui-react';
import { Header } from '../../components/header';
import {
  IReportEntry,
  IMigraineReportEntry,
  IOverFortyReportEntry,
  IReport,
  ReportType,
  ISalesReportEntry,
  IGpContactReportEntry,
  IRejectionsReportEntry,
  IHelpMeDecideEntry,
} from '../../interfaces';
import { fetchReport } from '../../reducers/reportsSlice';
import { RootState } from '../../root-reducer';
import { MigraineList } from '../../components/report/migraine-list';
import { downloadXslsFile } from '../../api-clients/base';
import { OverFortyList } from '../../components/report/over-forty-list';
import { SalesList } from '../../components/report/sales-list';
import { GpContactList } from '../../components/report/gp-contact-list';
import { HelpMeDecideList } from '../../components/report/help-me-decide-list';
import { RejectionsList } from '../../components/report/rejections-list';

interface IReportComponentProps {
  id: string;
  token: string;
  report: IReport<IReportEntry>;
}

const isMigraineReport = (report: IReport<IReportEntry>): report is IReport<IMigraineReportEntry> =>
  report.type === ReportType.Migraine;
const isOverFortyReport = (report: IReport<IReportEntry>): report is IReport<IOverFortyReportEntry> =>
  report.type === ReportType.OverForty;
const isSalesReport = (report: IReport<IReportEntry>): report is IReport<ISalesReportEntry> =>
  report.type === ReportType.Sales;
const isGpContactReport = (report: IReport<IReportEntry>): report is IReport<IGpContactReportEntry> =>
  report.type === ReportType.GpContact;
const isHelpMeDecideReport = (report: IReport<IReportEntry>): report is IReport<IHelpMeDecideEntry> =>
  report.type === ReportType.HelpMeDecide;
const isRejectionsReport = (report: IReport<IReportEntry>): report is IReport<IRejectionsReportEntry> =>
  report.type === ReportType.Rejections;

const getListComponent = (report: IReport<IReportEntry>) => {
  if (isMigraineReport(report)) return <MigraineList migraines={report.entries} />;
  if (isOverFortyReport(report)) return <OverFortyList overForties={report.entries} />;
  if (isSalesReport(report)) return <SalesList sales={report.entries} />;
  if (isGpContactReport(report)) return <GpContactList gpContact={report.entries} />;
  if (isHelpMeDecideReport(report)) return <HelpMeDecideList helpMeDecide={report.entries} />;
  if (isRejectionsReport(report)) return <RejectionsList rejections={report.entries} />;
  return <div>Report type is not supported</div>;
};

const ReportComponent = (props: IReportComponentProps) => {
  const { report } = props;
  const downloadUrl = `/api/reports/${props.id}?download=true`;

  return (
    <Container>
      <Breadcrumb divider='/' sections={[
        { key: 'home', content: (<Icon name="home" />) },
        { key: 'reports', content: (<a href="/reports">Reports</a>) },
        { key: 'info', content: props.id, active: true },
      ]} />
      <Header icon="newspaper">
        <div>
          <Button floated='right' size='small' href={downloadUrl}
            onClick={(e) => {
              downloadXslsFile(downloadUrl, props.token, `${report.type} Report - ${format(new Date(), 'do MMMM yyyy hh-mm-ss')}`);
              e.preventDefault();
            }
            }><Icon name="download" /> Download Report
          </Button>

          {report.type} report between {
            format(new Date(report.startDate), 'do MMMM yyyy')
          } and {
            format(new Date(report.endDate), 'do MMMM yyyy')
          }
        </div>
      </Header>
      {!report.entries.length && <Label color='red'>The report has no entries</Label>}
      { getListComponent(report)}
    </Container>
  );
};

export const ReportPage = () => {
  // @ts-ignore
  const { id } = useParams();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || '';
  const dispatch = useDispatch();
  const { report, isLoadingReport } = useSelector((state: RootState) => state.reportsReducer);

  useEffect(() => {
    async function loadReport() {
      await dispatch(fetchReport(token || '', id));
    }
    loadReport();
  }, [token, id, dispatch]);

  if (isLoadingReport || !report) {
    return <Label content="loading" />;
  }

  return (
    <ReportComponent
      token={token} id={id} report={report} />
  );
};
