import React, { useState, useEffect } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import { rejectionReasonOptions } from '../shared/utils/rejection-reason-options';
import { ConfirmationButton } from './confirmation-button';

interface IConfirmationDropdownButton {
  color: string;
  icon: string;
  content: string;
  disabled: boolean;
  title: string;
  message: string;
  placeholder: string;
  setIsOtherReason: Function;
  onSubmit: Function;
  onCancel?: Function;
  onChange: Function;
}

export const ConfirmationDropdownButton = ({
  placeholder, onSubmit, onChange, setIsOtherReason, ...props
}: IConfirmationDropdownButton) => {
  const [reason, setReason] = useState('');
  const [displayOtherReason, setDisplayOtherReason] = useState(false);
  const [isError, setIsError] = useState({ dropdown: false, input: false });

  const renderRejectionReasonsList = () => {
    return (
      <Dropdown
        placeholder='Select a reason'
        fluid
        selection
        options={rejectionReasonOptions}
        onChange={(e, data) => {
          setReason(data.value!.toString());
          if (data.value?.toString().includes('Other')) {
            setReason('');
            setIsOtherReason(true);
            setIsError({ dropdown: false, input: false });
            setDisplayOtherReason(true);
          } else {
            setIsOtherReason(false);
            setDisplayOtherReason(false);
          }
        }}
        style={{ marginBottom: '10px' }}
        error={!reason && isError.dropdown}
      />
    );
  };

  const renderOtherReasonInput = () => {
    if (!displayOtherReason) return null;

    return (
      <Input
        style={{ width: '100%' }}
        error={!reason && isError.input}
        placeholder={placeholder}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        data-testid="confirmation-input"
      />
    );
  };

  useEffect(() => {
    onChange(reason);
  }, [reason, onChange]);

  return (
    <ConfirmationButton
      { ...props }
      ControllerComponent={
        <>
          {renderRejectionReasonsList() }
          { renderOtherReasonInput()}
        </>
      }
      onError={() => {
        const isInputTextEmpty = !reason;
        if (isInputTextEmpty) {
          setIsError(displayOtherReason ? { dropdown: false, input: true } : { dropdown: true, input: false });
        }

        return isInputTextEmpty;
      }}
      onSubmit={onSubmit}
      onCancel={() => {
        setIsError({ dropdown: false, input: false });
        setReason('');
        setDisplayOtherReason(false);
      }}
    />
  );
};
