import React, { useState } from 'react';
import { Label } from 'semantic-ui-react';

export interface ITruncatedLabelProps {
  title: string;
  detail: string;
  truncateCount?: number;
  truncate?: boolean;
  // defined in https://react.semantic-ui.com/elements/label/
  color:
    | 'red'
    | 'orange'
    | 'yellow'
    | 'olive'
    | 'green'
    | 'teal'
    | 'blue'
    | 'violet'
    | 'purple'
    | 'pink'
    | 'brown'
    | 'grey'
    | 'black';
}

export const TruncatedLabel = ({
  title,
  detail,
  color,
  truncate = false,
  truncateCount = 4,
}: ITruncatedLabelProps) => {
  const [showExpansion, setViewExpansion] = useState(false);
  const [allowHoverEvents, setHoverEvents] = useState(true);

  let displayText = detail;
  if (truncate && displayText.length > truncateCount + 3) {
    displayText = `${detail.slice(0, truncateCount)}...`;
  }

  function toggleExpansion(condition: boolean) {
    if (!allowHoverEvents) return;
    setViewExpansion(condition);
  }

  const setHideExpansion = () => toggleExpansion(truncate && false);
  const setShowExpansion = () => toggleExpansion(truncate && true);
  const toggleHoverEvents = () => setHoverEvents((cur) => !cur);

  return (
    <span
      onMouseLeave={setHideExpansion}
      onMouseOver={setShowExpansion}
      onClick={toggleHoverEvents}
      style={{ cursor: 'pointer' }}
    >
      <Label color={color}>
        {title}
        <Label.Detail>{showExpansion ? detail : displayText}</Label.Detail>
      </Label>
    </span>
  );
};
