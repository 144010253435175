import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, reverse } from 'lodash/fp';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Icon, List, Placeholder, Popup, Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { useSwr } from '../../hooks/swr';
import { CustomTable } from '../custom-table';
import type { IMedication, IOrder, IProduct } from '../../interfaces';
import {
  extractOrderItem,
  findMedicationForProduct,
  getChangeEventsForIndex,
  getProductSubstitutionEvents,
} from '../../pages/orders/order-utils';

interface IRelatedOrdersProps {
  patientId?: string,
  currentOrderId?: String,
  medications: IMedication[],
  products: Record<string, IProduct>,
}

const substitutionIcon = () => {
  return (
    <Popup
      content='This product was substituted while processing'
      trigger={<Icon name='pencil' color='red' pop="true" />}
    />
  );
};

const OrderItemsCell = (order: IOrder, products: Record<string, IProduct>, medications: Array<IMedication>) => {
  if (!order.items) {
    return null;
  }
  return (
    <List>{order.items.map((item, i) => {
      const product = products[item.id];
      const medication = findMedicationForProduct(product, medications);
      const productChanges = getChangeEventsForIndex(i, getProductSubstitutionEvents(order));
      const productWasChanged = productChanges.length > 0;
      if (!product || !medication) {
        return (<List.Item key={i}>
          {productWasChanged && substitutionIcon()}
          {`${item.quantity} × ${item.id}`}
        </List.Item>);
      }
      if (!product.medication) {
        return (<List.Item key={i}>
          {productWasChanged && substitutionIcon()}
          {`${item.quantity} × ${product.units} ${product.id} `}
        </List.Item>);
      }
      return (<List.Item key={i}>
        {productWasChanged && substitutionIcon()}
        {`${item.quantity} × ${product.units} ${medication.form} of ${medication.name} ${!isEmpty(medication.dosageLevel) ? `at ${medication.dosageLevel}` : ''}`}
      </List.Item>);
    })}</List>
  );
};

export const RelatedOrders = ({
  patientId,
  currentOrderId,
  medications,
  products,
}: IRelatedOrdersProps) => {
  const { data: orderHistory, error } = useSwr<IOrder[]>(`/api/orders/?patientId=${patientId}`);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ value }: { value: string }) => <Link to={`/orders/view/${value}`}>{value}</Link>,
      },
      {
        Header: 'Created at',
        accessor: (order: IOrder) => utcToZonedTime(order!.createdAt, 'Europe/London'),
        Cell: ({ value }: { value: Date }) => format(value, 'do MMMM yyyy HH:mm'),
      },
      {
        Header: 'Items',
        accessor: 'items',
        Cell: (cell: Cell<IOrder>) => {
          const order = extractOrderItem(cell);
          return OrderItemsCell(order, products, medications);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [medications, products],
  );

  const data = React.useMemo(() => {
    return Array.isArray(orderHistory) ? reverse<IOrder[]>(orderHistory.filter(
      (order: IOrder) => order.id !== currentOrderId)) : [];
  },
  [currentOrderId, orderHistory]);

  if (error) {
    return (<Segment>
      Error fetching related orders:
      {error.message}
    </Segment>);
  }

  if (!orderHistory) {
    return (
      <Segment>
        <Placeholder fluid>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
        </Placeholder>
      </Segment>
    );
  }

  if (isEmpty(data)) {
    return (
      <Segment>
        <p className="bold">No previous orders</p>
      </Segment>
    );
  }

  return <CustomTable columns={columns as any} data={data!} />;
};

RelatedOrders.propTypes = {
  orders: PropTypes.array,
  isFetching: PropTypes.bool,
  currentOrderId: PropTypes.string,
};
