import React from 'react';
import { Header } from 'semantic-ui-react';
import { Cell } from 'react-table';
import { CustomTable } from './custom-table';
import { FormattedProducts } from '../interfaces';
import { extractRowObject } from '../shared/utils/table-utils';
import { SubstituteProductButton, SubstituteProductProps } from './order/substitute-product-button';
import { ConditionalRenderer } from './conditional-renderer';

const extractProductItem = (
  cell: Cell<FormattedProducts>,
): FormattedProducts => extractRowObject<FormattedProducts>(cell);

const ActionsCell = ({
  orderId, companyId, product, orderStatus, disabled = false,
}: SubstituteProductProps) => {
  return (
    <ConditionalRenderer role={'order.SUBSTITUTE'}>
      <SubstituteProductButton orderId={orderId} companyId={companyId}
        product={product} orderStatus={orderStatus} disabled={disabled} />
    </ConditionalRenderer>
  );
};

export const Product = ({
  products, companyId, orderStatus, orderId, disabled = false,
}: IProductProps) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Number Ordered',
        accessor: 'quantity',
      },
      {
        Header: 'Medication',
        accessor: 'name',
      },
      {
        Header: 'Product Code',
        accessor: 'id',
      },
      {
        Header: 'Total Contents',
        accessor: 'total',
      },
      {
        Header: '',
        accessor: 'dummy',
        Cell: (cell: Cell<FormattedProducts>) => {
          const product = extractProductItem(cell);
          return ActionsCell({
            orderId, companyId, product, orderStatus, disabled,
          });
        },
      },
    ],
    [orderId, companyId, orderStatus],
  );

  if (!products.length) {
    return null;
  }

  return (
    <>
      <Header size="medium">Order Pick List</Header>
      <CustomTable columns={columns} data={products} />
    </>
  );
};

export interface IProductProps {
  products: Partial<FormattedProducts>[];
  companyId: string;
  orderStatus: string;
  orderId: string;
  disabled?: boolean;
}
