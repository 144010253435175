import React, { Fragment, ReactNode } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

export type Conditions = {
  assignment?: string | null,
  role?: string,
  order?: { status: string },
  statuses?: Array<string>,
  condition?: boolean,
};

type Props = Conditions & {
  children: ReactNode,
};

export const shouldRender = (
  keycloak: Keycloak.KeycloakInstance | undefined,
  {
    assignment, role, order, statuses, condition = true,
  }: Conditions,
): boolean => {
  if (!condition) {
    return false;
  }
  const parsedToken = keycloak?.idTokenParsed as any;
  if (assignment && assignment !== parsedToken.email) {
    return false;
  }

  if (role) {
    const [resource, action] = role.split('.');

    const resourceAccess = keycloak?.tokenParsed?.resource_access?.[resource]?.roles;

    if (!resourceAccess?.some((resourceRole) => resourceRole === action || resourceRole.startsWith(`${action}:`))) {
      return false;
    }
  }
  if (statuses) {
    if (!order || !statuses.includes(order!.status)) {
      return false;
    }
  }

  return true;
};

export const ConditionalRenderer = ({ children, ...conditions }: Props) => {
  const { keycloak } = useKeycloak();

  if (!shouldRender(keycloak, conditions)) {
    return null;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};
