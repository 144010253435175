import { useCallback, useState } from 'react';

export const useError = () => {
  const [, setError] = useState();
  return useCallback((e: any) => {
    setError(() => {
      throw new Error(e);
    });
  }, [setError]);
};
