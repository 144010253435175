import React from 'react';
import {
  Formik,
  Form as FormikForm,
  Field as FormikField,
  FormikErrors,
  FormikTouched,
  FieldAttributes,
} from 'formik';
import * as yup from 'yup';

import {
  Button, Form, DropdownProps, Icon,
} from 'semantic-ui-react';
import { isEmpty, omit, startCase } from 'lodash/fp';
import {
  differenceInYears, format, subYears,
} from 'date-fns';
import { IPatient, IPatientDto, MetaData } from '../interfaces';

export interface B2bFormProps {
  data: any;
  formFieldData: MetaData[];
  validationSchema: yup.SchemaOf<any>;
  updateHandler: Function;
}
export interface PatientEditFormProps {
  initialValues?: IPatient;
  loading: boolean;
  updateHandler: Function;
}

const renderError = (
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  fieldName: string,
) =>
  (errors[fieldName] && touched[fieldName] ? (
    <div style={{ color: 'red' }}>{errors[fieldName]}</div>
  ) : null);

const errorMessage = (
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  fieldName: string,
) => (errors[fieldName] && touched[fieldName] ? errors[fieldName] : false);

const adultAgeValidator = (minimumAge: number) => (value?: string | number | Date) => {
  if (!value) return false;
  const currentDate = new Date();
  const dateOfBirth = new Date(value);

  return differenceInYears(currentDate, dateOfBirth) >= minimumAge;
};

const patientDtoSchema = ({ minimumAge }: { minimumAge: number }): yup.SchemaOf<IPatientDto> => yup.object().shape({
  firstName: yup.string().trim().required('Please specify a first name'),
  lastName: yup.string().trim().required('Please specify a last name'),
  dob: yup
    .string()
    .required()
    .test(
      `over ${minimumAge}`,
      `A patient must be at least ${minimumAge} years old`,
      adultAgeValidator(minimumAge),
    ),
  sex: yup.string().trim().required('Please specify the sex'),
  telephone: yup.string().required('Please specify a telephone number'),
  email: yup
    .string()
    .email('Not a valid email')
    .trim()
    .required('You must specify an email'),
  genderIdentity: yup.string().trim().nullable(),
  preferredPronouns: yup.string().trim().nullable(),
  preferredName: yup.string().trim().nullable(),
});

export const PatientEditForm = ({
  initialValues,
  updateHandler,
  loading,
}: PatientEditFormProps) => {
  const isUnder18 = initialValues?.dob && differenceInYears(new Date(), new Date(initialValues?.dob)) < 18;
  const minimumAge = isUnder18 ? 16 : 18;

  return (
    <Formik
      initialValues={omit(['id'], initialValues
        ? { ...initialValues, dob: format(new Date(initialValues.dob), 'yyyy-MM-dd') } : initialValues)}
      validationSchema={patientDtoSchema({ minimumAge})}
      onSubmit={(values) => {
        updateHandler(values);
      }}
    >
      {({
        errors,
        touched,
        handleSubmit,
        handleReset,
        isSubmitting,
        isValid,
      }) => {
        const getErrorMessageForField = (fieldName: string) =>
          errorMessage(errors, touched, fieldName);
        return (
          <Form
            size="large"
            onReset={handleReset}
            onSubmit={handleSubmit}
            loading={loading}
          >
            <Form.Group widths="equal">
              <FormikField
                name="firstName"
                id="first-name"
                as={Form.Input}
                label="First name"
                fluid
                required
                error={getErrorMessageForField('firstName')}
              />
              <FormikField
                name="lastName"
                id="last-name"
                as={Form.Input}
                label="Last name"
                error={getErrorMessageForField('lastName')}
                fluid
                required
              />
            </Form.Group>
            <FormikField name="dob">
              {({ field }: FieldAttributes<any>) => {
                return (
                  <Form.Input
                    {...field}
                    label="Date of birth"
                    type="date"
                    required

                    max={format(subYears(Date.now(), minimumAge), 'yyyy-MM-dd')}
                    error={getErrorMessageForField('dob')}
                  />
                );
              }}
            </FormikField>
            <FormikField name="sex">
              {({ field, form }: FieldAttributes<any>) => {
                return (
                  <Form.Select
                    {...field}
                    label="Sex at birth"
                    name="sex"
                    error={getErrorMessageForField('sex')}
                    required
                    options={[
                      {
                        value: 'male',
                        text: 'Male',
                      },
                      {
                        value: 'female',
                        text: 'Female',
                      },
                    ]}
                    value={field.value}
                    onChange={(_, fieldData: DropdownProps) =>
                      form.setFieldValue('sex', fieldData.value)
                    }
                    onBlur={() => form.setFieldTouched('sex', true)}
                  />
                );
              }}
            </FormikField>
            <FormikField
              name="genderIdentity"
              id="gender-identity"
              as={Form.Input}
              label="Gender Identity"
              error={getErrorMessageForField('genderIdentity')}
              fluid
            />
            <Form.Group widths="equal">
              <FormikField
                name="preferredName"
                id="preferred-name"
                as={Form.Input}
                label="Preferred name"
                error={getErrorMessageForField('preferredName')}
                fluid
              />
              <FormikField
                name="preferredPronouns"
                id="preferred-pronouns"
                as={Form.Input}
                error={getErrorMessageForField('preferredPronouns')}
                label="Preferred pronouns"
                fluid
              />
            </Form.Group>
            <FormikField
              name="email"
              id="email"
              as={Form.Input}
              required
              type="email"
              error={getErrorMessageForField('email')}
              label="Email"
            />
            <FormikField
              name="telephone"
              id="telephone"
              required
              as={Form.Input}
              type="tel"
              error={getErrorMessageForField('telephone')}
              label="Telephone"
            />
            <Button disabled={isSubmitting || !isValid} type="submit">
              {' '}
              Submit{' '}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export const B2bForm = ({
  data,
  formFieldData,
  validationSchema,
  updateHandler,
}: B2bFormProps) => {
  return (
    <Formik
      initialValues={omit(['id'], data)}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        updateHandler(values);
      }}
    >
      {({ errors, touched }) => {
        return (
          <FormikForm>
            {formFieldData.map(
              ({ fieldName, component = Form.Input, icon }) => (
                <div key={fieldName}>
                  <Icon name={icon} /> {startCase(fieldName)}
                  <FormikField name={fieldName} as={component} />
                  <span>{renderError(errors, touched, fieldName)}</span>
                </div>
              ),
            )}
            <Button
              disabled={isEmpty(touched) || !isEmpty(errors)}
              type="submit"
            >
              {' '}
              Submit{' '}
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
