import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import type { IPrescriber } from '../../interfaces';
import { assignPrescriber } from '../../reducers/ordersSlice';

interface IAssignPrescriberButtonProps {
  token: string;
  orderId: string;
  text: string;
  prescriber: IPrescriber;
  disabled?: boolean;
}

export const AssignPrescriberButton = ({
  text,
  prescriber,
  orderId,
  token,
  disabled = false,
}: IAssignPrescriberButtonProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      data-testid={`assign-prescriber-${orderId}`}
      onClick={() => dispatch(assignPrescriber(token, orderId, prescriber))}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
