import React, { useCallback, useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import {
  SemanticCOLORS,
  SemanticFLOATS,
  SemanticShorthandItem,
  SemanticSIZES,
} from 'semantic-ui-react/dist/commonjs/generic';
import { IconProps } from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { ModalHeaderProps } from 'semantic-ui-react/dist/commonjs/modules/Modal/ModalHeader';

export interface IModalConfirmationProps {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',
  header?: SemanticShorthandItem<ModalHeaderProps>,
  message?: string,
  buttonText: string,
  buttonSize?: SemanticSIZES,
  buttonIcon: boolean | SemanticShorthandItem<IconProps>,
  buttonColor?: SemanticCOLORS,
  onCancel?: Function,
  onSubmit: Function;
  buttonAlign?: SemanticFLOATS;
  dataTestId?: string;
  loading?: boolean
}

export const ModalConfirmationButton = ({
  size = 'small',
  header,
  message = 'Are you sure?',
  buttonText,
  buttonSize = 'tiny',
  buttonColor = 'grey',
  buttonIcon = false,
  buttonAlign = 'right',
  onCancel,
  onSubmit,
  dataTestId,
  loading,
}: IModalConfirmationProps) => {
  const [isOpen, setOpen] = useState(false);
  const openHandler = useCallback(() => {
    if (isOpen && onCancel) {
      onCancel();
    }
    setOpen(!isOpen);
  },
  [isOpen],
  );

  const onConfirm = () => {
    onSubmit();
    openHandler();
  };

  return (
    <>
      <Button.Group size={buttonSize} floated={buttonAlign}>
        <Button
          color={buttonColor}
          icon={buttonIcon}
          content={buttonText}
          onClick={openHandler}
          data-testid={dataTestId}
          loading={loading}
        />
      </Button.Group>
      <Confirm
        size={size}
        header={header}
        content={message}
        open={isOpen}
        onCancel={openHandler}
        onConfirm={onConfirm}
      />
    </>
  );
};
