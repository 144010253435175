import React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { Button, Header } from 'semantic-ui-react';
import { IPatient } from '../../interfaces';
import { CustomTable } from '../../components/custom-table';

const ShowDetailsButton = (id: string) => (
  <Link data-testid='detailsLink' to={`/patients/view/${id}`}>
    <Button style={{ margin: 0 }}>Show Details</Button>
  </Link>
);

export const PatientDetails = (props: IPatientDetailsProps) => {
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (details: IPatientDetailsProps) => `${details.patient.firstName} ${details.patient.lastName}`,
      },
      {
        Header: 'ID',
        accessor: 'patient.id',
      },
      {
        Header: 'Email',
        accessor: 'patient.email',
      },
      {
        Header: 'Age',
        accessor: (details: IPatientDetailsProps) => {
          if (!details.patient.dob) {
            return '';
          }
          return `${format(new Date(details.patient.dob), 'PPP')} (${formatDistanceToNowStrict(new Date(details.patient.dob), {
            unit: 'year',
            roundingMethod: 'floor',
          })} old)`;
        },
      },
      {
        Header: 'Sex',
        accessor: 'patient.sex',
      },
      {
        Header: 'Telephone',
        accessor: 'patient.telephone',
      },
      {
        Header: 'Patient Details',
        Cell: ShowDetailsButton(props.patient.id),
      },
    ],
    [props.patient.id],
  );

  const memoPatient = React.useMemo(() => props, [props]);
  return (
    <>
      <Header size="medium">
        Patient Information
      </Header>
      <CustomTable columns={columns} data={[memoPatient]} />
    </>
  );
};

interface IPatientDetailsProps {
  patient: IPatient
}
