import React from "react";
import { SemanticICONS } from "semantic-ui-react";

export const triageTitles = {
  awaiting_physical_prescription:
    "Order contains controlled substance, physical prescription required",
  awaiting_updated_consultation:
    "An updated consultation is required before the repeat order can be filled",
} as const;

export type TriageCodes = keyof typeof triageTitles;

interface TriageResolution {
  resolvedAt: Date;
  userId: string;
}

interface BaseTriageReason {
  code: TriageCodes;
  title: string;
  addedAt: Date;
}

export interface UnresolvedTriageReason extends BaseTriageReason {
  resolution?: undefined;
}

export interface ResolvedTriageReason extends BaseTriageReason {
  resolution: TriageResolution;
}

type TriageReason = ResolvedTriageReason | UnresolvedTriageReason;

export interface RequestedItem {
  productId: string;
  instructions: string;
}

export interface IOrder {
  id: string;
  upstreamId?: string;
  companyId: string;
  createdAt: string;
  patient: Partial<IPatient>;
  patientId?: string;
  shippingAddress: IShippingAddress;
  shippingInformation: IShippingInformation;
  dispatcherId?: string | null;
  dispenserId?: string | null;
  pharmacistId?: string | null;
  prescriberId?: string | null;
  consultationId: string;
  prescriptionId?: string;
  reviewerDetails?: IReviewer;
  status: string;
  resolution?: IResolution;
  items: IOrderItems[];
  auditHistory: IAuditHistory[];
  repeatPrescription?: IRepeatPrescription;
  triageReasons: TriageReason[];
  prescription?: {
    requestedItems: RequestedItem[];
  };
  imageUrls?: string[];
  isSentToPhloConnect?: boolean;
}

export interface IPagination {
  currentPage: number;
  limit: number;
  next: number | null;
  prev: number | null;
  totalPages: number;
  totalRecords: number;
}

export interface IPaginatedOrders {
  data: IOrder[];
  pagination: IPagination;
}

export interface IResolution {
  resolvedAt: string;
  type?: ResolutionType;
}

export type ResolutionType = "DISPATCHED" | "REJECTED" | "CANCELLED";

export interface ICacheable {
  url: string;
}

export interface IPatient {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  sex: string;
  telephone: string;
  email: string;
  genderIdentity?: string;
  preferredPronouns?: string;
  preferredName?: string;
}

export interface IPatientDto {
  firstName: string;
  lastName: string;
  dob: string;
  sex: string;
  telephone: string;
  email: string;
  genderIdentity?: string | null;
  preferredPronouns?: string | null;
  preferredName?: string | null;
}

export interface IPatientNote {
  id: string;
  authorName: string;
  authorEmail?: string;
  authorId?: string;
  lastName: string;
  content: string;
  createdAt: string;
}

export interface IShippingAddress {
  name?: string;
  line1: string;
  line2?: string;
  city?: string;
  region?: string;
  postcode: string;
}

export interface IShippingInformation {
  trackingUrl: string;
  trackingId: string;
  carrier: string;
}

export interface IReviewer {
  name: string;
  registrationId: string;
  registrationBody: string;
  reviewedAt: string;
}

export interface IProduct {
  active: boolean;
  available: boolean;
  currency: string;
  dateCode: string;
  id: string;
  medication?: { id: string | null; name?: string };
  price: string;
  type: string;
  units: string;
  variant: string;
  deletedAt?: string;
  companyId?: string[];
  loading?: boolean;
}

export type IMedicationDto = {
  name: string;
  available: boolean;
  units: number;
  form: string;
  type: string;
  dosageLevel: string;
  instructions: string;
  packSize: number;
  categoryCode: string;
  shortCode: string;
};

export interface IMedication {
  id: string;
  name: string;
  available: boolean;
  units: number;
  form: string;
  type: string;
  dosageLevel: string;
  instructions: string;
  packSize: string;
  categoryCode: string;
  shortCode: string;
}

export enum MedicationType {
  gsl = "GSL",
  p = "P",
  pom = "POM",
}

export type ProductType =
  | "medication"
  | "shipment"
  | "prescriber-consultation"
  | "pharmacist-consultation"
  | "other";

export interface IOrderItems {
  id: string;
  quantity: number;
  productType: ProductType;
  medicationType: MedicationType;
  medication?: IMedication;
}

export interface IConsultation {
  id: string;
  patientId: string;
  consultation: Array<{
    question: string;
    answer: string;
  }>;
}

export interface FormattedProducts extends IMedication, IOrderItems {
  total: number;
}

export interface PrescriptionUpdate {
  id: string;
  instructions: string;
}

export interface FormattedOrderItems {
  productsWithMedication: Partial<FormattedProducts>[];
  allProducts: Partial<FormattedProducts>[];
}

export interface IOrderNote {
  id: string;
  authorName: string;
  authorEmail: string;
  content: string;
  createdAt: string;
}

export interface ICreatePatientNoteButtonProps {
  onComplete?: VoidFunction;
  prescriber: IPrescriber;
  patientId: string;
}

export interface IAuditHistory {
  authorizedParty: string;
  username: string;
  userId: string;
  eventType: string;
  changed?: ChangedType;
  at: string;
  message?: string;
}

export enum RepeatPrescriptionInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export interface IRepeatPrescription {
  frequency: number;
  interval: RepeatPrescriptionInterval;
  repeat: number;
  sequence: { current: number; total: number };
}

export interface IPrescriber {
  prescriberId?: string | null;
  prescriberUsername?: string | null;
}

export interface IPharmacist {
  pharmacistId: string | null;
  pharmacistUsername: string | null;
}

export interface IDispenser {
  dispenserId: string | null;
  dispenserUsername: string | null;
}

export interface IDispatcher {
  dispatcherId: string | null;
  dispatcherUsername: string | null;
}

export interface IProductSubstituteParams {
  id: string;
  reason: string;
  quantity: number;
  item: string;
}

export type ChangedType = {
  added: object;
  deleted: object;
  updated: object;
};

interface IPrescriptionPatient {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  telephone: string;
}

export interface IPrescriptionItem {
  productId: string;
  name: string;
  quantity: number;
  instructions: string;
}

export interface IPrescriptionOrder {
  id: string;
  status: string;
  shippingAddress: IShippingAddress;
  repeatPrescription?: IRepeatPrescription;
  patient: IPrescriptionPatient;
  items: Array<IPrescriptionItem>;
}

export interface IPrescription {
  order: IPrescriptionOrder;
}
export interface IReportEntry {
  orderId: string;
}
export interface IMigraineReportEntry extends IReportEntry {
  patientId: string;
  resolutionDate: Date;
  answer: string;
  patientName: string;
  email: string;
  medication: string;
  dispensed: string | null;
  status: string;
  reason: string | null;
}

export interface IOverFortyReportEntry extends IReportEntry {
  patientId: string;
  upstreamId: string;
  createdDate: Date;
  patientName: string;
  requestedMedication: string;
  dispensedMedication: string;
  prescriber: string | null;
  pharmacist: string | null;
}

export interface IGpContactReportEntry extends IReportEntry {
  createdAt: Date;
  patientId: string;
  gpName: string;
  gpPostcode: string;
  answer: string;
  patientName: string;
  email: string;
  requestedMedication: string;
  dispensedMedication: string | null;
  status: string;
  reason: string | null;

  informGpConsent: string | null;
}

export interface IHelpMeDecideEntry extends IReportEntry {
  patientAge: string;
  bmiStatus: string;
  migraineStatus: string;
  migraineVisualAura: string;
  smokingStatus: string;
  bloodPressureSystolic: string;
  bloodPressureDiastolic: string;
  medication: string;
  createdAt: Date;
}

export interface ISalesReportEntry extends IReportEntry {
  orderCreationDate: Date;
  dispensedDate: Date | null;
  resolutionDate: Date;
  companyID: string;
  dispensedMedication: string;
  dispensedMedicationType: string;
  prescriber: string | null;
  pharmacist: string | null;
  patientId: string;
  patientName: string;
  patientDOB: string;
  status: string;
  reason: string | null;
}

export interface IRejectionsReportEntry extends IReportEntry {
  patientId: string;
  patientName: string;
  patientDOB: string;
  patientAge: string;
  orderCreationDate: Date;
  companyID: string;
  resolutionDate: Date;
  status: string;
  reason: string | null;
  prescriptionType: string;
}

export enum ReportType {
  Migraine = "Migraine",
  OverForty = "OverForty",
  Sales = "Sales",
  GpContact = "GpContact",
  HelpMeDecide = "HelpMeDecide",
  Rejections = "Rejections",
}

export interface IReport<T extends IReportEntry> {
  id?: string;
  entries: T[];
  name: string;
  startDate: Date;
  endDate: Date;
  type: ReportType;
  createdAt: Date;
}

export interface ICreateReportRequest {
  from: Date;
  to: Date | null;
  type: ReportType;
}

export interface MetaData {
  fieldName: string;
  value?: any; // TODO constrain me
  icon: SemanticICONS;
  component?: React.Component;
  // eslint-disable-next-line no-undef
  specialRenderer?: (dto: any) => JSX.Element; // TODO revisit
  formatField?: (dto: any) => any;
  labelText?: string;
  shouldRenderOnStatic?: boolean;
}

export interface IEditPageProps {
  create?: boolean;
  pageContext: "edit" | "create" | "view";
}

export interface ISearchResponse {
  upstreamId?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export enum EPageStep {
  FIRST = "first",
  PREV = "prev",
  NEXT = "next",
  LAST = "last",
}

export enum TypeOfPrescription {
  REPEAT = "Repeat",
  ONE_TIME = "One Time",
}
