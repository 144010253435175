import axios from 'axios';
import { deserialize } from 'json-api-deserialize';
import { useKeycloak } from '@react-keycloak/web';
import useSWR from 'swr';

export const fetcher = (token?: string) => async (endpoint: string) => {
  const { data: response } = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.errors) {
    // TODO: improve this handler:
    console.error('Error fetching', endpoint, response.errors);
    throw new Error(response.errors[0].title);
  }

  return deserialize(response.data);
};

export const useSwr = <T>(url: string | (() => string | null)) => {
  const { keycloak } = useKeycloak();
  return useSWR<T, Error>(url, fetcher(keycloak!.token));
};
