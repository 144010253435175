import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import type { RootState } from '../../root-reducer';
import { dispatchOrder } from '../../reducers/ordersSlice';

interface IDispatchButtonProps {
  orderId: string,
  token: string,
  disabled?: boolean,
}

export const DispatchButton = ({ orderId, token, disabled = false }: IDispatchButtonProps) => {
  const { isUpdatingOrder } = useSelector(
    (state: RootState) => state.ordersReducer,
  );

  const dispatch = useDispatch();

  return (
    <Button
      loading={isUpdatingOrder}
      disabled={isUpdatingOrder || disabled}
      color='green'
      icon='check circle'
      content='Mark as dispatched'
      onClick={() =>
        dispatch(dispatchOrder(token, orderId))
      }
    />
  );
};
