import { toSafeInteger } from 'lodash';
import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { EPageStep, IPagination } from '../../interfaces';

interface TerminalStatusPaginatorProps {
  onPageChange: Function;
  setPageLimit: Function;
  setPageSize: Function;
  pagination: IPagination | null;
}

const pageDropdownValues = [
  {
    key: 10,
    value: 10,
    text: 10,
  },
  {
    key: 20,
    value: 20,
    text: 20,
  },
  {
    key: 30,
    value: 30,
    text: 30,
  },
  {
    key: 40,
    value: 40,
    text: 40,
  },
  {
    key: 50,
    value: 50,
    text: 50,
  },
];

export const TerminalStatusPaginator = ({ onPageChange, setPageLimit, setPageSize, pagination }: TerminalStatusPaginatorProps) => {
  return (
    <div className="flex items-center justify-end" style={{ paddingRight: '5rem' }}>
      <div>
        <Button onClick={() => onPageChange(EPageStep.FIRST)} disabled={pagination?.currentPage === 1}>
          {'<<'}
        </Button>
        <Button onClick={() => onPageChange(EPageStep.PREV)} disabled={pagination?.prev === null}>
          {'<'}
        </Button>
        <Button onClick={() => onPageChange(EPageStep.NEXT)} disabled={pagination?.next === null}>
          {'>'}
        </Button>
        <Button onClick={() => onPageChange(EPageStep.LAST)} disabled={pagination?.totalPages === pagination?.currentPage}>
          {'>>'}
        </Button>
      </div>
      <Dropdown
        className="mx1"
        options={pageDropdownValues}
        value={pagination?.limit}
        text={`Show ${pagination?.limit}`}
        onChange={(_, data) => {
          const value = toSafeInteger(data.value);
          setPageLimit(value);
          setPageSize(value);
        }}
      />
      <span>
        {`Page ${pagination?.currentPage} of ${pagination?.totalPages}`}
      </span>
    </div>
  );
};
