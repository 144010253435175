import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import type { IDispenser } from '../../interfaces';
import { assignDispenser } from '../../reducers/ordersSlice';

interface IAssignDispenserButtonProps {
  token: string;
  orderId: string;
  text: string;
  dispenser: IDispenser;
  disabled?: boolean;
}

export const AssignDispenserButton = ({
  token,
  orderId,
  text,
  dispenser,
  disabled = false,
}: IAssignDispenserButtonProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(assignDispenser(token, orderId, dispenser))}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
