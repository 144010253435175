import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import type { IDispatcher } from '../../interfaces';
import { assignDispatcher } from '../../reducers/ordersSlice';

interface IAssignDispatcherButtonProps {
  token: string;
  orderId: string;
  text: string;
  dispatcher: IDispatcher;
  disabled?: boolean;
}

export const AssignDispatcherButton = ({
  text,
  dispatcher,
  orderId,
  token,
  disabled = false,
}: IAssignDispatcherButtonProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      data-testid={`assign-dispatcher-${orderId}`}
      onClick={() => dispatch(assignDispatcher(token, orderId, dispatcher))}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
