import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmationDropdownButton } from '../confirmation-dropdown-button';
import { rejectOrder } from '../../reducers/ordersSlice';

interface IRejectButtonProps {
  status: string,
  orderId: string,
  token: string,
  disabled?: boolean,
}

const STATUS_ALLOWED_TO_REJECT = [
  'READY_FOR_PRESCRIBER',
  'READY_FOR_REVIEW',
  'READY_FOR_DISPATCH',
  'PRESCRIBING',
  'REVIEWING',
];

export const RejectButton = ({
  status, orderId, token, disabled: _disabled = false,
}: IRejectButtonProps) => {
  const dispatch = useDispatch();
  const canReject = STATUS_ALLOWED_TO_REJECT.includes(status);
  const [disabled, setDisabled] = useState(_disabled);
  const [reason, setReason] = useState('');
  const [isOtherReason, setIsOtherReason] = useState(false);

  if (!canReject) {
    return null;
  }

  return (
    <ConfirmationDropdownButton
      color="red"
      icon="cancel"
      content="Reject"
      disabled={disabled}
      title="Reason for Rejection"
      message="Are you sure you want to reject this order?"
      placeholder="Please provide a reason..."
      onSubmit={() => {
        setDisabled(true);
        if (!reason) {
          setDisabled(false);
          return;
        }

        dispatch(rejectOrder(token, orderId, isOtherReason ? `Other: ${reason}` : reason));
      }}
      onChange={(updatedReason: string) => setReason(updatedReason)}
      setIsOtherReason={setIsOtherReason}
    />
  );
};
