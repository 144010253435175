import { ICreateReportRequest, IReport } from '../interfaces';
import { get, post } from './base';

export const getReports = async (token: string): Promise<IReport<any>[]> => {
  const url = '/api/reports';
  const data = await get<IReport<any>[]>(url, token);

  return data;
};

export const getReportById = async <T>(
  token: string,
  id: string,
): Promise<T> => {
  const url = `/api/reports/${id}`;
  return get<T>(url, token);
};

export const createReport = async (
  token: string,
  request: ICreateReportRequest,
): Promise<{}> => {
  const url = '/api/reports';
  const type = 'report';

  return post<{}>(url, token, type, request);
};
