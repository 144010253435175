import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import { formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const StatusLozenge = ({ value, resolutionDate }: { value: string, resolutionDate?: Date }) => (
  <Label color={resolutionDate ? 'red' : 'teal'}>
    <Icon name="exclamation circle" />{_.startCase(_.lowerCase(value))}{
      resolutionDate
  && ` ${formatDistanceToNow(utcToZonedTime(resolutionDate, 'Europe/London'))} ago`
    }
  </Label>
);

StatusLozenge.propTypes = {
  value: PropTypes.string.isRequired,
  resolutionDate: PropTypes.any,
};
