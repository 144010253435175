import React from 'react';
import { List, Table } from 'semantic-ui-react';
import { formatDistanceToNow } from 'date-fns';
import {
  capitalize, get, isEmpty, keys, startCase, tail, truncate,
} from 'lodash';
import type { IAuditHistory } from '../../interfaces';

interface IAuditHistoryProps {
  auditHistory: IAuditHistory[];
}

type ChangeType = 'added' | 'updated' | 'deleted';
type ChangedType = { change: object | null, type: ChangeType | null };
const relatedAudits = ['authorizedParty', 'username', 'eventType', 'message'];
const AUDIT_ADDED: ChangeType = 'added';
const AUDIT_UPDATED: ChangeType = 'updated';
const AUDIT_DELETED: ChangeType = 'deleted';

const hasChange = (changeName: ChangeType, audit: IAuditHistory) => (
  !isEmpty(get(audit, `changed.${changeName}`))
);

const getChange = (audit: IAuditHistory, change: ChangeType) => (
  hasChange(change, audit)
    ? { change: get(audit, `changed.${change}`), type: change }
    : { change: null, type: null }
);

const getChangeCount = (audit: IAuditHistory, change: ChangeType) => (
  keys(get(audit, `changed.${change}`)).length
);

const getAuditRowsNum = (audit: IAuditHistory) => {
  return audit.message ? relatedAudits.length : relatedAudits.length - 1;
};

export const getDiffRowsNum = (audit: IAuditHistory) => {
  const changedLength = (
    getChangeCount(audit, AUDIT_ADDED)
    + getChangeCount(audit, AUDIT_UPDATED)
    + getChangeCount(audit, AUDIT_DELETED)
  );
  const relatedAuditsLength = getAuditRowsNum(audit);
  return changedLength === 0 ? relatedAuditsLength + 1 : changedLength + relatedAuditsLength;
};

const ValueCell = ({ value = '' }: { value: any }) => {
  if (value && typeof value === 'object') {
    const items = [];
    for (const [key, val] of Object.entries(value)) {
      let objectException = null;
      let keyException = null;
      // Originally done to handle adding the "originalItems" field to the audit history
      if (val && typeof val === 'object') {
        objectException = get(val, 'id');
        keyException = `item${key}`;
      }

      items.push(
        <List.Item>
          <i>
            {startCase(keyException || key)}:
          </i>{truncate(JSON.stringify(objectException || val), { length: 120, separator: ',' })}
        </List.Item>,
      );
    }
    return (<List>{items}</List>);
  }
  return (
    <>{value}</>
  );
};

const TypedCell = ({ type, children }: { type: ChangeType | null, children: React.ReactNode }) => (
  <Table.Cell
    positive={type === AUDIT_ADDED}
    negative={type === AUDIT_DELETED}
    warning={type === AUDIT_UPDATED}
  >
    {children}
  </Table.Cell>
);

const RowFirstOfChanged = ({ audit, changed }: { audit: IAuditHistory, changed: ChangedType }) => {
  if (changed.change === null || changed.type === null) {
    return null;
  }
  const [firstChangeKey, firstChangeValue] = Object.entries(changed.change)[0];
  return (
    <>
      <Table.Cell rowSpan={getChangeCount(audit, changed.type)}>
        {capitalize(`${changed.type}`)}
      </Table.Cell>
      <TypedCell type={changed.type}>{startCase(firstChangeKey)}</TypedCell>
      <TypedCell type={changed.type}>
        <ValueCell value={firstChangeValue} />
      </TypedCell>
    </>
  );
};

interface IRowRestOfChanged {
  audit: IAuditHistory;
  changed: ChangedType;
  index: number;
}

const RowRestOfChanged = ({ audit, changed, index }: IRowRestOfChanged) => {
  if (changed.change === null || changed.type === null) {
    return null;
  }
  const restOfChanges = tail(Object.entries(changed.change));
  return (
    <>
      {index > 0
      && <Table.Row>
        <RowFirstOfChanged audit={audit} changed={changed} />
      </Table.Row>
      }
      {restOfChanges.map(([changeName, changeValue], i) => {
        return (
          <Table.Row key={i}>
            <TypedCell type={changed.type}>{startCase(changeName)}</TypedCell>
            <TypedCell type={changed.type}>
              <ValueCell value={changeValue} />
            </TypedCell>
          </Table.Row>
        );
      })}
    </>
  );
};

export const AuditHistory = (props: IAuditHistoryProps) => {
  const { auditHistory } = props;
  return (
    <Table compact structured basic>
      <Table.Body>
        {auditHistory.map((audit: IAuditHistory, index) => {
          const changedAdded = getChange(audit, 'added');
          const changedUpdated = getChange(audit, 'updated');
          const changedDeleted = getChange(audit, 'deleted');
          const changes = [changedAdded, changedUpdated, changedDeleted]
            .filter((change: ChangedType) => change.change && change.type);
          const cellNumberOfRows = getDiffRowsNum(audit);
          return (
            <React.Fragment key={index}>
              <Table.Row>
                <Table.Cell rowSpan={cellNumberOfRows}>
                  {formatDistanceToNow(new Date(audit.at), { addSuffix: true })}
                </Table.Cell>
                {changes.length > 0
                && <RowFirstOfChanged audit={audit} changed={changes[0]} />
                }
              </Table.Row>
              {changes.length > 0
              && changes.map((changed: ChangedType, i) =>
                <RowRestOfChanged
                  key={i}
                  index={i}
                  audit={audit}
                  changed={changed}
                />,
              )
              }
              <>
                {relatedAudits
                  .filter((val) => (val !== 'message' || audit[val as keyof IAuditHistory]))
                  .map((auditName: string, i) => {
                    return (
                      <Table.Row key={i}>
                        {i === 0 && <Table.Cell rowSpan={getAuditRowsNum(audit)}>Audit</Table.Cell>}
                        <Table.Cell>{startCase(auditName)}</Table.Cell>
                        <Table.Cell>
                          <ValueCell value={audit[auditName as keyof IAuditHistory]} />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </>
            </React.Fragment>
          );
        })}
      </Table.Body>
    </Table>
  );
};
