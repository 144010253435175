import { Breadcrumb, Container, Header, Icon, Menu } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MedicationsList } from '../../components/medication/medications-list';
import { DismissibleErrorMessage } from '../../components/dismissableErrorMessage';
import {
  fetchMedications,
  getMedicationsAsArray,
  medicationsErrorHandled,
} from '../../reducers/medicationsSlice';
import { RootState } from '../../root-reducer';

export const MedicationsPage = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token as string;
  const { error } = useSelector((state: RootState) => state.medicationsReducer);
  const medications = useSelector(getMedicationsAsArray);

  const getMedications = useCallback(async () => {
    await dispatch(fetchMedications(token));
  }, [token, dispatch]);

  useEffect(() => {
    getMedications();
  }, [getMedications]);

  const { createMedicationsEnabled } = useFlags();

  if (error) {
    return (
      <DismissibleErrorMessage
        handleError={() => dispatch(medicationsErrorHandled())}
        message={error}
      />
    );
  }

  return (
    <Container>
      <Breadcrumb
        divider="/"
        sections={[
          { key: 'home', content: <Icon name="home" /> },
          {
            key: 'medications',
            content: <a href="/medications">Medications</a>,
          },
        ]}
      />
      <Header size="medium">
        <Icon name="syringe" /> Medications List{' '}
      </Header>
      <MedicationsList medications={medications}>
        <Menu.Menu compact="true" position="left" size="mini" icon="labeled">
          {createMedicationsEnabled && (
            <Menu.Item name="create" onClick={() => { }}>
              <>
                <Icon name="plus" />
                <Link to="/medications/create"> Create Medication </Link>
              </>
            </Menu.Item>
          )}
        </Menu.Menu>
      </MedicationsList>
    </Container>
  );
};
