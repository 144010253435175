import {
  Breadcrumb, Button, Container, Dropdown, Header, Icon, Label, Menu,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import DatePicker from 'react-datepicker';
import { useKeycloak } from '@react-keycloak/web';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportsList } from '../../components/report/reports-list';
import { RootState } from '../../root-reducer';
import { fetchReports, createReport } from '../../reducers/reportsSlice';
import { ReportType } from '../../interfaces';
import { ConditionalRenderer } from '../../components/conditional-renderer';

export const ReportsPage = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || '';
  const dispatch = useDispatch();

  const { reportingEnabled } = useFlags();

  const [from, setFrom] = useState<Date>(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    return start;
  });

  const [to, toDate] = useState<Date>(() => {
    const end = new Date();
    end.setHours(23, 59, 59);
    return end;
  });
  const [type, setType] = useState<ReportType>(ReportType.Migraine);

  useEffect(() => {
    async function loadReports() {
      await dispatch(fetchReports(token || ''));
    }
    loadReports();
  }, [token, dispatch]);

  const reportOptions = [
    { key: ReportType.Migraine, text: ReportType.Migraine, value: ReportType.Migraine },
    { key: ReportType.OverForty, text: 'Over Forty', value: ReportType.OverForty },
    { key: ReportType.Sales, text: ReportType.Sales, value: ReportType.Sales },
    { key: ReportType.GpContact, text: 'GP Contact Requested', value: ReportType.GpContact },
  ];

  if (reportingEnabled) {
    reportOptions.push(
      { key: ReportType.HelpMeDecide, text: 'Help Me Decide', value: ReportType.HelpMeDecide },
      { key: ReportType.Rejections, text: 'Rejections', value: ReportType.Rejections },
    );
  }

  const { reports, isLoading } = useSelector((state: RootState) => state.reportsReducer);
  return (
    <Container>
      <Breadcrumb divider='/' sections={[
        { key: 'home', content: (<Icon name="home" />) },
        { key: 'reports', content: (<a href="/reports">Reports</a>) },
      ]} />
      <Header size="medium"><Icon name='newspaper' /> Reports List </Header>
      <ConditionalRenderer role={'report.POST'}>
        <Menu fluid>
          <Menu.Item>
            <Menu.Item > From </Menu.Item>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="ui menu right small"
              selected={from}
              onChange={(date: any) => setFrom(date)}
              isClearable
              placeholderText='Start Date'
              selectsStart
              startDate={from}
              endDate={to}
              maxDate={new Date()}
            />
          </Menu.Item>
          <Menu.Item>
            <Menu.Item> To </Menu.Item>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="ui menu right small"
              selected={to}
              onChange={(date: any) => toDate(date)}
              isClearable
              placeholderText='End Date'
              selectsEnd
              startDate={from}
              endDate={to}
              minDate={from}
              maxDate={new Date()}
            />
          </Menu.Item>
          <Menu.Item>
            <Menu.Item> Report Type </Menu.Item>
            <Dropdown
              fluid
              selection
              defaultValue={ReportType.Migraine}
              options={reportOptions}
              onChange={
                (event: SyntheticEvent, data) => {
                  setType(data.value as ReportType);
                }
              }
            />
          </Menu.Item>
          <Menu.Item className="right">
            <Button onClick={
              () => {
                dispatch(createReport(token, { type, from, to }));
              }}>Create New Report</Button>
          </Menu.Item>
        </Menu>
      </ConditionalRenderer>
      {!isLoading && <ReportsList reports={reports} />}
      {isLoading && <Label>Loading...</Label>}
    </Container>
  );
};
