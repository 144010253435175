import {
  IDispatcher,
  IDispenser,
  IOrder,
  IPaginatedOrders,
  IPharmacist,
  IPrescriber,
  IProductSubstituteParams,
} from "../interfaces";

import { get, doDelete, post, put } from "./base";

export const getOrderById = async (
  token: string,
  id: string
): Promise<IOrder> => {
  const url = `/api/orders/${id}`;
  return get<IOrder>(url, token);
};

export const getOrdersByQuery = async (
  token: string,
  query: string
): Promise<IOrder[]> => {
  const url = `/api/orders?${query}`;
  return get<IOrder[]>(url, token);
};

export const getOrdersByQueryWithPagination = async (
  token: string,
  query: string
): Promise<IPaginatedOrders> => {
  const url = `/api/orders?${query}`;
  return get<IPaginatedOrders>(url, token);
};

// eslint-disable-next-line max-len
export const rejectOrder = async (
  token: string,
  id: string,
  reason: string
): Promise<void> => {
  const url = `/api/orders/${id}/reject`;
  return post<void>(url, token, "order-rejection", { reason });
};

// eslint-disable-next-line max-len
export const cancelOrder = async (
  token: string,
  id: string,
  reason: string
): Promise<IOrder> => {
  const url = `/api/orders/${id}/cancel`;
  return post<IOrder>(url, token, "order-cancellation", { reason });
};

export const approveOrder = async (
  token: string,
  id: string
): Promise<IOrder> => {
  const url = `/api/orders/${id}/approve`;
  return post<IOrder>(url, token);
};

export const voidOrderPrescription = async (
  token: string,
  id: string
): Promise<IOrder> => {
  const url = `/api/orders/${id}/prescriptions`;
  return doDelete<IOrder>(url, token);
};

export const dispatchOrder = async (
  token: string,
  id: string
): Promise<IOrder> => {
  const url = `/api/orders/${id}/dispatch`;
  return post<IOrder>(url, token);
};

export const dispenseOrder = async (
  token: string,
  id: string
): Promise<IOrder> => {
  const url = `/api/orders/${id}/dispense`;
  return post<IOrder>(url, token);
};

// eslint-disable-next-line max-len
export const assignPharmacist = async (
  token: string,
  id: string,
  pharmacist: IPharmacist
): Promise<IOrder> => {
  const url = `/api/orders/${id}/assign-pharmacist`;
  const type = "order-pharmacist-assignation";
  return post<IOrder>(url, token, type, pharmacist);
};

// eslint-disable-next-line max-len
export const assignDispenser = async (
  token: string,
  id: string,
  dispenser: IDispenser
): Promise<IOrder> => {
  const url = `/api/orders/${id}/assign-dispenser`;
  const type = "order-dispenser-assignation";
  return post<IOrder>(url, token, type, dispenser);
};

// eslint-disable-next-line max-len
export const assignDispatcher = async (
  token: string,
  id: string,
  dispatcher: IDispatcher
): Promise<IOrder> => {
  const url = `/api/orders/${id}/assign-dispatcher`;
  const type = "order-dispatcher-assignation";
  return post<IOrder>(url, token, type, dispatcher);
};

// eslint-disable-next-line max-len
export const assignPrescriber = async (
  token: string,
  id: string,
  prescriber: IPrescriber
): Promise<IOrder> => {
  const url = `/api/orders/${id}/assign-prescriber`;
  const type = "order-prescriber-assignation";
  return post<IOrder>(url, token, type, prescriber);
};

export const substituteProduct = async (
  token: string,
  orderId: string,
  itemSubstitution: IProductSubstituteParams
): Promise<IOrder> => {
  const url = `/api/orders/${orderId}/products/${itemSubstitution.id}/substitute`;
  const type = "order-product-substitute";
  const { item, reason, quantity } = itemSubstitution;
  return put<IOrder>(url, token, type, { item, reason, quantity });
};

export const resolveOrderTriage = async (
  token: string,
  orderId: string,
  physicalPrescriptionId: string,
  physicalPrescriptionDate: string,
  physicalPrescriptionImage: string
): Promise<IOrder> => {
  const url = `/api/orders/${orderId}/resolve-triage`;
  const type = "order-resolve-triage";
  return post<IOrder>(url, token, type, {
    physicalPrescriptionId,
    physicalPrescriptionDate,
    physicalPrescriptionImage,
  });
};

export const updateShippingAddress = async (
  token: string,
  orderId: string,
  shippingAddress: Partial<IOrder["shippingAddress"]>
): Promise<IOrder> => {
  const url = `/api/orders/${orderId}/update-shipping-address`;
  const type = "order-shipping-address-update";
  return put<IOrder>(url, token, type, shippingAddress);
};

export const updateShippingInformation = async (
  token: string,
  orderId: string,
  shippingInformation: Partial<IOrder["shippingInformation"]>
): Promise<IOrder> => {
  const url = `/api/orders/${orderId}/update-shipping-information`;
  const type = "order-shipping-information-update";
  return put<IOrder>(url, token, type, shippingInformation);
};
