import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateReportRequest, IReportEntry, IReport } from '../interfaces';
import { AppThunk } from '../store';
import * as api from '../api-clients/reports';

interface ReportsState {
  report: IReport<IReportEntry> | null;
  reports: IReport<IReportEntry>[];
  error: string | null;
  isLoading: boolean;
  isLoadingReport: boolean;
}
const initialState: ReportsState = {
  report: null,
  reports: [],
  error: null,
  isLoading: false,
  isLoadingReport: false,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getReportSuccess(state, action: PayloadAction<IReport<IReportEntry>>) {
      state.report = action.payload;
      state.error = null;
      state.isLoadingReport = false;
    },
    getReportError(state, action: PayloadAction<string>) {
      state.report = null;
      state.error = action.payload;
      state.isLoadingReport = false;
    },
    getReportStart(state) {
      state.isLoadingReport = true;
    },
    getReportsSuccess(state, action: PayloadAction<IReport<any>[]>) {
      state.reports = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getReportsError(state, action: PayloadAction<string>) {
      state.reports = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    getReportsStart(state) {
      state.isLoading = true;
    },
    createReportsSuccess(state) {
      state.error = null;
      state.isLoading = false;
    },
    createReportsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createReportsStart(state) {
      state.isLoading = true;
    },
  },
});

export const {
  getReportError,
  getReportSuccess,
  getReportStart,
  getReportsError,
  getReportsSuccess,
  getReportsStart,
  createReportsError,
  createReportsSuccess,
  createReportsStart,
} = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;

export const fetchReport = (token: string, id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getReportStart());
    const report = await api.getReportById<IReport<IReportEntry>>(token, id);
    dispatch(getReportSuccess(report));
  } catch (err) {
    dispatch(getReportError(err.toString()));
  }
};

export const fetchReports = (token: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getReportsStart());
    const reports = await api.getReports(token);
    dispatch(getReportsSuccess(reports));
  } catch (err) {
    dispatch(getReportsError(err.toString()));
  }
};

export const createReport = (token: string, request: ICreateReportRequest): AppThunk => async (dispatch) => {
  try {
    dispatch(createReportsStart());
    await api.createReport(token, request);
    dispatch(fetchReports(token));
    dispatch(createReportsSuccess());
  } catch (err) {
    dispatch(createReportsError(err.toString()));
  }
};
