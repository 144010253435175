import React, { useCallback, useState } from 'react';
import { Header, Icon, List } from 'semantic-ui-react';
import { ResolvedTriageReason, UnresolvedTriageReason } from '../../interfaces';
import { useKeycloak } from '@react-keycloak/web';
import { ConditionalRenderer } from '../conditional-renderer';
import { resolveOrderTriage, triageResolveErrorHandled } from '../../reducers/ordersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../root-reducer';
import { DismissibleMessage } from '../dismissible-message';
import { AddPhysicalPrescription } from './add-physical-prescription';

export const TriageMessage = ({ reasons = [], orderId }: ITriageMessageProps) => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const [physicalPrescriptionId, setPhysicalPrescriptionId] = useState('');
  const [physicalPrescriptionDate, setPhysicalPrescriptionDate] = useState('');
  const [physicalPrescriptionImage, setPhysicalPrescriptionImage] = useState('');

  const postPhysicalPresciptionId = useCallback(async () => {
    dispatch(resolveOrderTriage(keycloak?.token || '', orderId, physicalPrescriptionId, physicalPrescriptionDate!, physicalPrescriptionImage));
  }, [physicalPrescriptionId, physicalPrescriptionDate, physicalPrescriptionImage]);

  const { triageResolveError } = useSelector(
    (state: RootState) => state.ordersReducer,
  );
  const handleError = useCallback(() => {
    dispatch(triageResolveErrorHandled());
  }, [dispatch, physicalPrescriptionId, physicalPrescriptionDate, physicalPrescriptionImage]);

  if (reasons.length === 0) {
    return null
  }

  return (
    <div>
      <Header>
        <Icon color='yellow' name="warning sign" />
        Triage
      </Header>

      <Header as="h3">Reason</Header>
      <List bulleted>
        {reasons.map(reason => <List.Item key={`${reason.code}`}>{`${reason.title}`}</List.Item>)}
      </List>

      <ConditionalRenderer role='order.ADD_PHYS_RX_ID'>
        <AddPhysicalPrescription
          primary
          color="blue"
          message="Enter the physical prescription id"
          disabled={false}
          content="Resolve triage"
          title="Enter the physical prescription id"
          placeholder="Physical prescription id"
          onSubmit={postPhysicalPresciptionId}
          onChange={({ prescriptionId, prescriptionDate, prescriptionImage }: { prescriptionId: string, prescriptionDate: string, prescriptionImage: string }) => {
            setPhysicalPrescriptionId(prescriptionId);
            setPhysicalPrescriptionDate(prescriptionDate);
            setPhysicalPrescriptionImage(prescriptionImage);
          }}
        />
      </ConditionalRenderer>
      {triageResolveError && <DismissibleMessage negative message={triageResolveError}
        handleError={handleError} timeout={5000} />}
    </div>
  );
};

export interface ITriageMessageProps {
  reasons: (ResolvedTriageReason | UnresolvedTriageReason)[];
  orderId: string;
  keycloak: any;
}
