import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IProduct } from '../interfaces';
import type { AppThunk } from '../store';
import { getProducts, getSingleProduct } from '../api-clients/products';
import { toDictionary } from '../shared/utils/transforms';
import { RootState } from '../root-reducer';

interface ProductsState {
  products: Record<string, IProduct>;
  error: string | null;
  isLoading: boolean;
}

interface SingleProductFetchError {
  error: string;
  id: string
}

const initialState: ProductsState = {
  products: {},
  error: null,
  isLoading: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsSuccess(state, action: PayloadAction<IProduct[]>) {
      state.products = action.payload.reduce(toDictionary, {});
      state.error = null;
      state.isLoading = false;
    },
    getProductsError(state, action: PayloadAction<string>) {
      state.products = {};
      state.error = action.payload;
      state.isLoading = false;
    },
    getProductsStart(state) {
      state.isLoading = true;
    },
    getSingleProductStart(state) {
      state.isLoading = true;
    },
    getSingleProductError(state, { payload }: PayloadAction<SingleProductFetchError>) {
      state.isLoading = false;
      state.error = payload.error;
      delete state.products[payload.id];
    },
    getSingleProductSuccess(state, action: PayloadAction<IProduct>) {
      state.isLoading = false;
      state.products[action.payload.id] = action.payload;
    },
    storeSingleProductStart(state) {
      state.isLoading = true;
    },
    storeSingleProductError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    storeSingleProductSuccess(state, action: PayloadAction<IProduct>) {
      state.isLoading = false;
      state.products[action.payload.id] = action.payload;
    },
  },
});

export const {
  getProductsError,
  getProductsSuccess,
  getProductsStart,
  getSingleProductStart,
  getSingleProductSuccess,
  getSingleProductError,
  storeSingleProductStart,
  storeSingleProductError,
  storeSingleProductSuccess,
} = productsSlice.actions;

export const productsReducer = productsSlice.reducer;
export const getProductsAsArray = (state: RootState) => Object.values(state.productsReducer.products);

export const fetchProducts = (token: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getProductsStart());
    const products = await getProducts(token);
    dispatch(getProductsSuccess(products));
  } catch (err) {
    dispatch(getProductsError(err.toString()));
  }
};

export const fetchSingleProduct = (token: string, id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getSingleProductStart());
    const product = await getSingleProduct(token, id);
    dispatch(getSingleProductSuccess(product));
  } catch (error) {
    dispatch(getSingleProductError({ id, error: error.toString() }));
  }
};
