import React, { useState, useEffect, ChangeEvent } from 'react';
import { ButtonProps, Form, Input } from 'semantic-ui-react';
import { ConfirmationButton } from '../confirmation-button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IConfirmationInputButton extends ButtonProps {
  content: string;
  disabled: boolean;
  title: string;
  message: string;
  placeholder: string;
  onSubmit: Function;
  onCancel?: Function;
  onChange?: Function;
}

function convertUTCToLocalDate(date: Date | null) {
  if (!date) {
    return date
  }
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

function convertLocalToUTCDate(date: Date | null) {
  if (!date) {
    return date
  }
  let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return utcDate
}

export const AddPhysicalPrescription = ({
  onSubmit, onChange, ...props
}: IConfirmationInputButton) => {
  const [prescriptionId, setPrescriptionId] = useState('');
  const [prescriptionDate, setPrescriptionDate] = useState<Date | null>(null);
  const [prescriptionImage, setPrescriptionImage] = useState('');

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPrescriptionImage(e.target?.result?.toString() || '');
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ prescriptionId, prescriptionDate: prescriptionDate?.toISOString(), prescriptionImage });
    }
  }, [prescriptionId, prescriptionDate, prescriptionImage]);
  return (
    <ConfirmationButton
      {...props}
      color={props.color || 'red'}
      icon={props.icon || false}
      ControllerComponent={
        <>
          <Form>
            <Form.Field style={{ width: '100%' }}>
              <label>Prescription ID</label>
              <Input
                style={{ width: '100%' }}
                error={!prescriptionId && isError}
                placeholder={'Prescription ID'}
                value={prescriptionId}
                onChange={(e) => setPrescriptionId(e.target.value)}
              />
            </Form.Field>
            <Form.Field style={{ width: '100%' }}>
              <label>Prescription Date</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={convertUTCToLocalDate(prescriptionDate)}
                onChange={(date: Date) => {
                  const utcDate = convertLocalToUTCDate(date);
                  setPrescriptionDate(utcDate)
                }} />
              {!prescriptionDate && isError && <div style={{ color: 'red' }}>Please select a date</div>}
            </Form.Field>
            <Form.Field>
              <label>Upload Prescription Image</label>
              <input type="file" onChange={handleFileChange} />
              {!setPrescriptionImage && isError && <div style={{ color: 'red' }}>Please upload an image</div>}
            </Form.Field>
          </Form>
        </>
      }
      onError={() => {
        const isError = !prescriptionId || !prescriptionDate || !prescriptionImage;
        if (isError) {
          setIsError(true);
        }
        return isError;
      }}
      onSubmit={onSubmit}
      onCancel={() => { setIsError(false); setPrescriptionId(''); setPrescriptionDate(null); setPrescriptionImage('') }}
    />
  );
};
