import React, { useCallback, useEffect } from "react";
import { get } from "lodash/fp";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { format, utcToZonedTime } from "date-fns-tz";

import {
  Breadcrumb,
  Container,
  Header,
  Icon,
  Label,
  Segment,
  Image as ImageComponent,
} from "semantic-ui-react";

import { TruncatedLabel } from "../../components/order/truncated-label";
import {
  ConditionalRenderer,
  shouldRender,
} from "../../components/conditional-renderer";
import { PatientDetails } from "../patient/patient-details";
import { Medication } from "../../components/medications";
import { Product } from "../../components/products";
import { RejectButton } from "../../components/order/reject-button";
import { ApproveButton } from "../../components/order/approve-button";
import { DispatchButton } from "../../components/order/dispatch-button";
import { DispenseButton } from "../../components/order/dispense-button";
import { AssignDispatcherButton } from "../../components/order/assign-dispatcher-button";
import { AssignDispenserButton } from "../../components/order/assign-dispenser-button";
import { AssignPharmacistButton } from "../../components/order/assign-pharmacist-button";
import { AssignPrescriberButton } from "../../components/order/assign-prescriber-button";
import { CancelButton } from "../../components/order/cancel-button";
import { RelatedOrders } from "../../components/order/related-orders";
import { ApprovePomButton } from "../../components/order/approve-pom-button";
import {
  FormattedOrderItems,
  FormattedProducts,
  IMedication,
  IOrder,
  IOrderItems,
  IProduct,
  RequestedItem,
} from "../../interfaces";
import { Consultation } from "../../components/consultation";
import { AuditHistory } from "../../components/order/audit-history";
import { Tabs } from "../../components/tabs";
import { StatusLozenge } from "../../components/status-lozenge";
import { OrderNotes } from "../../components/order/order-notes";
import { ReviewerDetails } from "../../components/reviewer-details";
import { findMedicationForProduct } from "./order-utils";
import type { RootState } from "../../root-reducer";
import { fetchProducts } from "../../reducers/productsSlice";
import {
  fetchMedications,
  getMedicationsAsArray,
} from "../../reducers/medicationsSlice";
import {
  fetchOrder,
  orderUpdateErrorHandled,
  updateShippingAddress,
  updateShippingInformation,
} from "../../reducers/ordersSlice";
import { fetchPatient } from "../../reducers/patientsSlice";
import { DismissibleErrorMessage } from "../../components/dismissableErrorMessage";
import { SubstituteHistoryView } from "../../components/order/substitute-history-view";
import { ShippingAddress } from "../../components/order/shipping-address";
import { ShippingInformation } from "../../components/order/shipping-information";
import { TriageMessage } from "../../components/triage/message";

const colorSwitch = (username: string | null | undefined, userName: string) => {
  return username === userName ? "blue" : "red";
};

const orderItems = (
  items: IOrderItems[],
  products: Record<string, IProduct>,
  medications: IMedication[],
  prescriptionItems?: RequestedItem[]
) => {
  const formattedOrderItems: FormattedOrderItems = {
    productsWithMedication: [],
    allProducts: [],
  };
  items.forEach((item: IOrderItems) => {
    const productId = item.id;
    const product = products[productId];
    if (!product) throw Error("Error: could not find product");

    let medication =
      item.medication || findMedicationForProduct(product, medications);

    if (medication && prescriptionItems) {
      medication = {
        ...medication,
        ...{
          instructions:
            prescriptionItems.find((p) => p.productId === productId)
              ?.instructions || medication.instructions,
        },
      };
    }

    const formattedProduct: Partial<FormattedProducts> = {
      ...medication,
      ...item,
    };

    const size = parseInt(product.units, 10);
    formattedProduct.total = item.quantity * size;

    formattedOrderItems.allProducts.push(formattedProduct);
    if (medication) {
      formattedOrderItems.productsWithMedication.push(formattedProduct);
    }
  });
  return formattedOrderItems;
};

const hasConsultation = (value: IOrderItems) =>
  value.productType === "prescriber-consultation";

const orderHasConsultation = (order: IOrder): boolean =>
  !order?.items?.length ? false : order.items.some(hasConsultation);

const ConsultationImages = ({
  imageUrls,
}: {
  imageUrls: string[] | undefined;
}) => {
  if (!imageUrls || imageUrls.length === 0) return <></>;

  return (
    <>
      <Header size="medium">Consultation Images</Header>
      <ImageComponent.Group size="medium">
        {imageUrls.map((url) => (
          <a key={url} href={url} target="_blank" rel="noreferrer">
            <ImageComponent src={url} />
          </a>
        ))}
      </ImageComponent.Group>
    </>
  );
};
export const OrderPage = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || "";

  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const {
    order,
    loadingError: orderError,
    updateError,
    isUpdatingOrder,
  } = useSelector((state: RootState) => state.ordersReducer);

  const handleError = useCallback(
    () => dispatch(orderUpdateErrorHandled()),
    []
  );
  const handleChangeAddress = useCallback(
    (values) => {
      order &&
        dispatch(
          updateShippingAddress(keycloak?.token || "", order.id, values)
        );
    },
    [order]
  );
  const handleShippingInformation = useCallback(
    (values) => {
      order &&
        dispatch(
          updateShippingInformation(keycloak?.token || "", order.id, values)
        );
    },
    [order]
  );

  const { products, isLoading: productsLoading } = useSelector(
    (state: RootState) => state.productsReducer
  );

  const { isLoading: medicationsLoading } = useSelector(
    (state: RootState) => state.medicationsReducer
  );

  const medications = useSelector(getMedicationsAsArray);
  const { patient, isPatientLoading } = useSelector(
    (state: RootState) => state.patientsReducer
  );

  useEffect(() => {
    async function loadData() {
      await Promise.all([
        dispatch(fetchProducts(keycloak?.token || "")),
        dispatch(fetchMedications(keycloak?.token || "")),
        dispatch(fetchOrder(keycloak?.token || "", id)),
      ]);
    }

    loadData();
  }, [keycloak, id, dispatch]);

  useEffect(() => {
    if (order?.patient?.id) {
      dispatch(fetchPatient(keycloak?.token || "", order.patient.id));
    }
  }, [keycloak, order, dispatch]);

  if (orderError) {
    return (
      <Segment>
        Error fetching order data:
        {orderError}
      </Segment>
    );
  }

  // fields don't exist on KeycloakParsedToken and I'm not willing to fix that today
  const { email, preferred_username: userName } = get(
    "idTokenParsed",
    keycloak
  ) as any;

  const assignedDispenser = get("dispenserId", order);
  const assignedDispatcher = get("dispatcherId", order);
  const resolutionDate = get("resolution.resolvedAt", order);
  // TODO: Red flag -- this is causing us pain with SWR/Hooks by
  //  unmounting everything else whilst updates are happening
  // but we can't remove it because none of the components are guarded
  if (!order || productsLoading || medicationsLoading || isPatientLoading) {
    return (
      <Container>
        <p className="italic">Loading</p>
      </Container>
    );
  }

  const {
    companyId,
    upstreamId,
    createdAt,
    status,
    shippingAddress,
    shippingInformation,
    items,
    repeatPrescription,
    prescription,
  } = order;

  const formattedOrderItems = orderItems(
    items,
    products,
    medications,
    prescription?.requestedItems
  );
  const sequence =
    repeatPrescription?.sequence &&
    `${repeatPrescription.sequence?.current}/${repeatPrescription.sequence?.total}`;

  return (
    <>
      <DismissibleErrorMessage
        handleError={handleError}
        message={updateError}
      />
      <Container>
        <Breadcrumb
          divider="/"
          sections={[
            { key: "home", content: <Icon name="home" /> },
            { key: "orders", content: <a href="/orders">Orders</a> },
            { key: "info", content: id, active: true },
          ]}
        />

        <Header size="huge">
          <Icon name="building outline" /> {companyId}{" "}
        </Header>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
          {upstreamId && (
            <>
              <TruncatedLabel
                title="Upstream ID:"
                detail={upstreamId}
                color="grey"
                truncate
                truncateCount={25}
              />
            </>
          )}
          {Object.prototype.hasOwnProperty.call(order, "repeatPrescription") && (
            <Label color="orange" data-testid="badge-repeat-prescription">
              <Icon name="exchange" />
              Repeat Prescription {sequence}
            </Label>
          )}
          <Label color="violet">
            <Icon name="calendar alternate outline" />
            Created{" "}
            {format(
              utcToZonedTime(createdAt, "Europe/London"),
              "do MMMM yyyy HH:mm"
            )}
          </Label>

          <ConditionalRenderer order={order} statuses={["DISPENSING"]}>
            <Label color="blue">
              <Icon name="user" /> {assignedDispenser}
            </Label>
          </ConditionalRenderer>

          <ConditionalRenderer
            order={order}
            statuses={["DISPATCHING", "DISPATCHED"]}
          >
            <Label color="blue">
              <Icon name="user" /> {assignedDispatcher}
            </Label>
          </ConditionalRenderer>
          <ConditionalRenderer order={order} statuses={["PRESCRIBING"]}>
            <Label color={colorSwitch(order.prescriberId, userName)}>
              <Icon name="user" /> {order.prescriberId}
            </Label>
          </ConditionalRenderer>

          <ConditionalRenderer order={order} statuses={["REVIEWING"]}>
            <Label color={colorSwitch(order.pharmacistId, userName)}>
              <Icon name="user" /> {order.pharmacistId}
            </Label>
          </ConditionalRenderer>
            
          {order.isSentToPhloConnect && (
            <a
              href="https://app.phloconnect.com"
              target="_blank"
              rel="noreferrer"
              style={{ 
                display: "inline-flex",
                alignItems: "center",
                backgroundColor: "#F0EEEA",
                color: "#07073D",
                borderRadius: "4px",
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: "12px",
                margin: "0 2px",
                padding: "5px 10px",
                gap: "8px",
                lineHeight: "16px",
              }}
            >
              <svg width="16" height="16" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_94_1186)">
                  <path d="M150.208 59.3706H111.747C109.467 59.3706 107.655 61.2101 107.655 63.4584C107.655 65.7066 109.496 67.5461 111.747 67.5461H150.208C160.993 67.5461 169.76 76.8604 169.76 88.277C169.76 99.6935 160.993 109.008 150.208 109.008H131.737C118.352 109.008 107.48 119.87 107.45 133.242V137.272L107.48 151.696C107.48 162.47 98.1566 171.229 86.7292 171.229C75.3018 171.229 65.9787 162.47 65.9787 151.696V145.914C69.6612 144.338 72.2331 140.688 72.2331 136.425C72.2331 130.702 67.5861 126.089 61.887 126.089C56.188 126.089 51.541 130.731 51.541 136.425C51.541 140.688 54.1129 144.309 57.7954 145.914V151.696C57.7954 166.996 70.801 179.434 86.7584 179.434C102.716 179.434 115.721 166.996 115.721 151.696V137.301L115.692 133.272C115.692 124.424 122.911 117.242 131.767 117.242H150.237C165.552 117.242 178.002 104.248 178.002 88.3062C178.002 72.3639 165.552 59.3706 150.237 59.3706H150.208Z" fill="#1779F2" />
                  <path d="M121.976 35.5449V29.1212C121.976 13.8213 108.971 1.38281 93.0132 1.38281C77.0558 1.38281 64.0502 13.8213 64.0502 29.1212V43.516L64.0794 47.5454C64.0794 56.3925 56.8606 63.5753 48.0051 63.5753H29.5342C14.1905 63.6045 1.74023 76.5977 1.74023 92.54C1.74023 108.482 14.1905 121.476 29.505 121.476H67.9665C70.2461 121.476 72.0581 119.636 72.0581 117.388C72.0581 115.14 70.2169 113.3 67.9665 113.3H29.505C18.7206 113.3 9.95276 103.986 9.95276 92.5692C9.95276 81.1527 18.7206 71.8384 29.505 71.8384H47.9759C61.3614 71.8384 72.2335 60.9766 72.2627 47.6038V43.5744L72.2335 29.1504C72.2335 18.3762 81.5566 9.61674 92.984 9.61674C104.411 9.61674 113.735 18.3762 113.735 29.1504V35.5741C110.052 37.1508 107.48 40.8006 107.48 45.0635C107.48 50.7864 112.127 55.3997 117.826 55.3997C123.525 55.3997 128.172 50.7572 128.172 45.0635C128.172 40.8006 125.6 37.18 121.918 35.5741L121.976 35.5449Z" fill="#1779F2" />
                  <path d="M67.9657 121.476C65.7153 121.476 63.874 119.636 63.874 117.388C63.874 115.14 65.686 113.3 67.9657 113.271C69.2224 113.271 98.6238 112.483 111.513 74.9044C112.243 72.7729 114.581 71.6049 116.715 72.3641C118.848 73.0941 119.988 75.4299 119.257 77.5614C104.381 120.979 69.2516 121.534 67.9657 121.476V121.476Z" fill="url(#paint0_linear_94_1186)" />
                  <path d="M64.6053 108.015C64.1669 108.015 63.6993 107.927 63.2317 107.781C61.0981 107.022 59.9583 104.686 60.7182 102.555C75.7112 59.9546 110.286 59.3706 111.747 59.3706C113.997 59.3706 115.839 61.2101 115.839 63.4584C115.839 65.7066 114.027 67.5461 111.747 67.5753C110.49 67.5753 81.4103 68.3345 68.4339 105.3C67.8494 106.993 66.2419 108.044 64.5468 108.044L64.6053 108.015Z" fill="url(#paint1_linear_94_1186)" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_94_1186" x1="75.4475" y1="126.439" x2="109.185" y2="67.9566" gradientUnits="userSpaceOnUse">
                    <stop offset="0.54" stopColor="#1779F2" />
                    <stop offset="0.62" stopColor="#1779F2" stopOpacity="0.84" />
                    <stop offset="0.81" stopColor="#1779F2" stopOpacity="0.44" />
                    <stop offset="1" stopColor="#1779F2" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_94_1186" x1="110.432" y1="56.334" x2="60.853" y2="105.989" gradientUnits="userSpaceOnUse">
                    <stop offset="0.54" stopColor="#1779F2" />
                    <stop offset="0.58" stopColor="#1779F2" stopOpacity="0.97" />
                    <stop offset="0.64" stopColor="#1779F2" stopOpacity="0.89" />
                    <stop offset="0.71" stopColor="#1779F2" stopOpacity="0.76" />
                    <stop offset="0.79" stopColor="#1779F2" stopOpacity="0.58" />
                    <stop offset="0.88" stopColor="#1779F2" stopOpacity="0.35" />
                    <stop offset="0.98" stopColor="#1779F2" stopOpacity="0.07" />
                    <stop offset="1" stopColor="#1779F2" stopOpacity="0" />
                  </linearGradient>
                  <clipPath id="clip0_94_1186">
                    <rect width="181" height="181" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              Sent to Phlo Connect
            </a>
          )}

          <StatusLozenge value={status} resolutionDate={resolutionDate} />
        </div>

        <ConditionalRenderer order={order} statuses={["IN_TRIAGE"]}>
          <Segment loading={isUpdatingOrder}>
            <TriageMessage
              reasons={order.triageReasons}
              orderId={order.id}
              keycloak={keycloak}
            />
          </Segment>
        </ConditionalRenderer>
        <Segment loading={isUpdatingOrder}>
          <Medication
            order={order}
            products={formattedOrderItems.productsWithMedication}
            keycloak={keycloak}
          />
          <Product
            products={formattedOrderItems.allProducts}
            orderStatus={order.status}
            companyId={order.companyId}
            orderId={order.id}
            disabled={order.isSentToPhloConnect}
          />

          <SubstituteHistoryView
            order={order}
            products={products}
            medications={medications}
          />
          {order.reviewerDetails && (
            <ReviewerDetails
              email={order.pharmacistId || ""}
              reviewerDetails={order.reviewerDetails}
            />
          )}
          {patient && <PatientDetails patient={patient} />}

          <ShippingAddress
            shippingAddress={shippingAddress}
            isEditable={shouldRender(keycloak, {
              statuses: [
                "PRESCRIBING",
                "READY_FOR_DISPATCH",
                "READY_FOR_DISPENSING",
                "READY_FOR_PRESCRIBER",
                "READY_FOR_REVIEW",
                "REVIEWING",
              ],
              role: "order.EDIT",
              order,
            })}
            onSubmit={handleChangeAddress}
          />
          <ConditionalRenderer
            order={order}
            statuses={["DISPATCHING", "DISPATCHED"]}
            role="order.EDIT"
          >
            <ShippingInformation
              shippingInformation={shippingInformation}
              isEditable={shouldRender(keycloak, {
                statuses: ["DISPATCHING", "DISPATCHED"],
                role: "order.EDIT",
                order,
              })}
              onSubmit={handleShippingInformation}
            />
          </ConditionalRenderer>
        </Segment>

        <Tabs
          tabs={[
            {
              label: "Consultation",
              role: "consultation.GET",
              component: (
                <>
                  <ConsultationImages
                    imageUrls={order.imageUrls}
                  ></ConsultationImages>
                  <Consultation consultationId={order!.consultationId} />
                </>
              ),
            },
            {
              label: "Related orders",
              component: (
                <RelatedOrders
                  patientId={order.patientId ?? order.patient!.id!}
                  currentOrderId={order.id}
                  medications={medications}
                  products={products}
                />
              ),
            },
            {
              label: "Audit history",
              role: "order.GET",
              component: <AuditHistory auditHistory={order.auditHistory} />,
            },
            {
              label: "Order notes",
              component: <OrderNotes orderId={order.id} />,
            },
          ]}
        />

        <ConditionalRenderer
          order={order}
          assignment={order.pharmacistId}
          role="order.REJECT"
          statuses={["REVIEWING", "PRESCRIBING"]}
        >
          <RejectButton
            status={order.status}
            orderId={order.id}
            token={token}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.CANCEL"
          statuses={[
            "AWAITING_APPROVAL",
            "READY_FOR_REVIEW",
            "READY_FOR_PRESCRIBER",
            "REVIEWING",
            "DISPENSING",
            "PRESCRIBING",
            "READY_FOR_DISPENSING",
            "READY_FOR_DISPATCH",
            "IN_TRIAGE",
          ]}
        >
          <CancelButton
            token={keycloak!.token || ""}
            orderId={order.id}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          assignment={order.pharmacistId}
          role="order.APPROVE"
          statuses={["REVIEWING"]}
          condition={
            !order.items.some(
              (item) => item.productType === "pharmacist-consultation"
            )
          }
        >
          <ApproveButton orderId={id} token={keycloak!.token || ""} />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          assignment={order.dispenserId}
          role="order.DISPENSE"
          statuses={["DISPENSING"]}
        >
          <DispenseButton
            orderId={id}
            token={keycloak!.token || ""}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          assignment={order.dispatcherId}
          role="order.DISPATCH"
          statuses={["DISPATCHING"]}
        >
          <DispatchButton
            orderId={id}
            token={keycloak!.token || ""}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        {patient && (
          <ConditionalRenderer
            order={order}
            statuses={["PRESCRIBING"]}
            assignment={order.prescriberId}
            role="order.APPROVE:USER_DETAILS"
            condition={!orderHasConsultation(order)}
          >
            <ApprovePomButton
              order={order}
              products={formattedOrderItems.allProducts}
              disabled={order.isSentToPhloConnect}
            />
          </ConditionalRenderer>
        )}

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_DISPENSER"
          statuses={["READY_FOR_DISPENSING"]}
          condition={!orderHasConsultation(order)}
        >
          <AssignDispenserButton
            token={keycloak!.token || ""}
            orderId={id}
            text="Assign Self (Dispenser)"
            dispenser={{
              dispenserUsername: userName,
              dispenserId: email,
            }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_DISPATCHER"
          statuses={["READY_FOR_DISPATCH"]}
        >
          <AssignDispatcherButton
            orderId={id}
            text="Assign Self (Dispatcher)"
            token={keycloak!.token || ""}
            dispatcher={{
              dispatcherUsername: userName,
              dispatcherId: email,
            }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_PHARMACIST"
          statuses={["READY_FOR_REVIEW"]}
        >
          <AssignPharmacistButton
            orderId={id}
            text="Assign Self (Pharmacist)"
            token={token || ""}
            pharmacist={{ pharmacistId: email, pharmacistUsername: userName }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_PHARMACIST"
          statuses={["REVIEWING"]}
        >
          <AssignPharmacistButton
            orderId={id}
            text="Unassign Pharmacist"
            token={token || ""}
            pharmacist={{ pharmacistId: null, pharmacistUsername: null }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_PRESCRIBER"
          statuses={["READY_FOR_PRESCRIBER"]}
        >
          <AssignPrescriberButton
            token={keycloak!.token || ""}
            orderId={id}
            text="Assign Self (Prescriber)"
            prescriber={{
              prescriberId: email,
              prescriberUsername: userName,
            }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_PRESCRIBER"
          statuses={["PRESCRIBING"]}
        >
          <AssignPrescriberButton
            token={keycloak!.token || ""}
            orderId={id}
            text="Unassign Prescriber"
            prescriber={{ prescriberId: null, prescriberUsername: null }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_DISPENSER"
          statuses={["DISPENSING"]}
        >
          <AssignDispenserButton
            orderId={id}
            token={keycloak!.token || ""}
            text="Unassign Dispenser"
            dispenser={{ dispenserId: null, dispenserUsername: null }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          order={order}
          role="order.ASSIGN_DISPATCHER"
          statuses={["DISPATCHING"]}
        >
          <AssignDispatcherButton
            token={keycloak!.token || ""}
            orderId={id}
            text="Unassign Dispatcher"
            dispatcher={{ dispatcherId: null, dispatcherUsername: null }}
            disabled={order.isSentToPhloConnect}
          />
        </ConditionalRenderer>
      </Container>
    </>
  );
};
