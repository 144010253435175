import React, { useEffect, useState } from 'react';
import {
  Table, Button, Dropdown, Container, Icon,
} from 'semantic-ui-react';
import { formatDistanceToNowStrict } from 'date-fns';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  useTable,
  usePagination,
  Row,
  useGlobalFilter,
  useAsyncDebounce,
  Cell,
} from 'react-table';
import { get, startCase, toSafeInteger } from 'lodash/fp';
import { useSelector } from 'react-redux';
import {
  EPageStep,
  IOrder,
  IOrderItems,
  IPagination,
  MedicationType,
  ProductType,
  TypeOfPrescription,
} from '../../interfaces';
import { AssignDispenserButton } from './assign-dispenser-button';
import { AssignDispatcherButton } from './assign-dispatcher-button';
import { StatusLozenge } from '../status-lozenge';
import { AssignPrescriberButton } from './assign-prescriber-button';
import { AssignPharmacistButton } from './assign-pharmacist-button';
import '../../type-hacks/react-table.d.ts';
import {
  extractOrderItem,
  isOverdueOrder,
  sortOverdueOrdersOnTop,
} from '../../pages/orders/order-utils';
import { ConditionalRenderer } from '../conditional-renderer';
import { TerminalStatusPaginator } from './terminal-status-paginator';
import { RootState } from '../../root-reducer';

interface IOrderListProps {
  pagination: IPagination | null;
  searchValue: string;
  onSearchFilter: (debouncer: (any: any) => void) => () => void;
  onPageChange: (page: EPageStep) => {};
  setPageLimit: (value: number) => void;
}

type RoleCell = Partial<IOrder> & {
  userName: string;
  email: string;
  canAssignDispatcher?: boolean | undefined;
  canAssignDispenser?: boolean | undefined;
  canAssignPrescriber?: boolean | undefined;
  canAssignPharmacist?: boolean | undefined;
  token: string;
  items: IOrderItems[];
};

const pageDropdownValues = [
  {
    key: 10,
    value: 10,
    text: 10,
  },
  {
    key: 20,
    value: 20,
    text: 20,
  },
  {
    key: 30,
    value: 30,
    text: 30,
  },
  {
    key: 40,
    value: 40,
    text: 40,
  },
  {
    key: 50,
    value: 50,
    text: 50,
  },
];

const OrderIdCell = ({ value }: { value: string }) => (
  <Link to={`/orders/view/${value}`}>{value}</Link>
);

const FriendlyDate = ({ value }: { value: string }) =>
  (value ? (
    <div title={value}>{formatDistanceToNowStrict(new Date(value))} ago</div>
  ) : null);

const StatusLozengeCell = ({ value }: { value: string }) => {
  return StatusLozenge({ value: startCase(value.toLowerCase()) });
};
const TrackingInfoCell = ({ value }: { value: string }) =>
  value ? <Icon style={{color:'green'}} name="check circle" /> : null;

const itemHasMedicationType = (type: MedicationType, items: IOrderItems[]) =>
  items.some((item) => item.medicationType === type);
const hasProductType = (type: ProductType, items: IOrderItems[]) =>
  items.some((item) => item.productType === type);

const DispatcherCell = ({
  id = '',
  token,
  dispatcherId: orderDispatcherId,
  canAssignDispatcher,
  userName,
  email,
  status,
}: RoleCell) => {
  if (orderDispatcherId) return orderDispatcherId;
  if (!canAssignDispatcher || status !== 'READY_FOR_DISPATCH') return null;
  return (
    <AssignDispatcherButton
      orderId={id}
      token={token}
      text="Assign to me"
      dispatcher={{
        dispatcherUsername: userName,
        dispatcherId: email,
      }}
    />
  );
};

const DispenserCell = ({
  id = '',
  token,
  dispenserId: orderDispenserId,
  canAssignDispenser,
  userName,
  email,
}: RoleCell) => {
  if (orderDispenserId) return orderDispenserId;
  if (!canAssignDispenser) return null;
  return (
    <AssignDispenserButton
      orderId={id}
      token={token}
      text="Assign to me"
      dispenser={{
        dispenserUsername: userName,
        dispenserId: email,
      }}
    />
  );
};

const PrescriberCell = ({
  id = '',
  token,
  prescriberId: orderPrescriberId,
  canAssignPrescriber,
  userName,
  email,
  items,
}: RoleCell) => {
  if (orderPrescriberId) return orderPrescriberId;
  if (
    !canAssignPrescriber
    || (!hasProductType('prescriber-consultation', items)
      && !itemHasMedicationType(MedicationType.pom, items))
  ) {
    return null;
  }

  return (
    <AssignPrescriberButton
      orderId={id}
      token={token}
      text="Assign to me"
      prescriber={{
        prescriberId: email,
        prescriberUsername: userName,
      }}
    />
  );
};

const PharmacistCell = ({
  id = '',
  token,
  pharmacistId: orderPharmacistId,
  canAssignPharmacist,
  userName,
  email,
  items,
}: RoleCell) => {
  if (orderPharmacistId) return orderPharmacistId;
  if (!canAssignPharmacist || !itemHasMedicationType(MedicationType.p, items)) {
    return null;
  }
  return (
    <AssignPharmacistButton
      orderId={id}
      token={token}
      text="Assign to me"
      pharmacist={{
        pharmacistId: email,
        pharmacistUsername: userName,
      }}
    />
  );
};

export const OrderList = ({
  onSearchFilter,
  searchValue,
  pagination,
  onPageChange,
  setPageLimit,
}: IOrderListProps) => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || '';

  const orders = useSelector((state: RootState) => state.ordersReducer.orders);

  const canAssignDispatcher = keycloak?.hasResourceRole(
    'ASSIGN_DISPATCHER',
    'order',
  );
  const canAssignDispenser = keycloak?.hasResourceRole(
    'ASSIGN_DISPENSER',
    'order',
  );
  const canAssignPrescriber = keycloak?.hasResourceRole(
    'ASSIGN_PRESCRIBER',
    'order',
  );
  const canAssignPharmacist = keycloak?.hasResourceRole(
    'ASSIGN_PHARMACIST',
    'order',
  );

  const { email, preferred_username: userName } = get(
    'idTokenParsed',
    keycloak,
  ) as any; // fields don't exist on KeycloakParsedToken and I'm not willing to fix that today
  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: OrderIdCell,
      },
      {
        Header: 'Upstream Id',
        accessor: 'upstreamId',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: FriendlyDate,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: StatusLozengeCell,
      },
      {
        Header: 'Resolved',
        accessor: 'resolution.resolvedAt',
        Cell: FriendlyDate,
      },
      {
        Header: 'Has Tracking Info',
        accessor: 'shippingInformation.trackingUrl',
        Cell: TrackingInfoCell,
      },
      {
        Header: 'Type of Prescription',
        accessor: (order: IOrder) => {
          if (
            !Object.prototype.hasOwnProperty.call(order, 'repeatPrescription')
          ) {
            return TypeOfPrescription.ONE_TIME;
          }

          const { repeatPrescription } = order;
          let sequenceString = '';
          if (repeatPrescription?.sequence) {
            const { sequence } = repeatPrescription!;
            sequenceString = `${sequence?.current}/${sequence?.total}`;
          }
          return `${TypeOfPrescription.REPEAT} ${sequenceString}`;
        },
      },
      {
        Header: 'Business',
        accessor: 'companyId',
      },
      {
        Header: 'Patient',
        accessor: (order: IOrder) =>
          (order.patient
            ? `${order.patient.firstName} ${order.patient.lastName}`
            : ''),
      },
      {
        Header: 'Postcode',
        accessor: 'shippingAddress.postcode',
      },
      {
        Header: 'Dispatcher',
        accessor: 'dispatcherId',
        Cell: (cell: Cell<IOrder>) => {
          const { id, dispatcherId, items, status, isSentToPhloConnect } = extractOrderItem(cell);
          return DispatcherCell({
            id,
            token,
            dispatcherId,
            canAssignDispatcher: canAssignDispatcher && !isSentToPhloConnect,
            userName,
            email,
            items,
            status,
          });
        },
      },
      {
        Header: 'Dispenser',
        accessor: 'dispenserId',
        Cell: (cell: Cell<IOrder>) => {
          const { id, dispenserId, items, isSentToPhloConnect } = extractOrderItem(cell);
          return DispenserCell({
            id,
            token,
            dispenserId,
            canAssignDispenser: canAssignDispenser && !isSentToPhloConnect,
            userName,
            email,
            items,
          });
        },
      },
      {
        Header: 'Prescriber',
        accessor: 'prescriberId',
        Cell: (cell: Cell<IOrder>) => {
          const { id, prescriberId, items, isSentToPhloConnect } = extractOrderItem(cell);
          return PrescriberCell({
            id,
            token,
            prescriberId,
            canAssignPrescriber: canAssignPrescriber && !isSentToPhloConnect,
            userName,
            email,
            items,
          });
        },
      },
      {
        Header: 'Pharmacist',
        accessor: 'pharmacistId',
        Cell: (cell: Cell<IOrder>) => {
          const { id, pharmacistId, items, isSentToPhloConnect } = extractOrderItem(cell);
          return PharmacistCell({
            id,
            token,
            pharmacistId,
            canAssignPharmacist: canAssignPharmacist && !isSentToPhloConnect,
            userName,
            email,
            items,
          });
        },
      },
      {
        Header: 'Reviewer',
        accessor: 'reviewerDetails.name',
      },
    ],
    [
      canAssignDispatcher,
      canAssignPharmacist,
      canAssignPrescriber,
      canAssignDispenser,
      email,
      token,
      userName,
    ],
  );

  const memoOrders = React.useMemo(() => [...orders ?? []], [orders]);

  const location = useLocation();
  const history = useHistory();

  function getInitialPageNumber(): number {
    const params = new URLSearchParams(location.search);
    const val = parseInt(params.get('page') || '1', 10);
    // arrays start at 0, humans prefer 1
    return +val - 1;
  }

  const [currentPage, setCurrentPage] = useState(getInitialPageNumber());

  const tableInstance = useTable<any>(
    {
      columns,
      data: memoOrders.sort(sortOverdueOrdersOnTop),
      initialState: {
        pageIndex: currentPage,
      },
    },
    useGlobalFilter,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const setNextPage = () => setCurrentPage((cur) => cur + 1);
  const setPrevPage = () => setCurrentPage((cur) => cur - 1);
  const setFirstPage = () => setCurrentPage(0);
  const setLastPage = () => setCurrentPage(pageCount - 1);

  useEffect(() => {
    // pageCount is like array.length, in order to hit the last entry, use length - 1
    const usefulPageCount = pageCount - 1;

    if (pageCount === 0 && memoOrders.length === 0) return; // nothing loaded? do nothing

    if (currentPage > usefulPageCount) {
      setCurrentPage(0); // querystring handled in currentPage useEffect
    }
  }, [pageCount]);

  useEffect(() => {
    gotoPage(currentPage);
    const newPageNum = currentPage + 1 ?? 1;
    const nextPageQs = `page=${newPageNum}`;
    history.replace({ pathname: location.pathname, search: nextPageQs });
  }, [currentPage]);

  // Fixes "Warning: Can't perform a React state update on an unmounted component"
  // TODO: move this to a custom hook (useMounted)
  // Current impediment:
  // passing a function to a hook and calling it inside the hook breaks the hooks rule
  const isMounted = React.useRef(false);
  const debounceHandler = onSearchFilter(
    useAsyncDebounce((value) => {
      if (isMounted.current) {
        setGlobalFilter(value || undefined);
      }
      isMounted.current = true;
    }, 200),
  );

  useEffect(() => {
    debounceHandler();
  }, [searchValue, debounceHandler]);

  return (
    <Container>
      <div style={{ overflowX: 'scroll', paddingBottom: '10px' }}>
        <Table {...getTableProps()}>
          <Table.Header>
            {headerGroups.map((headerGroup, i) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <Table.HeaderCell {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row: Row<IOrder>, l: number) => {
              prepareRow(row);
              const overdueOrderClass = isOverdueOrder(row.original)
                ? { color: 'red' }
                : null;
              return (
                <Table.Row
                  {...row.getRowProps()}
                  style={overdueOrderClass}
                  key={l}
                >
                  {row.cells.map((cell, k) => {
                    return (
                      <Table.Cell {...cell.getCellProps()} key={k}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      <ConditionalRenderer condition={pagination !== null}>
        <TerminalStatusPaginator
          onPageChange={onPageChange}
          setPageLimit={setPageLimit}
          setPageSize={setPageSize}
          pagination={pagination}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={pagination === null}>
        <div
          className="flex items-center justify-end"
          style={{ paddingRight: '5rem' }}
        >
          <div>
            <Button onClick={setFirstPage} disabled={!canPreviousPage}>
              {'<<'}
            </Button>
            <Button onClick={setPrevPage} disabled={!canPreviousPage}>
              {'<'}
            </Button>
            <Button onClick={setNextPage} disabled={!canNextPage}>
              {'>'}
            </Button>
            <Button onClick={setLastPage} disabled={!canNextPage}>
              {'>>'}
            </Button>
          </div>
          <Dropdown
            className="mx1"
            options={pageDropdownValues}
            value={pageSize}
            text={`Show ${pageSize}`}
            onChange={(_, data) => setPageSize(toSafeInteger(data.value))}
          />
          <span>
            {`Page ${pageIndex + 1} of ${pageCount === 0 ? 1 : pageCount}`}
          </span>
        </div>
      </ConditionalRenderer>
    </Container>
  );
};
